import jwtDecode from "jwt-decode";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

export const makeId = (length = 12) => {
  var result = "";
  var characters = "123456789123456789123456789123456789123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
//
export const SamplePrevArrow = ({ onClick }) => {
  return (
    <button
      className="PrevBtn text-pexo-gray-700 dark:text-pexo-gray-200 w-9 h-9 xl:w-12 xl:h-12 text-lg absolute left-0 xl:-left-0 z-[1] top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full hidden group-hover:inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none"
      onClick={onClick}
      style={{ zIndex: 99 }}
    >
      <BiChevronLeft className="w-5 h-5" />
    </button>
  );
},
  SampleNextArrow = ({ onClick }) => {
    return (
      <button
        className="NextBtn text-pexo-gray-700 dark:text-pexo-gray-200 w-9 h-9 xl:w-12 xl:h-12 text-lg absolute right-0 xl:-right-0 z-[1] top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full hidden group-hover:inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none"
        onClick={onClick}
      >
        <BiChevronRight className="w-5 h-5" />
      </button>
    );
  };
export const defaultURI = '/app/dashboard';
export const hostURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8000"
    : "https://app.pexo.trade";
export const AUTH_TOKEN = "x-auth-token";
export const JWT_TOKEN_STRING = "x-jwt-token-encode-decode-string";
export const REDIRECT_URI = "x-pexo-redirect";
export const USER_CURRENCY = "x-pexo-user-currency";
export const DEPOSIT_DATA = "x-pexo-deposit-saved-data";
export const DEPOSIT_COIN = "x-pexo-deposit-COIN-data";
export const OTP_TOKEN = "_x-otp-token";
export const USER_IP_AND_LOCATION = "_x-user-ip-and-location";
export const NOTIFICATION_TONE = "_x-notification-tone";

export const ipURL = "https://api.ipify.org?format=json",
  getLocationURL = (ip) => `https://ipwhois.app/json/${ip}`;

export const decodeToken = (token) => {
  const decodeToken = jwtDecode(token);
  return decodeToken;
},
  TRANSACTION_METHOD = {
    MANUAL: "manual",
    AUTOMATED: "Crypto Wallet",
    PAYPAL: "PayPal",
    WIRE_TRANSFER: "wire-transfer",
    CARD: "Credit / Debit Card",
  };

export const transPaymentType = (type) => {
  switch (type) {
    case "card":
      return { name: TRANSACTION_METHOD.CARD };

    case "crypto":
      return { name: TRANSACTION_METHOD.AUTOMATED };

    case "wire-transfer":
      return {
        name: TRANSACTION_METHOD.WIRE_TRANSFER,
      };

    case "paypal":
      return { name: TRANSACTION_METHOD.PAYPAL };

    default:
      return { name: TRANSACTION_METHOD.MANUAL };
  }
},
  feeRate = 0.78 / 100,
  baseCurrency = 'USDT';
