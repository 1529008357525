import { getMerge } from "components/wallet/balance-table.component";
import { useEffect, useState } from "react";
import { MdSwapHoriz } from "react-icons/md";
import { apiHeaders, classNames, getFinalPrice } from "utils/helpers/helpers";
import ConvertWalletSelect from "./wallet-select.component";
import Image from "components/image/image.component";
import { alertMessage } from "utils/initial-state/initial-state";
import { hostURL } from "utils/initial-state/states";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/user/user.selector";
import { selectAllMarketValues } from "store/market/market.selector";
import Preloader from "components/preloader/preloader.component";
import { getUserWallets } from "store/user/user.actions";
import { stapleCoins } from "utils/helpers/staple-coins";
import { selectInfo } from "store/info/info.selector";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SuccessScreen from "components/toolkits/success-screen.component";

const defaultValues = {
    value: 0.0,
    from: "",
    to: "",
};

const CryptoConverter = ({ wallets }) => {
    const [formData, setFormData] = useState(defaultValues),
        [items, setItems] = useState([]),
        [loading, setLoading] = useState(false),
        [result, setResult] = useState(0),
        user = useSelector(selectUser),
        navigate = useNavigate(),
        _marketLists = useSelector(selectAllMarketValues),
        siteInfo = useSelector(selectInfo),
        stableCoinsMap = stapleCoins.map(i => i.id),
        marketLists = _marketLists?.map(item => ({ ...item, current_price: stableCoinsMap.includes(item.id) ? (item.current_price) : (getFinalPrice(item.current_price, siteInfo.charge)) })),
        dispatch = useDispatch(),
        { value, from, to } = formData,
        handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        },
        swap = () => {
            setFormData({ ...formData, from: to, to: from });
        };
    const fetchData = async () => {
        try {
            const data = getMerge(wallets, marketLists);
            setItems(data)
            // console.log(data);
        } catch (error) {
            console.log(error);
        }
    }
    const getData = async () => {
        try {
            const dataMap = getMerge(wallets, marketLists);
            setItems(dataMap)
        } catch (error) {
            console.log(error);
        }
    };

    const selected =
        items.length > 0
            ? {
                from: items.find((item) => item.symbol === from),
                to: items.find((item) => item.symbol === to),
            }
            : {
                from: null,
                to: null,
            };

    const convert = () => {
        // rd = (num) => {
        //   return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
        // },
        const fromValue = Number(value) * selected?.from?.current_price,
            base = fromValue / selected?.to?.current_price;
        return base.toFixed(6);
    }, proceed = async () => {
        const { from, to } = selected
        if (from == null || to == null) {
            alertMessage('error', 'Please select wallet or currency');
            return false;
        }
        if (from.user_id == null) {
            alertMessage('error', 'You do not have any balance to convert');
            return false;
        }
        const equivalent = parseFloat(value) * parseFloat(from.current_price)
        console.log(equivalent);
        if (equivalent < 10) {
            alertMessage('error', 'Amount must be above 10 USD');
            return false;
        }
        const sumbitData = {
            from: selected.from,
            value,
            amount: result,
            to: selected.to,
            user_id: user.id
        }
        try {
            setLoading(true)
            const result = await fetch(`${hostURL}/api/convert/wallet`, {
                method: 'POST',
                headers: {
                    ...apiHeaders, "Authorization": `Bearer ${user.api_token}`,
                    "X-Custom-Header": "header value",
                },
                body: JSON.stringify(sumbitData)
            }),
                res = await result.json();
            if (res.success) {

                dispatch(getUserWallets(user));
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <SuccessScreen title='Conversion Successful' message={res.message} callback={() => { onClose(); navigate('/') }} />
                        );
                    },
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            } else {
                alertMessage('warn', res.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (selected != null) {
            const res = convert();
            setResult(res);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, from, to, selected]);
    useEffect(() => {
        fetchData();
        const intervalId = setInterval(() => {
            getData();
        }, 5000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (items.length === 0)
        return (
            <div id="app-body">
                <div
                    style={{
                        background: "rgba(0,0,0,0.47)",
                        backdropFilter: "blur(4px)",
                        position: "fixed",
                        inset: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <center>
                        <div className="spinner-border text-primary" role="status"></div>
                    </center>
                </div>
            </div>
        );
    if (loading) return <Preloader />
    return (
        <section className="w-full h-[77vh] sm:h-[100vh] mb-4">
            <div className="max-w-[504px] m-auto border border-pexo-gray-300 dark:border-[#3C4145] py-5 px-4 sm:px-8 bg-white dark:bg-black/25 rounded-lg shadow-[0px_1px_1px_rgba(0,0,0,0.25)]">
                <div className="flex justify-between items-center gap-2 sm:gap-5 py-1 px-2 border border-[#6f767e30] dark:border-[#3C4145] rounded-lg">
                    <span
                        className="text-center flex-1 text-[#F8FAFC] bg-amber-500/30 rounded-lg p-2"
                    >
                        Convert
                    </span>
                </div>
                <hr className="my-5 border-pexo-gray-300 dark:border-[#3C4145]" />
                <div className="flex items-center justify-between gap-2 relative">
                    <div className="w-[210px] border border-[#6f767e30] dark:border-[#3C4145] rounded-lg px-2 sm:px-5 py-2 sm:py-3 bg-stone-500/25 dark:bg-pexo-dark-950 ">
                        <div className="flex flex-col gap-1">
                            <h6 className="text-sm leading-[150%] text-pexo-gray-500 dark:text-pexo-gray-300">
                                From
                            </h6>
                            <div className="flex items-center gap-2 user-info">

                                {selected.from == null ? <span
                                    className="w-8 h-8 rounded-full block overflow-hidden bg-gray-500/50 dark:bg-amber-700"></span> : <Image
                                    alt="fromIcon"
                                    loading="lazy"
                                    width={28}
                                    height={28}
                                    decoding="async"
                                    data-nimg={1}
                                    className="w-8 h-8 rounded-full overflow-hidden"
                                    src={selected.from.image}
                                    style={{ color: "transparent" }}
                                />}
                                <div className="flex flex-col">
                                    <h6 className="text-sm font-bold leading-[150%] text-pexo-gray-500 dark:text-white uppercase">
                                        {selected.from == null ? '---' : selected.from.symbol}
                                    </h6>
                                    <span className="text-xs leading-[150%] text-pexo-gray-500 dark:text-pexo-gray-400">
                                        On {selected.from == null ? '-------' : selected.from.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={swap}
                        className="flex items-center justify-center w-7 sm:w-10 h-7 sm:h-10 rounded-full border border-[#6f767e30] dark:border-[#3C4145] absolute left-2/4 -translate-x-2/4 bg-stone-200 dark:bg-pexo-dark-950"
                    >
                        <MdSwapHoriz className="material-symbols-outlined text-pexo-gray-400 !text-sm sm:!text-2xl" />
                    </button>
                    <div className="w-[210px] border border-[#6f767e30] dark:border-[#3C4145] rounded-lg px-2 sm:px-5 py-2 sm:py-3 bg-stone-500/25 dark:bg-pexo-dark-950">
                        <div className="flex flex-col gap-1">
                            <h6 className="text-sm leading-[150%] text-pexo-gray-500 dark:text-pexo-gray-300">
                                To
                            </h6>
                            <div className="flex items-center gap-2 user-info" >
                                {selected.to == null ? <span
                                    className="w-8 h-8 rounded-full block overflow-hidden bg-gray-500/50 dark:bg-amber-700"></span> : <Image
                                    alt="toIcon"
                                    width={28}
                                    height={28}
                                    className="w-8 h-8 rounded-full overflow-hidden"
                                    src={selected.to.image}
                                    style={{ color: "transparent" }}
                                />}
                                <div className="flex flex-col">
                                    <h6 className="text-sm font-bold leading-[150%] text-pexo-gray-500 dark:text-white uppercase">
                                        {selected.to == null ? '---' : selected.to.symbol}
                                    </h6>
                                    <span className="text-xs leading-[150%] text-pexo-gray-500 dark:text-pexo-gray-400">
                                        On {selected.to == null ? '-------' : selected.to.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-3 border-pexo-gray-300 dark:border-[#3C4145]" />
                <div className="cls">
                    <h6 className="text-base font-semibold text-pexo-gray-500 dark:text-white">
                        Swap from
                    </h6>
                    <div className={classNames("flex items-center justify-between px-2 sm:px-5 py-1 sm:py-3 rounded-lg mt-3",
                        'border border-pexo-gray-300 dark:border-[#3C4145]',
                        "bg-stone-500/25 dark:bg-pexo-dark-950")}>
                        <ConvertWalletSelect selected={selected.from} wallets={items} handleSelect={handleChange} name={'from'} />

                        <form onSubmit={(e) => { e.preventDefault(); proceed() }}>
                            <div className="flex flex-1 flex-col items-end border-l border-pexo-gray-300 dark:border-[#3C4145]">
                                <input
                                    type="number"
                                    step={'any'}
                                    className={classNames("w-full text-lg leading-[150%] text-right outline-none bg-transparent",
                                        "text-pexo-gray-500 dark:text-white placeholder:text-pexo-gray-500 dark:placeholder:text-pexo-gray-300",
                                        "without-ring border-transparent focus:border-transparent focus:ring-0")}
                                    placeholder={0.0}
                                    name="value"
                                    value={value}
                                    onChange={handleChange}
                                />
                                <span className="text-base leading-[150%] text-right outline-none text-pexo-gray-400">
                                    {selected.from == null ? '---' : selected.from.balance}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-3">
                    <h6 className="text-base font-semibold text-pexo-gray-500 dark:text-white">
                        Swap to
                    </h6>
                    <div className="flex items-center justify-between border border-pexo-gray-300 dark:border-[#3C4145] px-2 sm:px-5 py-1 sm:py-3 rounded-lg mt-3 bg-stone-500/25 dark:bg-pexo-dark-950">
                        <ConvertWalletSelect selected={selected.to} wallets={items} handleSelect={handleChange} name={'to'} />
                        <div className="flex flex-1 flex-col items-end border-l border-pexo-gray-300 dark:border-[#3C4145]">
                            <input
                                type="number"
                                step={'any'}
                                className={classNames("w-full text-lg leading-[150%] text-right outline-none bg-transparent",
                                    "text-pexo-gray-500 dark:text-white placeholder:text-pexo-gray-500 dark:placeholder:text-pexo-gray-300",
                                    "without-ring border-transparent focus:border-transparent focus:ring-0")}
                                placeholder={0.0}
                                value={result}
                                readOnly
                            />
                            <span className="text-base leading-[150%] text-right outline-none text-pexo-gray-400">
                                {selected.to == null ? '---' : selected.to.balance}
                            </span>
                        </div>
                    </div>
                </div>
                <button onClick={proceed} className="w-full text-center text-lg leading-[150%] text-[#F8FAFC] bg-amber-500 rounded-lg p-2 mt-8">
                    Proceed
                </button>
            </div>
        </section>
    );
};

export default CryptoConverter;