import logo from 'assets/img/logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Disclosure } from "@headlessui/react";
import { Link, NavLink } from 'react-router-dom';
import { setTheme } from 'store/theme/theme-action';
import { selectTheme } from 'store/theme/theme.selector';
import { authLinks, links, navigation, navigationRight, profileLinks } from 'utils/helpers/links';
import { BiDownload } from 'react-icons/bi';
import NavGroupItem, { NavGroupItem2 } from './group-item.component';
import { selectUser } from 'store/user/user.selector';
import Badge, { badgeType } from 'components/badge/badge.component';
import { signOutStart } from 'store/user/user.actions';
import SideNotifications from 'components/toolkits/side-notifications.component';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const truncateEmail = (email = '') => {
    return email.substring(0, 3) + '...' + email.substring(email.indexOf('@'), email.length);
}
const AppHeader = ({ isHome = false }) => {
    const theme = useSelector(selectTheme),
        user = useSelector(selectUser),
        dispatch = useDispatch(),
        isSticky = (isHome ? 'fixed' : 'relative'),
        switchTheme = () => {
            const mode = theme === 'dark' ? 'light' : 'dark';
            dispatch(setTheme(mode))
        },
        logOut = () => {
            dispatch(signOutStart())
        }, userDropdown = user != null ? <div className="dropdown-menu dark:bg-pexo-gray-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
            <div>
                <button className="js-copy-clipboard font-display text-pexo-gray-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                    <span>{truncateEmail(user.email)}</span>

                </button>
            </div>
            <div className=" mx-5 mb-6">
                {user.kyc == null ? <Badge type={badgeType.warning} label='Not Verified' /> : user.kyc.verified ? <Badge type={badgeType.success} label='Verified' /> : <Badge type={badgeType.warning} label={"Pending"} />}

            </div>
            {profileLinks.map((link, i) => <Link key={i}
                className="dark:hover:bg-pexo-gray-600 mt-1 dark:text-white dark:hover:text-amber-500 hover:text-amber-500 focus:text-amber-500 hover:bg-pexo-gray-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                to={link.href}>
                {link.icon}

                <span className="font-display text-pexo-gray-700 text-sm dark:text-white">
                    {link.name}
                </span>
            </Link>)}

            <span role='button'
                className="dark:hover:bg-pexo-gray-600 mt-1 dark:text-white dark:hover:text-amber-500 hover:text-amber-500 focus:text-amber-500 hover:bg-pexo-gray-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                onClick={logOut}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-pexo-gray-700 h-4 w-4 transition-colors dark:fill-white"
                >

                    <use xlinkHref="#log-out-f" />  </svg>
                <span className="font-display text-pexo-gray-700 mt-1 text-sm dark:text-white">
                    Sign out
                </span>
            </span>
        </div> : <div className="dropdown-menu dark:bg-pexo-gray-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">

            <Link
                className="dark:hover:bg-pexo-gray-600 hover:text-amber-500 focus:text-amber-500 hover:bg-pexo-gray-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                to={authLinks.default + authLinks.login}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-pexo-gray-700 h-4 w-4 transition-colors dark:fill-white"
                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
                <span className="font-display text-pexo-gray-700 mt-1 text-sm dark:text-white">
                    Login
                </span>
            </Link>
            <Link
                className="dark:hover:bg-pexo-gray-600 hover:text-amber-500 focus:text-amber-500 hover:bg-pexo-gray-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                to={authLinks.default + authLinks.signUp}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-pexo-gray-700 h-4 w-4 transition-colors dark:fill-white"
                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
                <span className="font-display text-pexo-gray-700 mt-1 text-sm dark:text-white">
                    Sign-Up
                </span>
            </Link>

        </div>, toggleSettings = () => {
            const className = "offcanvas-open", body = document.body;
            if (body.classList.contains(className)) {
                body.classList.remove(className);
            } else {
                body.classList.add(className);
            }
        }

    return (
        <header className={`js-page-header hidden lg:block ${isSticky} top-0 z-20 w-full backdrop-blur transition-colors`}>

            <Disclosure as="div" className="flex items-center px-6 py-6 lg:py-2 xl:px-24 ">
                <Link className="shrink-0" to="/">
                    <div className="dark:hidden flex items-center justify-center">
                        <img
                            alt="Pexo - Trading and Exchange"
                            loading="lazy"
                            width={30}
                            decoding="async"
                            data-nimg={1}
                            className="max-h-7 h-auto "
                            style={{ color: "transparent" }}
                            src={logo}
                        /><h1 style={{ color: '#ffa800', textTransform: 'lowercase' }} className="font-bold  text-center text-4xl dark:text-white md:text-left lg:text-4xl xl:text-4xl">
                            EXO.
                        </h1>
                    </div>
                    <div className="hidden dark:flex items-center justify-center">
                        <img
                            alt="Pexo - Trading and Exchange"
                            loading="lazy"
                            width={30}
                            decoding="async"
                            data-nimg={1}
                            style={{ color: "transparent" }}
                            src={logo}
                        /><h1 style={{ color: '#ffa800', textTransform: 'lowercase' }} className="font-bold  text-center text-4xl dark:text-white md:text-left lg:text-4xl xl:text-4xl">
                            exo.
                        </h1>
                    </div>
                </Link>
                <div
                    className="relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]"
                >
                    <ul className="nc-Navigation items-center hidden lg:flex">
                        {navigation.map((item, i) =>
                            item.hasChildren ? (
                                <NavGroupItem item={item} key={i} />
                            ) : (
                                <li
                                    className="menu-item flex-shrink-0"
                                    key={i}
                                >
                                    <div className="h-20 flex-shrink-0 flex items-center">
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={({
                                                isActive,
                                            }) =>
                                                classNames(
                                                    isActive
                                                        ? "bg-gray-900 text-white"
                                                        : "text-gray-300 hover:bg-gray-700 hover:text-gray-500",
                                                    "inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
                                                )
                                            }
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </NavLink>
                                    </div>
                                </li>
                            )
                        )}
                    </ul>
                </div>
                <div className="js-mobile-menu invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
                    <nav className="navbar w-full">
                        <ul className="flex flex-col lg:flex-row">
                            <li className="flex items-center justify-center">
                                <Link to={links.default + links.deposit} className='rounded-xl bg-amber-500 bold text-white font-display hover:text-white focus:text-white dark:hover:text-white dark:focus:text-white flex items-center justify-between py-2 space-x-2 text-base  lg:px-5 w-full'>
                                    <BiDownload className='w-5 h-5' />
                                    <span>Deposit</span>
                                </Link>
                            </li>
                            {navigationRight.map((item, i) =>
                                item.hasChildren ? (
                                    <NavGroupItem2 item={item} key={i} />
                                ) : (
                                    <li
                                        className="menu-item flex-shrink-0"
                                        key={i}
                                    >
                                        <div className="h-20 flex-shrink-0 flex items-center">
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({
                                                    isActive,
                                                }) =>
                                                    classNames(
                                                        isActive
                                                            ? "bg-gray-900 text-white"
                                                            : "text-gray-300 hover:bg-gray-700 hover:text-gray-500",
                                                        "inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
                                                    )
                                                }
                                                aria-current={
                                                    item.current
                                                        ? "page"
                                                        : undefined
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                    <div className="ml-8 hidden items-center lg:flex xl:ml-12">
                        <span role="button" onClick={toggleSettings}><svg
                            viewBox="0 0 24 24"
                            width={23}
                            height={23}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-white dark:text-pexo-gray-950"
                        >
                            <path
                                fill="#FFA800"
                                d="M2 12.947v-1.771c0-1.047.85-1.913 1.899-1.913 1.81 0 2.549-1.288 1.64-2.868a1.919 1.919 0 0 1 .699-2.607l1.729-.996c.79-.474 1.81-.192 2.279.603l.11.192c.9 1.58 2.379 1.58 3.288 0l.11-.192c.47-.795 1.49-1.077 2.279-.603l1.73.996a1.92 1.92 0 0 1 .699 2.607c-.91 1.58-.17 2.868 1.639 2.868 1.04 0 1.899.856 1.899 1.912v1.772c0 1.047-.85 1.912-1.9 1.912-1.808 0-2.548 1.288-1.638 2.869.52.915.21 2.083-.7 2.606l-1.729.997c-.79.473-1.81.191-2.279-.604l-.11-.191c-.9-1.58-2.379-1.58-3.288 0l-.11.19c-.47.796-1.49 1.078-2.279.605l-1.73-.997a1.919 1.919 0 0 1-.699-2.606c.91-1.58.17-2.869-1.639-2.869A1.911 1.911 0 0 1 2 12.947Z"
                            />
                            <path
                                fill="currentColor"
                                d="M11.995 15.332c1.794 0 3.248-1.464 3.248-3.27 0-1.807-1.454-3.272-3.248-3.272-1.794 0-3.248 1.465-3.248 3.271 0 1.807 1.454 3.271 3.248 3.271Z"
                            />
                        </svg></span>
                        {user != null && <SideNotifications />}
                        <div className="js-nav-dropdown group-dropdown relative">
                            <button className="dropdown-toggle border-pexo-gray-100 hover:bg-amber-500 focus:bg-amber-500 group dark:hover:bg-amber-500 ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    className="fill-pexo-gray-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                                </svg>
                            </button>
                            {userDropdown}
                        </div>
                        <button
                            className="js-dark-mode-trigger dark:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-pexo-gray-100 bg-white transition-colors hover:border-transparent hover:bg-amber-500 focus:border-transparent focus:bg-amber-500 dark:border-transparent dark:bg-white/[.15] dark:hover:bg-amber-500"
                            aria-label="light"
                            onClick={switchTheme}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width={24}
                                height={24}
                                className="dark-mode-light h-4 w-4 fill-pexo-gray-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width={24}
                                height={24}
                                className="dark-mode-dark hidden h-4 w-4 fill-pexo-gray-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="ml-auto flex lg:hidden">
                    <a
                        className="border-pexo-gray-100 hover:bg-amber-500 focus:bg-amber-500 group dark:hover:bg-amber-500 ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                        aria-label="profile"
                        href="/profile/user_avatar"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            className="fill-pexo-gray-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                        </svg>
                    </a>
                    <button
                        className="js-dark-mode-trigger dark:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-pexo-gray-100 bg-white transition-colors hover:border-transparent hover:bg-amber-500 focus:border-transparent focus:bg-amber-500 dark:border-transparent dark:bg-white/[.15] dark:hover:bg-amber-500"
                        aria-label="light"
                        onClick={switchTheme}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            className="dark-mode-light h-4 w-4 fill-pexo-gray-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            className="dark-mode-dark hidden h-4 w-4 fill-pexo-gray-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                        </svg>
                    </button>
                    <button
                        className="js-mobile-toggle border-pexo-gray-100 hover:bg-amber-500 dark:hover:bg-amber-500 focus:bg-amber-500 group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                        aria-label="open mobile menu"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            className="fill-pexo-gray-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
                        </svg>
                    </button>
                </div>
            </Disclosure>
        </header>
    );
}

export default AppHeader;