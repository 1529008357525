import { Outlet } from "react-router-dom";
import React from 'react';
import AppHeader from "components/nav/app-header.component";
import BottomNavigation from "components/nav/bottom-nav.component";
import Footer from "components/nav/footer.component";

const Navigation = () => {


    return (
        <>
            <AppHeader isHome={true} />
            {/*END HEADER SECTION*/}
            <main><Outlet /></main>
            <Footer />
            {/* <SearchModel /> */}

            {/* <NavBar /> */}
        </>
    );
};
export const PageNavigation = () => {
    return (
        <>
            <AppHeader />
            <Outlet />

            <BottomNavigation />
            {/* <NavBar /> */}
        </>
    );
};

export default Navigation;
