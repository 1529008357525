import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectInfo } from "store/info/info.selector";
import { selectAllMarketValues, selectBTCMarketValues } from "store/market/market.selector";
import { selectSettings, selectWallets } from "store/user/user.selector";
import { getFinalPrice } from "utils/helpers/helpers";
import { stapleCoins } from "utils/helpers/staple-coins";
import { getNumberFormat } from "utils/initial-state/initial-state";

export const walletTypes = {
    funding: 'funding',
    spot: 'spot',
    earn: 'earn',
    all: 'all',
}
// const convert = () => {
//     const fromValue = Number(value) * selected.from.current_price,
//         base = fromValue / selected.to.current_price;
//     return base.toFixed(8);
// };
function BalanceCalc({ type = walletTypes.spot, vs = 'btc' }) {
    const
        [total, setTotal] = useState(parseFloat(0.00).toFixed(2));
    const wallets = useSelector(selectWallets),
        settings = useSelector(selectSettings),
        marketLists = useSelector(selectAllMarketValues),
        marketLists2 = useSelector(selectBTCMarketValues),
        // selectBTCMarketValues
        viewBalance = settings.view,
        siteInfo = useSelector(selectInfo),
        spotWallet = type === walletTypes.all ? wallets : wallets?.filter(i => i.type === walletTypes[type]);
    const getCurrentPrices = async () => {
        try {
            const query = spotWallet.map((i) => i.coin_id),
                res = vs === 'btc' ? marketLists2 : marketLists

            const response = res?.filter(i => query.includes(i.id)),
                stableCoinsMap = stapleCoins.map(i => i.id)
            const walletSum = spotWallet?.reduce((sum, item) => {
                const curPrice = response?.find(i => i.id === item.coin_id).current_price,
                    price = stableCoinsMap?.includes(item.coin_id) ? parseFloat(curPrice) : vs === 'usd' ? (getFinalPrice(curPrice, siteInfo.charge)) : parseFloat(curPrice),
                    amount = parseFloat(item.balance) * price;
                return parseFloat(parseFloat(sum) + amount).toFixed(vs === 'usd' ? 2 : 8)
            }, 0)
            // console.log("walletSum: ", walletSum);
            setTotal(walletSum)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (wallets != null) getCurrentPrices()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, vs, wallets])
    if (!viewBalance) return <span>**********</span>
    if (wallets == null) return <></>
    return <span>{vs === 'usd' ? getNumberFormat(total) : total}</span>
}

export default BalanceCalc;