
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { classNames } from 'utils/helpers/helpers';

export const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate, nextPage, prevPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const truncatedPageNumbers = (() => {
        if (pageNumbers.length <= 5) {
            return pageNumbers;
        }

        if (currentPage <= 3) {
            return [...pageNumbers.slice(0, 4), '...', pageNumbers.length];
        }

        if (currentPage >= pageNumbers.length - 2) {
            return [1, '...', ...pageNumbers.slice(pageNumbers.length - 4)];
        }

        return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pageNumbers.length];
    })();
    const prev = () => {
        if (currentPage === 1) {
            return;
        }
        // goToTop();
        prevPage();
    };
    const next = () => {
        console.log(pageNumbers.length);
        if (pageNumbers.length === currentPage) {
            return;
        }
        // goToTop();
        nextPage();
    };
    return totalItems.length === 0 ? <></> : (
        <nav className="flex items-center justify-center mt-4">
            <ul className="pagination inline-flex -space-x-px text-sm">
                <li className="cursor-pointer">
                    <button onClick={prev} className={classNames(
                        'flex items-center justify-center px-3 h-8 ml-0 leading-tight',
                        'text-pexo-gray-500 bg-white border border-pexo-gray-300 rounded-l-lg hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                        'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-100 dark:hover:bg-pexo-gray-950 dark:hover:text-white'
                    )}
                    //  "px-3 py-1 rounded bg-pexo-gray-200 hover:bg-pexo-gray-300"
                    >
                        <span className="sr-only">Previous</span>
                        <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                        </svg>
                    </button>
                </li>

                {truncatedPageNumbers.map((number, index) => (
                    <li key={index} className="cursor-pointer">
                        {number === '...' ? (
                            <span className="px-3 py-1 text-pexo-gray-950 dark:text-pexo-gray-200">...</span>
                        ) : (
                            <button onClick={() => paginate(number)}
                                className={classNames(
                                    'flex items-center justify-center px-3 h-8 leading-tight',
                                    currentPage === number ?
                                        classNames('text-amber-600 border border-pexo-gray-300 bg-amber-50 hover:bg-amber-100 hover:text-amber-700',
                                            'dark:border-pexo-gray-950 dark:bg-amber-500 dark:text-white') :
                                        classNames('text-pexo-gray-500 bg-white border border-pexo-gray-300 hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                                            'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-200 dark:hover:bg-pexo-gray-950 dark:hover:text-white')
                                )}
                            // className={`px-3 py-1 rounded ${currentPage === number ? 'bg-amber-500 text-white' : 'bg-pexo-gray-200 hover:bg-pexo-gray-300'}`}
                            >
                                {number}
                            </button>
                        )}
                    </li>
                ))}

                {currentPage < pageNumbers.length && (
                    <li className="cursor-pointer">
                        <button onClick={next} className={classNames(
                            'flex items-center justify-center px-3 h-8 leading-tight rounded-r-lg',
                            'text-pexo-gray-500 bg-white border border-pexo-gray-300 hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                            'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-400 dark:hover:bg-pexo-gray-950 dark:hover:text-white'
                        )}
                        >
                            <span className="sr-only">Next</span>
                            <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;

export const PagePagination = ({ handlePaginate, links }) => {

    const _pageNumbers = links.filter(i => i.label !== "&laquo; Previous" && i.label !== "Next &raquo;"),
        pageNumbers = _pageNumbers.map(i => Number(i.label)),
        _currentPage = links.find(i => i.active === true),
        currentPage = Number(_currentPage.label),
        truncatedPageNumbers = (() => {
            if (pageNumbers.length <= 5) {
                return pageNumbers;
            }

            if (currentPage <= 3) {
                return [...pageNumbers.slice(0, 4), '...', pageNumbers.length];
            }

            if (currentPage >= pageNumbers.length - 2) {
                return [1, '...', ...pageNumbers.slice(pageNumbers.length - 4)];
            }

            return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pageNumbers.length];
        })(),
        paginate = (number) => {
            const item = links.find(i => Number(i.label) === Number(number))
            handlePaginate(item.url, item.active)
        },
        prev = () => {
            const item = links.find(i => i.label === "&laquo; Previous")
            handlePaginate(item.url, item.active)
        },
        next = () => {
            const item = links.find(i => i.label === "Next &raquo;")
            handlePaginate(item.url, item.active)
        }
    console.log(truncatedPageNumbers);
    return (
        <div className="flex flex-col my-2 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <nav className="flex items-center justify-center mt-4">
                <ul className="pagination inline-flex -space-x-px text-sm">
                    <li className="cursor-pointer">
                        <button onClick={prev} className={classNames(
                            'flex items-center justify-center px-3 h-8 ml-0 leading-tight',
                            'text-pexo-gray-500 bg-white border border-pexo-gray-300 rounded-l-lg hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                            'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-100 dark:hover:bg-pexo-gray-950 dark:hover:text-white'
                        )}
                        //  "px-3 py-1 rounded bg-pexo-gray-200 hover:bg-pexo-gray-300"
                        >
                            <span className="sr-only">Previous</span>
                            <BiChevronLeft className="w-2.5 h-2.5" />
                        </button>
                    </li>

                    {truncatedPageNumbers.map((number, index) => (
                        <li key={index} className="cursor-pointer">
                            {number === '...' ? (
                                <span className="px-3 py-1 text-pexo-gray-950 dark:text-pexo-gray-200">...</span>
                            ) : (
                                <button onClick={() => paginate(number)}
                                    className={classNames(
                                        'flex items-center justify-center px-3 h-8 leading-tight',
                                        currentPage === number ?
                                            classNames('text-amber-600 border border-pexo-gray-300 bg-amber-50 hover:bg-amber-100 hover:text-amber-700',
                                                'dark:border-pexo-gray-950 dark:bg-amber-500 dark:text-white') :
                                            classNames('text-pexo-gray-500 bg-white border border-pexo-gray-300 hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                                                'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-200 dark:hover:bg-pexo-gray-950 dark:hover:text-white')
                                    )}
                                // className={`px-3 py-1 rounded ${currentPage === number ? 'bg-amber-500 text-white' : 'bg-pexo-gray-200 hover:bg-pexo-gray-300'}`}
                                >
                                    {number}
                                </button>
                            )}
                        </li>
                    ))}

                    {currentPage < pageNumbers.length && (
                        <li className="cursor-pointer">
                            <button onClick={next} className={classNames(
                                'flex items-center justify-center px-3 h-8 leading-tight rounded-r-lg',
                                'text-pexo-gray-500 bg-white border border-pexo-gray-300 hover:bg-pexo-gray-100 hover:text-pexo-gray-950',
                                'dark:bg-pexo-gray-900 dark:border-pexo-gray-950 dark:text-pexo-gray-400 dark:hover:bg-pexo-gray-950 dark:hover:text-white'
                            )}
                            >
                                <span className="sr-only">Next</span>

                                <BiChevronRight className="w-2.5 h-2.5" />
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
            {/* <nav className="nc-Pagination inline-flex space-x-1 text-base font-medium ">
                {links.map((item, i) => {
                    switch (item.label) {
                        case "&laquo; Previous":
                            return (
                                <span
                                    className={
                                        item.active
                                            ? "inline-flex w-11 h-11 items-center justify-center rounded-full bg-amber-500 text-white focus:outline-none"
                                            : "inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 focus:outline-none"
                                    }
                                    role="button"
                                    onClick={() => handlePaginate(item.url, item.active)}
                                    key={i}>
                                    <BiChevronLeft className="w-5 h-5" />
                                </span>
                            );
                        case "Next &raquo;":
                            return (
                                <span
                                    className={
                                        item.active
                                            ? "inline-flex w-11 h-11 items-center justify-center rounded-full bg-amber-500 text-white focus:outline-none"
                                            : "inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 focus:outline-none"
                                    }
                                    role="button"
                                    onClick={() => handlePaginate(item.url, item.active)}
                                    key={i}>
                                    <BiChevronRight className="w-5 h-5" />
                                </span>
                            );

                        default:
                            return (
                                <span
                                    className={
                                        item.active
                                            ? "inline-flex w-11 h-11 items-center justify-center rounded-full bg-amber-500 text-white focus:outline-none"
                                            : "inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 focus:outline-none"
                                    }
                                    role="button"
                                    onClick={() => handlePaginate(item.url, item.active)}
                                    key={i}>
                                    {item.label}
                                </span>
                            );
                    }
                })}
            </nav> */}
            {/* <button className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-amber-500 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6  ">
            Show me more
        </button> */}
        </div>
    );
};
