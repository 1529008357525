import { NavLink } from "react-router-dom";
import { links } from "utils/helpers/links";
const activeClass = 'active text-amber-500 dark:text-amber-500 bg-amber-50 dark:bg-amber-800'
function BottomNavigation() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (<div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-amber-600 border-opacity-10 dark:bg-jacarta-800 ">
        <div className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium">
            <NavLink
                to={links.home}
                onClick={scrollToTop}
                type="button"
                className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-amber-50 dark:hover:bg-amber-800 ${isActive ? activeClass : ''} group`}
            >
                <svg
                    className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                </svg>
                <span className="text-sm text-gray-500 dark:text-pexo-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500">
                    Home
                </span>
            </NavLink>
            <NavLink
                to={links.default + links.market.overview}
                onClick={scrollToTop}
                className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-amber-50 dark:hover:bg-amber-800 ${isActive ? activeClass : ''} group`}
            >
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500"
                >
                    <path d="M5.92 15.12H0V24H5.92V15.12Z" fill="currentColor" fillOpacity="0.5" />
                    <path d="M14.96 0H9.04004V24H14.96V0Z" fill="currentColor" fillOpacity="0.5" />
                    <path d="M24.0001 7.97339H18.0801V24.0001H24.0001V7.97339Z" fill="currentColor" fillOpacity="0.5" />
                    <path d="M5.92 19.5601H0V24.0001H5.92V19.5601Z" fill="currentColor" />
                    <path d="M14.96 12H9.04004V24H14.96V12Z" fill="currentColor" />
                    <path d="M24.0001 15.9867H18.0801V24H24.0001V15.9867Z" fill="currentColor" />
                </svg>
                <span className="text-sm text-gray-500 dark:text-pexo-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500">
                    Market
                </span>

            </NavLink>
            <NavLink
                to={links.default + links.trade.spot}
                onClick={scrollToTop}
                className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-amber-50 dark:hover:bg-amber-800 ${isActive ? activeClass : ''} group`}
            >

                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500"

                    fill="currentColor"   >
                    <circle cx="9.78947" cy="14.2105" r="9.78947" />
                    <circle cx="17.6842" cy="6.31579" r="6.31579" fillOpacity="0.5" />
                    <rect x="6.3158" y="14.3096" width="5.05263" height="5.05263" transform="rotate(-45 6.3158 14.3096)" fill="#161514" />
                </svg>

                <span className="text-sm text-gray-500 dark:text-pexo-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500">
                    Trade
                </span>
            </NavLink>
            <NavLink
                to={links.default + links.wallet.default + links.wallet.overview}
                onClick={scrollToTop}
                className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-amber-50 dark:hover:bg-amber-800 ${isActive ? activeClass : ''}  group`}
            >
                <svg className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500"
                    viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.446594 9.16002V6.48002L18.4466 -0.65332V9.16002H0.446594Z" fillOpacity="0.5" />
                    <path d="M0.446594 6.42664V23.2266H24.4466V6.42664H0.446594ZM12.7132 17.4533L10.0466 14.7866L12.7132 12.12L15.3799 14.7866L12.7132 17.4533Z" />
                    <path d="M12.5363 17.6835L12.7131 17.8603L12.8899 17.6835L15.5566 15.0168L15.7333 14.84L15.5566 14.6633L12.8899 11.9966L12.7131 11.8198L12.5363 11.9966L9.86967 14.6633L9.69289 14.84L9.86967 15.0168L12.5363 17.6835ZM9.67823 10.3381C10.5761 9.73552 11.6324 9.41257 12.7137 9.41005C13.4331 9.40837 14.1458 9.54861 14.8109 9.82276C15.476 10.0969 16.0806 10.4996 16.5898 11.0077C17.0991 11.5158 17.5032 12.1193 17.7789 12.7838C18.0546 13.4483 18.1965 14.1606 18.1965 14.88C18.1965 15.9614 17.876 17.0184 17.2755 17.9177C16.675 18.8169 15.8214 19.518 14.8227 19.9324C13.8239 20.3468 12.7247 20.4559 11.664 20.2459C10.6033 20.0358 9.62861 19.5161 8.86311 18.7524C8.09761 17.9887 7.57564 17.0152 7.36314 15.955C7.15064 14.8947 7.25713 13.7953 7.66918 12.7956C8.08124 11.7959 8.78037 10.9407 9.67823 10.3381Z" fill-opacity="0.5" stroke="#161514" stroke-width="0.5" />
                </svg>

                <span className="text-sm text-gray-500 dark:text-pexo-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500">
                    Wallet
                </span>
            </NavLink>
            <NavLink
                to={links.default + links.profile}
                onClick={scrollToTop}
                className={({ isActive }) => `inline-flex flex-col items-center justify-center px-5 hover:bg-amber-50 dark:hover:bg-amber-800 ${isActive ? activeClass : ''}  group`}
            >
                <svg className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500"
                    viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0159 12C12.7773 12 15.0159 9.31371 15.0159 6C15.0159 2.68629 12.7773 0 10.0159 0C7.25445 0 5.01587 2.68629 5.01587 6C5.01587 9.31371 7.25445 12 10.0159 12Z" />
                    <path d="M19.8158 17.1C18.9158 15.3 17.2158 13.8 15.0158 12.9C14.4158 12.7 13.7158 12.7 13.2158 13C12.2158 13.6 11.2158 13.9 10.0158 13.9C8.81584 13.9 7.81584 13.6 6.81584 13C6.31584 12.8 5.61584 12.7 5.01584 13C2.81584 13.9 1.11584 15.4 0.215842 17.2C-0.484158 18.5 0.615842 20 2.11584 20H17.9158C19.4158 20 20.5158 18.5 19.8158 17.1Z" />
                </svg>

                <span className="text-sm text-gray-500 dark:text-pexo-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500">
                    Profile
                </span>
            </NavLink>
        </div>
    </div>
    );
}

export default BottomNavigation;