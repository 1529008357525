
import { Link } from "react-router-dom";
import { classNames } from "utils/helpers/helpers";
import { links } from "utils/helpers/links";
import { AlertMsg } from "../wallet";
import { useSelector } from "react-redux";
import { selectUser, selectWallets } from "store/user/user.selector";
import CryptoConverter from "components/convert/converter.component";
import { walletTypes } from "components/toolkits/balance.component";
import { alertMessage } from "utils/initial-state/initial-state";



function Convert() {
    const user = useSelector(selectUser),
        _wallets = useSelector(selectWallets),
        wallets = _wallets?.filter(wallet => wallet.type === walletTypes.spot),
        checkStatus = () => {
            if (user.kyc == null || !user.kyc.verified) {
                alertMessage('warn', 'Please complete Identity Verification (KYC) to continue using our services.')
            }
        }

    return (<div className={classNames("ltr:flex flex-1 rtl:flex-row-reverse", "col-span-12 lg:col-span-10 space-y-6  lg:block relative")}>
        <div className="page-wrapper relative ltr:ml-auto rtl:mr-auto rtl:ml-0  px-4 pt-0  duration-300">
            <div className="xl:w-full">
                <div className="flex flex-wrap">
                    <div className="flex items-center py-4 w-full">
                        <div className="w-full">
                            <div className="">
                                <div className="flex flex-wrap justify-between">
                                    <div className="items-center ">
                                        <h1 className="font-semibold text-xl mb-1 block dark:text-slate-100 capitalize">
                                            Convert
                                        </h1>

                                    </div>
                                    <div className="flex items-center">
                                        <Link to={links.default + links.wallet.default + links.wallet.overview} className="px-3 py-2 lg:px-4 bg-amber-500 collapse:bg-green-100 text-white text-sm font-semibold rounded hover:bg-amber-600">
                                            Wallet
                                        </Link>
                                    </div>

                                </div>
                                {user.kyc == null ?
                                    <AlertMsg message='Please complete Identity Verification (KYC) to continue using our services.' /> : !user.kyc.verified ? <AlertMsg message='Please wait while we verify your identity...' /> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end container*/}
            {/*end container*/}
            <section onClick={checkStatus}>
                <section className={classNames("flex flex-col xl:flex-row gap-5 mx-2 sm:mx-6 mt-5 sm:mt-10 mb-10",
                    user.kyc == null || !user.kyc.verified ? 'opacity-50 pointer-events-none cursor-not-allowed' : '')} >
                    <CryptoConverter wallets={wallets} />
                </section>
            </section>

            {/*end container*/}
        </div>
    </div>
    );
}

export default Convert;