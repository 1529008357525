import { lazy, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PageNavigation } from "routes/navigation/navigation.component";
import { links } from "utils/helpers/links";
import { useSelector } from "react-redux";
import { selectIsLoggedIn, selectUser } from "store/user/user.selector";
import Preloader from "components/preloader/preloader.component";
import NotFound404 from "errors/404";
import { REDIRECT_URI } from "utils/initial-state/states";
import ComingSoon from "errors/coming-soon";
import WalletType from "./wallet/type";
import { walletTypes } from "components/toolkits/balance.component";
import Convert from "./convert";
import { selectAllMarketValues } from "store/market/market.selector";


const Transfer = lazy(() => import("./transfer"));
const CryptoMarketTradePage = lazy(() => import("./trade/page-market"));
const WalletFunding = lazy(() => import("./wallet/funding"));
const CoinItemPage = lazy(() => import("./wallet/item"));
const Notifications = lazy(() => import("./notification"));
const CryptoTradeSpot = lazy(() => import("./trade"));
const CryptoTradePage = lazy(() => import("./trade/page"));
const Settings = lazy(() => import("./settings"));
const Security = lazy(() => import("./security"));
const Withdraw = lazy(() => import("./withdraw"));
const Wallet = lazy(() => import("./wallet"));
const Deposit = lazy(() => import("./deposit"));
const MarketOverview = lazy(() => import("./markets/market-overview"));
const MarketTrading = lazy(() => import("./markets/trading"));
const Kyc = lazy(() => import("./kyc"));
const Profile = lazy(() => import("./profile"));
const Dashboard = lazy(() => import("./dashboard"));
const Referral = lazy(() => import("./referral"))
const ApplyForKYC = lazy(() => import("./kyc/apply"));
const Transactions = lazy(() => import("./transactions"));

function AppRoutes() {
    const isLoggedIn = useSelector(selectIsLoggedIn),
        user = useSelector(selectUser),
        market = useSelector(selectAllMarketValues),
        navigate = useNavigate()

    var baseurl = window.location.pathname;
    useEffect(() => {
        if (!isLoggedIn) {
            sessionStorage.setItem(REDIRECT_URI, baseurl);
            navigate('/auth?redirect=' + baseurl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])
    if (!isLoggedIn || user == null || market == null) return <Preloader />

    return (<Routes>
        <Route path="/" element={<PageNavigation />}>
            <Route path={links.market.overview} element={<MarketOverview />} />
            <Route path={links.market.trading} element={<MarketTrading />} />
            <Route path={links.dashboard} element={<Dashboard />} />
            <Route path={links.profile} element={<Profile />} />
            <Route path={links.user.referral} element={<Referral />} />
            <Route path={links.notifications} element={<Notifications />} />
            <Route path={links.transactions} element={<Transactions />} />
            <Route path={links.user.setting} element={<Settings />} />
            <Route path={links.user.security} element={<Security />} />
            <Route path={links.trade.spot}>
                <Route index element={<CryptoTradeSpot />} />
                <Route path={`market/:symbol/:coin_id`} element={<CryptoMarketTradePage />} />
                <Route path={`:symbol/:coin_id`} element={<CryptoTradePage />} />
            </Route>
            <Route path={`wallet-detail/:type/:coin_id`} element={<CoinItemPage />} />
            <Route path={links.kyc} element={<Kyc />} />
            <Route path={links.identification.apply} element={<ApplyForKYC />} />
            <Route path={links.convert} element={<Convert />} />
            <Route path={links.deposit} element={<Deposit />} />
            <Route path={links.wallet.default + links.wallet.overview} element={<Wallet />} />
            <Route path={links.wallet.default + links.wallet.transfer} element={<Transfer />} />
            <Route path={links.wallet.default + links.wallet.spot} element={<WalletType type={walletTypes.spot} />} />
            <Route path={links.wallet.default + links.wallet.funding} element={<WalletFunding type={walletTypes.funding} />} />
            <Route path={links.wallet.default + links.wallet.withdraw} element={<Withdraw />} />
        </Route>
        <Route path={links.wallet.default + links.wallet.pexo} element={<ComingSoon />} />
        <Route path={links.wallet.default + links.wallet.nft} element={<ComingSoon />} />
        <Route path="/*" element={<NotFound404 />} />
    </Routes>);
}

export default AppRoutes;