import { createSlice } from "@reduxjs/toolkit";

const infoSlice = createSlice({
  name: "info",
  initialState: {
    siteInfo: null,
    plans: null,
    accounts: null,
    notifyTone: false,
    locked:false
  },
  reducers: {
    setInfo(state, action) {
      state.siteInfo = action.payload;
    },
    setLocked(state, action) {
      state.locked = action.payload;
    },
    setPlans(state, action) {
      state.plans = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setNotifyTone(state, action) {
      state.notifyTone = action.payload;
    },
  },
});

export const infoActions = infoSlice.actions;
export default infoSlice;
