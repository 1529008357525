import { Popover, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { BiSolidChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
// duration can be tweaked at convenience
const timeoutDuration = 120

function NavGroupItem({ item }) {
    const triggerRef = useRef()
    const timeOutRef = useRef()

    const handleEnter = (isOpen) => {
        clearTimeout(timeOutRef.current)
        !isOpen && triggerRef.current?.click()
    }

    const handleLeave = (isOpen) => {
        timeOutRef.current = setTimeout(() => {
            isOpen && triggerRef.current?.click()
        }, timeoutDuration)
    }

    return (<Popover
        as="li"
        className="menu-item menu-dropdown group relative"
    >
        {({ open, close }) => (
            <div
                onMouseEnter={() => handleEnter(open)}
                onMouseLeave={() => handleLeave(open)}>
                <Popover.Button
                    as="div"
                    className="h-20 flex-shrink-0 flex items-center"
                    ref={triggerRef}
                >
                    <span
                        role="button"
                        className="dropdown-toggle text-jacarta-700 font-display hover:text-amber-500 focus:text-amber-500 dark:hover:text-amber-500 dark:focus:text-amber-500 flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
                    >
                        {item.name}
                        <BiSolidChevronDown
                            className="h-5 w-5 flex-none text-gray-400 dark:text-gray-100 ml-1"
                            aria-hidden="true"
                        />
                    </span>
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800">
                        <div className="p-4">
                            {item.children.map(
                                (
                                    item,
                                    i
                                ) => (
                                    <Link
                                        to={item.href}
                                        key={
                                            i
                                        }
                                        className="group relative flex items-center gap-x-3 rounded-lg p-4 text-sm leading-6 hover:bg-amber-50 dark:hover:bg-black"
                                    >
                                        <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                            <span className="bg-amber-100 dark:bg-amber-500 dark:bg-opacity-10 h-10 w-10 rounded-md flex justify-center items-center "> {item.icon}</span>
                                        </div>
                                        <div className="ml-4">
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                                                {item.name}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {item.description}
                                            </p>
                                        </div>
                                    </Link>
                                )
                            )}
                        </div>
                    </Popover.Panel>
                </Transition>
            </div>)}

    </Popover>);
}
export const NavGroupItem2 = ({ item }) => {
    const triggerRef = useRef()
    const timeOutRef = useRef()

    const handleEnter = (isOpen) => {
        clearTimeout(timeOutRef.current)
        !isOpen && triggerRef.current?.click()
    }

    const handleLeave = (isOpen) => {
        timeOutRef.current = setTimeout(() => {
            isOpen && triggerRef.current?.click()
        }, timeoutDuration)
    }

    return (<Popover
        as="li"
        className="js-nav-dropdown group relative"
    >
        {({ open, close }) => (
            <div
                onMouseEnter={() => handleEnter(open)}
                onMouseLeave={() => handleLeave(open)}>
                <Popover.Button
                    as="button"
                    className="dropdown-toggle text-jacarta-700 font-display hover:text-amber-500 focus:text-amber-500 dark:hover:text-amber-500 dark:focus:text-amber-500 flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
                    ref={triggerRef}
                >

                    <span className="text-amber-500  dark:text-amber-500">{item.name}</span>
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 min-w-[300px] max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800">
                        <div className="p-4">
                            {item.children.map(
                                (
                                    item,
                                    i
                                ) => (
                                    <Link
                                        to={item.href}
                                        key={
                                            i
                                        }
                                        className="hover:bg-amber-500/10 dark:hover:bg-amber-500/10 text-jacarta-500 dark:text-white hover:text-amber-500 focus:text-amber-500  dark:hover:text-amber-500 flex items-center rounded-xl px-5 py-2 transition-colors"
                                    >
                                        <div className="mr-2 rounded-xl">
                                            <span className="bg-amber-100 dark:bg-amber-500 dark:bg-opacity-10 p-[0.375rem] rounded-md flex justify-center items-center"> {item.icon}</span>
                                        </div>
                                        <div className="ml-1">
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                                                {item.name}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {item.description}
                                            </p>
                                        </div>
                                    </Link>
                                )
                            )}
                        </div>
                    </Popover.Panel>
                </Transition>
            </div>)}

    </Popover>);
}
export default NavGroupItem;