import Image from "components/image/image.component";
import Pagination from "components/pagination/pagination.component";
import { classNames, getFinalPrice, getPercent } from "utils/helpers/helpers";
import { links } from "utils/helpers/links";
import { getNumberFormat } from "utils/initial-state/initial-state";
import { ITEMS_PER_PAGE } from "./balance-table.component";
import { useSelector } from "react-redux";
import { selectSettings } from "store/user/user.selector";
import { walletTypes } from "components/toolkits/balance.component";

const BalanceTableLg = ({ page, currentItems, type, stableCoinsMap, siteInfo, goToLink, marketList, paginate, nextPage, prevPage }) => {
    const settings = useSelector(selectSettings),
        viewBalance = settings.view
    return (<div className="relative overflow-x-auto sm:rounded-lg">


        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-2">
            <thead className="text-xs text-gray-700 capitalize dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="flex items-center">
                            Balance

                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="flex items-center">
                            change

                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Actions</span>
                    </th>
                </tr>
            </thead>

            <tbody>
                {currentItems == null ? (new Array(15)).fill(0).map((item, i) => <tr key={i} className="border-b dark:border-gray-700 animate__infinite animate__slower animate__animated animate__flash">
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center gap-2"
                    >
                        <div class="rounded-full bg-gray-500/50 dark:bg-amber-700 dark:bg-opacity-25 h-10 w-10"></div>
                        <div class="h-5 w-[100px] bg-gray-500/50 rounded-full dark:bg-amber-700 dark:bg-opacity-25"></div>
                    </th>
                    <td className="px-6 py-4"><div class="h-5 bg-gray-500/50 rounded-full dark:bg-amber-700 dark:bg-opacity-25"></div></td>
                    <td className="px-6 py-4"><div class="h-5 bg-gray-500/50 rounded-full dark:bg-amber-700 dark:bg-opacity-25"></div></td>
                    <td className="px-6 py-4">
                        <div class="h-5 bg-gray-500/50 rounded-full dark:bg-amber-700 dark:bg-opacity-25"></div>
                    </td>
                </tr>) : currentItems?.map((item, index) => <tr key={index} className="border-b dark:border-gray-700">
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center space-x-3"
                    >
                        <Image src={item.image} class="rounded-full h-7 w-7" />
                        <span className="text-sm bold text-black dark:text-white uppercase">{item.symbol}</span>
                        <span className="text-sm text-gray-600 dark:text-gray-400">{item.name}</span>
                    </th>
                    <td className="px-6 py-4 ">
                        {viewBalance ? <div className="flex flex-col">
                            <span className='text-gray-600 dark:text-white text-[16px]'>{item.balance === '0.00' ? "0.00" : parseFloat(item.balance).toString()}</span>
                            <small className="text-amber-500 text-[14px]">≈ {stableCoinsMap.includes(item.id) ? getNumberFormat((parseFloat(item.balance) * parseFloat(item.current_price)).toFixed(2)) : getNumberFormat(parseFloat(parseFloat(item.balance) * getFinalPrice(item.current_price, siteInfo.charge)).toFixed(2))}</small>
                        </div> : <div className="flex flex-col">
                            <span className='text-gray-600 dark:text-white text-[16px]'>*********</span>
                            <small className="text-amber-500 text-[14px]">≈ *********</small>
                        </div>}

                    </td>

                    <td className="px-6 py-4">{getPercent((item.price_change_percentage_24h)?.toFixed(2))}</td>

                    {type === walletTypes.spot ? <td className="px-6 py-4 text-gray-600 dark:text-white">
                        <div className="inline-flex rounded-md shadow-sm">

                            <span onClick={() => goToLink(item)}
                                role="button"
                                className={classNames("px-4 py-2 text-sm font-medium",
                                    "text-amber-500 bg-white border border-gray-200 rounded-l-lg hover:bg-amber-100",
                                    'focus:z-10 focus:ring-2 focus:ring-amber-500 focus:text-amber-500',
                                    "dark:bg-transparent dark:border-0 dark:text-white dark:hover:text-amber-500",
                                    "dark:hover:text-amber-500 dark:focus:ring-blue-500 dark:focus:text-amber-500")}
                            >
                                Deposit
                            </span>
                            <span role="button" onClick={() => item.user_id == null ? {} : goToLink(item, links.default + links.convert)}
                                className="px-4 py-2 text-sm font-medium text-amber-500 bg-white border-t border-b border-gray-200 hover:bg-amber-100 focus:z-10 focus:ring-2 focus:ring-amber-500 focus:text-amber-500 dark:bg-transparent dark:border-0 dark:text-white dark:hover:text-amber-500 dark:hover:text-amber-500 dark:focus:ring-blue-500 dark:focus:text-amber-500"
                            >
                                Convert
                            </span>
                            <span role="button" onClick={() => goToLink(item, links.default + links.trade.spot + `/${item.symbol}/${item.coin_id}`)}
                                className="px-4 py-2 text-sm font-medium text-amber-500 bg-white border-t border-b border-gray-200 hover:bg-amber-100 focus:z-10 focus:ring-2 focus:ring-amber-500 focus:text-amber-500 dark:bg-transparent dark:border-0 dark:text-white dark:hover:text-amber-500 dark:hover:text-amber-500 dark:focus:ring-blue-500 dark:focus:text-amber-500"
                            >
                                Trade
                            </span>
                            <span onClick={() => item.user_id == null ? {} : goToLink(item, links.default + links.wallet.default + links.wallet.withdraw)}
                                role="button"
                                className={classNames("px-4 py-2 text-sm font-medium text-amber-500 bg-white",
                                    "border border-gray-200 rounded-r-md hover:bg-amber-100 focus:z-10 focus:ring-2",
                                    "focus:ring-amber-500 focus:text-amber-500 dark:bg-transparent dark:border-0 dark:text-white ",
                                    "dark:hover:text-amber-500 dark:hover:text-amber-500 dark:focus:ring-blue-500 dark:focus:text-amber-500",
                                    item.user_id == null ? 'opacity-10' : '')}
                            >
                                Withdraw
                            </span>
                        </div>

                    </td> :
                        <td></td>}
                </tr>)}
            </tbody>

        </table>
        <Pagination currentPage={page} itemsPerPage={ITEMS_PER_PAGE} totalItems={marketList == null ? 0 : marketList?.length}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage} />
    </div>);
}

export default BalanceTableLg;