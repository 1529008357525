import { Dialog, Transition } from '@headlessui/react'
import { CustomInput } from 'components/form-input/form-input.component';
import Image from 'components/image/image.component';
import { Fragment, useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import { classNames } from 'utils/helpers/helpers';
import empty from 'assets/img/empty.svg'
import emptyLight from 'assets/img/empty-light.svg'
import { useLocation } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi';

export default function ConvertWalletSelect({ selected = null, handleSelect = () => { }, wallets, name }) {
    const [searchValue, setSearchValue] = useState(''),
        [isOpen, setIsOpen] = useState(false),
        { state } = useLocation(),
        filtered = wallets?.filter(item => String(item.name).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || String(item.symbol).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    const closeModal = () => {
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }
    const handleClick = (value) => {
        handleSelect({
            target: {
                name, value
            }
        });
        closeModal()
    }

    useEffect(() => {
        if (state != null) {
            const _selected = wallets?.find(wallet => wallet.coin_id === state.coin.coin_id)
            handleClick(_selected)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, wallets])
    return (
        <>

            <div className="min-w-[113px] relative">
                <div className="relative mr-5">
                    <button
                        className="flex items-center relative w-full text-sm font-bold text-pexo-gray-500 dark:text-white uppercase"
                        id="headlessui-listbox-button-:r5:"
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded="false"
                        data-headlessui-state=""
                        onClick={openModal}
                    >
                        {selected == null ? <>
                            <span className="pl-2 pr-5">Select coin</span>
                            <BiChevronDown className="text-[24px]" /></> : <><Image src={selected.image}
                                width={28}
                                height={28}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                            />
                            <span className="pl-2 pr-5">{selected.symbol}</span>
                            <BiChevronDown className="text-[24px]" /></>}
                    </button>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex flex-col z-50 min-h-full items-center justify-end p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 pt-0 text-left align-middle shadow-xl transition-all offcanvas">
                                    <button type="button" onClick={closeModal} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="crypto-modal">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white"
                                        >
                                            Select coin to withdraw
                                        </Dialog.Title>

                                    </div>
                                    <div className="h-[470px]">
                                        <div className="mt-2 relative h-[48px]">
                                            <div className="col-span-11 lg:col-start-4 lg:col-span-6">
                                                <CustomInput
                                                    type="search"
                                                    id="searchValue"
                                                    name="searchValue"
                                                    placeholder="Search Coin Name"
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    icon={<AiOutlineSearch className="text-stone-500 dark:text-amber-500 opacity-50" />}
                                                    required />
                                            </div>
                                        </div>

                                        <div className="flex-auto p-4 scrollbar-sm overflow-y-auto h-full">
                                            <ol className="list-none">
                                                {wallets == null ?
                                                    <div className='flex flex-col gap-2 items-center justify-center'>
                                                        <img src={empty} alt='empty-icon' className='w-50 h-50 dark:hidden' />
                                                        <img src={emptyLight} alt='empty-icon' className='w-50 h-50 hidden dark:flex' />
                                                        <span className='text-pexo-gray-800 dark:text-pexo-gray-200 text-sm font-medium'>No Balance</span>
                                                    </div> : filtered.map((item, i) => <li key={i} className={classNames("mb-1 text-slate-700 dark:text-slate-400 rounded",
                                                        "cursor-pointer hover:bg-amber-500/10 dark:hover:bg-[#000000]/50 ",
                                                        selected?.id !== item.id ? '' : 'bg-amber-500/10 dark:bg-[#000000]/50')}
                                                        onClick={() => handleClick(item.symbol)}>
                                                        <div
                                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center justify-between space-x-3"
                                                        >
                                                            <div className='flex items-center space-x-3'>
                                                                <Image src={item.image} className="rounded-full bg-gray-200 dark:bg-amber-700 dark:bg-opacity-25 h-7 w-7" />
                                                                <div className='flex flex-col gap-0.5'>
                                                                    <span className="text-sm bold text-black dark:text-white uppercase">{item.symbol}</span>
                                                                    <span className="text-sm text-gray-600 dark:text-gray-400">{item.name}</span>
                                                                </div>
                                                            </div>
                                                            <div className='flex items-center space-x-3'>
                                                                <span className='capitalize text-sm text-gray-600 dark:text-gray-400'>{item.type}</span>
                                                                <span>{item.balance}</span>
                                                            </div>
                                                        </div>
                                                    </li>)}

                                            </ol>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
