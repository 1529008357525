function EmptySvg() {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width={236}
        height={298}
        viewBox="0 0 236 298"
        fill="none"
        className="mx-auto w-36"
    >
        <path
            opacity="0.5"
            d="M203.08 293C203.08 295.42 186.95 297.38 167.08 297.38C147.21 297.38 131.08 295.38 131.08 293C131.08 290.62 147.2 288.63 167.08 288.63C186.96 288.63 203.08 290.62 203.08 293Z"
            fill="#E8EBF2"
        />
        <path
            d="M68 3.79C68 3.79 62.41 -0.600002 56 1.59C49.59 3.78 44.73 14.46 43 23.14C41.27 31.82 34.62 38.4 36.22 48.58C37.82 58.76 49.39 60.35 63.36 61.85C77.33 63.35 84.91 61.65 91.49 52.47C98.07 43.29 95.28 32.12 91.39 22.24C87.5 12.36 81.02 3.19 75.63 1.49C70.24 -0.210001 68 3.79 68 3.79Z"
            fill="#484442"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M44.9 56C45.8433 56.4242 46.8243 56.759 47.83 57"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M66.6 10.9C64.3216 15.9904 61.4338 20.7854 58 25.18C52.28 32.53 39.63 39.47 38.82 47.18C38.6899 48.2122 38.7803 49.2604 39.0854 50.2551C39.3906 51.2497 39.9034 52.1683 40.59 52.95"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M89.81 47.77C89.3966 48.7379 88.866 49.6514 88.23 50.49"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M85 28.45C85 28.45 91.43 35.87 90.81 43.79"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.38 6.80999C62.8739 6.55491 60.3431 6.90389 57.9996 7.82774C55.6561 8.75158 53.5679 10.2234 51.91 12.12C51.68 12.38 51.47 12.64 51.27 12.9"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.49 195.3L27.24 214.54C27.24 214.54 21.92 240.35 20.24 250.99C18.56 261.63 15.48 277.81 14.92 277.81C14.36 277.81 5.18002 283.36 2.24002 287.89C-0.699985 292.42 3.03001 294.34 7.11001 292.89C11.19 291.44 16.73 287.34 18.54 282.47C20.35 277.6 21.25 275.79 21.37 275C21.49 274.21 40.84 222.26 42.76 216.71C44.68 211.16 48.76 194.86 48.76 194.86L58.27 162.6C58.27 162.6 68.45 195.77 68.57 197.24C68.69 198.71 79.09 283.49 79.57 286.66C80.05 289.83 79.91 292.09 81.83 292.77C83.75 293.45 88.28 293.45 90.66 293.45C93.04 293.45 101.3 294.7 105.37 293.68C109.44 292.66 109.9 291.98 107.07 289.94C104.24 287.9 88.52 286.6 87.07 280.66C86.95 280.21 86.84 192.94 86.84 192.94C86.84 192.94 85.48 164.08 83.22 155.94H30.92L28.49 195.3Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.10001 292.87C11.17 291.39 16.72 287.32 18.53 282.45L18.91 281.45C16.6 283.91 12.91 287.33 12.91 287.33C12.6839 286.17 12.1794 285.082 11.44 284.16C11.1174 283.804 10.7041 283.543 10.2444 283.404C9.7847 283.264 9.2958 283.253 8.83001 283.37L12.83 278.97C9.83001 280.83 4.43 284.63 2.31 287.9C-0.719998 292.41 3.00001 294.34 7.10001 292.87Z"
            fill="#484442"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M107 289.92C105.06 288.53 97.09 287.48 91.77 285.05C91.5505 285.237 91.379 285.473 91.27 285.74C90.9323 286.698 90.6682 287.681 90.48 288.68L79.48 286.33V286.64C79.93 289.81 79.82 292.07 81.74 292.75C83.66 293.43 88.19 293.43 90.57 293.43C92.95 293.43 101.21 294.68 105.28 293.66C109.35 292.64 109.87 292 107 289.92Z"
            fill="#484442"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.73 109C43.73 109 38.54 111.39 32.26 122.67C25.39 134.98 26.57 141.82 26.47 156.67C26.37 171.52 26.07 196.17 26.07 196.17C26.07 196.17 45.07 201.26 58.7 200.46C72.33 199.66 89.62 196.57 89.62 196.57C89.62 196.57 85.93 146.29 82.44 131.83C78.95 117.37 76.85 110.83 76.85 110.83C70.1048 111.766 63.2699 111.867 56.5 111.13C44.93 110 43.73 109 43.73 109Z"
            fill="#484442"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.59 153.47C66.11 155.18 66.59 156.91 67.01 158.64"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M53.54 125.17C57.5684 132.603 61.0251 140.332 63.8801 148.29"
            stroke="#E8EBF2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M171.12 99.44C170.52 99.04 160.69 97.5 160.69 97.5L158.77 96.71C158.77 96.71 170.67 97.66 170.77 97.16C170.87 96.66 171.27 96.06 170.47 95.96C169.67 95.86 154.36 92.75 154.36 92.75C154.36 92.75 158.25 89.07 158.75 88.27C159.25 87.47 159.94 85.87 159.14 85.87C158.34 85.87 158.14 86.37 157.05 87.07C155.96 87.77 151.66 89.47 150.46 90.46C149.26 91.45 145.68 94.05 145.68 94.05L104.1 88.33C104.1 88.33 89.5 70.33 86.33 66.49C84.1264 63.9274 81.6178 61.6438 78.86 59.69C75.8 58.34 75.86 60.6 75.86 60.6L82.25 89C82.25 89 98.78 98.85 99.8 98.85C100.82 98.85 145.48 100.33 145.48 100.33C145.48 100.33 148.48 101.73 150.36 102.92C152.24 104.11 164.23 107.92 164.23 107.92C164.23 107.92 165.03 107.52 164.13 106.82C163.23 106.12 157.45 103.33 157.45 103.33L169.45 105.93C169.45 105.93 170.15 105.43 169.85 104.93C169.55 104.43 158.85 100.34 158.85 100.34L170.85 100.94C170.85 100.94 171.71 99.84 171.12 99.44Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M101.59 85.24C97.28 79.93 88.67 69.33 86.33 66.49C84.1264 63.9274 81.6178 61.6438 78.86 59.69C75.8 58.34 75.86 60.6 75.86 60.6L82.25 89C82.25 89 89.71 93.44 94.91 96.36L101.59 85.24Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M57.4 57.26C57.4 57.26 48.72 57.66 43.4 63.55C38.08 69.44 38.71 82.4 40.91 90.18C43.11 97.96 45.91 102.25 45.3 104.95C44.69 107.65 42.51 108.54 43.7 111.03C44.89 113.52 51.78 116.22 63.95 115.82C76.12 115.42 78.42 112.82 78.52 110.54C78.62 108.26 77.72 106.35 78.02 104.95C78.32 103.55 87.1 95.77 87.1 87.19C87.1 78.61 83.31 75.62 82.41 69.43C81.51 63.24 81.81 62.35 78.12 59.65C74.43 56.95 62.29 55.56 57.4 57.26Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M53.46 87C50.559 89.4276 47.339 91.4464 43.89 93"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M54.42 88C54.42 88 52.27 94.94 47 98.77"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M75.96 63.54L76.96 73.12L72.76 68.23L75.96 63.54Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M70.37 73.92L75.96 63.54L70.87 58.06L70.37 73.92Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M59.6 40.3C60.4717 43.7587 60.9051 47.3132 60.89 50.88C60.8609 53.1895 60.6266 55.4919 60.19 57.76L70.37 73.92L73.26 58.92L75.16 48.54C75.16 48.54 63.59 47.88 59.6 40.3Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M69.77 65.24L70.37 73.92L67.38 69.23L69.77 65.24Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.8 57.36L66.08 74.02L69.7701 65.24L59.9 51.67L56.8 57.36Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M219.38 65.13H135.17V134.23H219.38V65.13Z" fill="#ffa800" />
        <path
            opacity="0.2"
            d="M219.38 65.13H135.17V134.23H219.38V65.13Z"
            fill="black"
        />
        <path
            d="M219.38 65.13H135.17V134.23H219.38V65.13Z"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M201.55 65.13H117.34V134.23H201.55V65.13Z"
            fill="#ffa800"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M233.25 157.49C230.55 153.21 219.38 134.23 219.38 134.23H201.56L210.73 157.49H233.25Z"
            fill="#ffa800"
        />
        <path
            opacity="0.2"
            d="M233.25 157.49C230.55 153.21 219.38 134.23 219.38 134.23H201.56L210.73 157.49H233.25Z"
            fill="black"
        />
        <path
            d="M233.25 157.49C230.55 153.21 219.38 134.23 219.38 134.23H201.56L210.73 157.49H233.25Z"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M103.42 157.49C106.12 153.21 117.28 134.23 117.28 134.23H135.11L125.94 157.49H103.42Z"
            fill="#ffa800"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M117.28 134.23L109.53 157.49H197.48L201.56 134.23H117.28Z"
            fill="#ffa800"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M230.22 90.13V113.27"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M234.6 100.14V118.9"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M108.29 113.27V127.65"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M104.54 121.4V132.65"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M42.93 82.1C45.9142 85.48 49.3777 88.4044 53.21 90.78C59.21 94.37 81.21 106.84 82.94 107.54C84.67 108.24 137.21 99.66 137.21 99.66C137.21 99.66 140.21 101.06 142.1 102.25C143.99 103.44 155.97 107.25 155.97 107.25C155.97 107.25 156.77 106.85 155.87 106.16C154.97 105.47 149.19 102.66 149.19 102.66L161.19 105.26C161.19 105.26 161.89 104.76 161.59 104.26C161.29 103.76 150.59 99.67 150.59 99.67L162.59 100.27C162.59 100.27 163.49 99.17 162.89 98.77C162.29 98.37 150 95.57 150 95.57C150 95.57 163.07 94.47 163.17 93.97C163.27 93.47 163.67 92.88 162.87 92.78C162.07 92.68 145.71 91.08 145.71 91.08C145.71 91.08 150 88.39 150.5 87.59C151 86.79 151.7 85.19 150.9 85.19C150.1 85.19 149.9 85.69 148.81 86.39C147.72 87.09 143.42 88.79 142.22 89.78C141.02 90.77 137.43 93.38 137.43 93.38L89.35 93.48L59.5 69.93"
            fill="#E8EBF2"
        />
        <path
            d="M42.93 82.1C45.9142 85.48 49.3777 88.4044 53.21 90.78C59.21 94.37 81.21 106.84 82.94 107.54C84.67 108.24 137.21 99.66 137.21 99.66C137.21 99.66 140.21 101.06 142.1 102.25C143.99 103.44 155.97 107.25 155.97 107.25C155.97 107.25 156.77 106.85 155.87 106.16C154.97 105.47 149.19 102.66 149.19 102.66L161.19 105.26C161.19 105.26 161.89 104.76 161.59 104.26C161.29 103.76 150.59 99.67 150.59 99.67L162.59 100.27C162.59 100.27 163.49 99.17 162.89 98.77C162.29 98.37 150 95.57 150 95.57C150 95.57 163.07 94.47 163.17 93.97C163.27 93.47 163.67 92.88 162.87 92.78C162.07 92.68 145.71 91.08 145.71 91.08C145.71 91.08 150 88.39 150.5 87.59C151 86.79 151.7 85.19 150.9 85.19C150.1 85.19 149.9 85.69 148.81 86.39C147.72 87.09 143.42 88.79 142.22 89.78C141.02 90.77 137.43 93.38 137.43 93.38L89.35 93.48L59.5 69.93"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M162.85 92.78C162.06 92.68 145.69 91.08 145.69 91.08C145.69 91.08 149.98 88.39 150.48 87.59C150.98 86.79 151.68 85.19 150.88 85.19C150.08 85.19 149.88 85.69 148.79 86.39C147.7 87.09 143.4 88.79 142.2 89.78C141 90.77 137.41 93.38 137.41 93.38L89.33 93.48L84 89.25C82 92.76 78.26 99.34 75.83 103.68C79.57 105.76 82.4 107.31 82.97 107.54C84.67 108.24 137.24 99.66 137.24 99.66C137.24 99.66 140.24 101.06 142.13 102.25C144.02 103.44 156 107.25 156 107.25C156 107.25 156.8 106.85 155.9 106.16C155 105.47 149.22 102.66 149.22 102.66L161.22 105.26C161.22 105.26 161.92 104.76 161.62 104.26C161.32 103.76 150.62 99.67 150.62 99.67L162.62 100.27C162.62 100.27 163.52 99.17 162.92 98.77C162.32 98.37 150 95.57 150 95.57C150 95.57 163.07 94.47 163.17 93.97C163.27 93.47 163.65 92.88 162.85 92.78Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M82.34 31.92C82.5104 31.7131 82.7356 31.5582 82.9897 31.4731C83.2439 31.388 83.5169 31.3761 83.7775 31.4386C84.0382 31.5012 84.276 31.6357 84.4639 31.8269C84.6517 32.0181 84.7821 32.2583 84.84 32.52C85.007 33.6048 84.9513 34.7122 84.6762 35.7747C84.4011 36.8372 83.9125 37.8326 83.24 38.7C82.14 39.8 81.14 40.1 81.14 38.7C81.4177 36.4201 81.8183 34.1568 82.34 31.92V31.92Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M70.06 14.44C69.7094 19.3066 67.6269 23.8867 64.19 27.35C60.9881 30.4986 57.3673 33.1906 53.43 35.35C53.43 35.35 54.01 38.48 55.19 39.46C56.37 40.44 58.71 40.25 58.71 40.25C60.0704 43.9695 62.2828 47.3189 65.17 50.03C69.86 54.33 75.34 53.94 78.47 47.49C81.6 41.04 83.17 34.57 82.78 30.85L82.39 27.13C82.39 27.13 72.21 21.48 70.06 14.44Z"
            fill="#E8EBF2"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M69.47 33.78C69.9671 33.78 70.37 33.0636 70.37 32.18C70.37 31.2963 69.9671 30.58 69.47 30.58C68.9729 30.58 68.57 31.2963 68.57 32.18C68.57 33.0636 68.9729 33.78 69.47 33.78Z"
            fill="#484442"
        />
        <path
            d="M74.36 46.55C74.8571 46.55 75.26 45.8337 75.26 44.95C75.26 44.0663 74.8571 43.35 74.36 43.35C73.863 43.35 73.46 44.0663 73.46 44.95C73.46 45.8337 73.863 46.55 74.36 46.55Z"
            fill="#484442"
        />
        <path
            d="M78.15 33.78C78.6471 33.78 79.05 33.0636 79.05 32.18C79.05 31.2963 78.6471 30.58 78.15 30.58C77.6529 30.58 77.25 31.2963 77.25 32.18C77.25 33.0636 77.6529 33.78 78.15 33.78Z"
            fill="#484442"
        />
        <path
            d="M76.35 30.48C76.0064 31.6779 75.9044 32.9323 76.05 34.17C76.35 35.87 77.75 38.76 76.55 39.26C76.0024 39.5098 75.3968 39.6046 74.7991 39.534C74.2014 39.4635 73.6344 39.2304 73.16 38.86"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M66.87 29.29C66.87 29.29 67.35 26.23 71.36 26.58"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M77.61 27.52C77.61 27.52 81.35 24.52 82.56 30.01"
            stroke="#384766"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
    );
}

export default EmptySvg;