import React, { Suspense, lazy } from "react";
import "./App.scss";
import "animate.css";
import InitialState from "utils/initial-state/initial-state";
import Ellipse from "components/ellipse/ellipse";
import { Route, Routes } from "react-router-dom";
import Preloader from "components/preloader/preloader.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AppRoutes from "routes/app";
import { useSelector } from "react-redux";
import { selectInfo } from "store/info/info.selector";
import Error503 from "errors/503";
import LockSite from "errors/locked";
import CommonSvgs from "components/svg-components/site.component";
import ComSvg from "components/svg-components/com.component";
import NotFound404 from "errors/404";
import InstallPage from "routes/install";
import { links } from "utils/helpers/links";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const About = lazy(() => import("routes/about"));
const FAQs = lazy(() => import("routes/faq"));
const Terms = lazy(() => import("routes/terms-and-privacy/terms"));
const Privacy = lazy(() => import("routes/terms-and-privacy/privacy"));

const Blog = lazy(() => import("routes/blog"));
const BlogDetails = lazy(() => import("routes/blog/blog-detail"));
const Navigation = lazy(() =>
  import("./routes/navigation/navigation.component")
);
const AuthRoutes = lazy(() => import("./routes/authentication"));
const Home = lazy(() => import("./routes/home"));

function App() {
  const siteInfo = useSelector(selectInfo);

  if (siteInfo == null) return <Preloader />;
  if (siteInfo.maintenance == null || siteInfo.locked == null)
    return <Preloader />;
  if (siteInfo.locked) return <LockSite />;
  if (siteInfo.maintenance) return <Error503 />;
  return (
    <Suspense fallback={<Preloader />}>
      <InitialState />
      <CommonSvgs />
      <ComSvg />
      <Ellipse />
      <Routes>
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route path="/app/*" element={<AppRoutes />} />
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path={links.faq} element={<FAQs />} />
          <Route path={"about"} element={<About />} />
          <Route path={links.terms} element={<Terms />} />
          <Route path={links.privacy} element={<Privacy />} />
          <Route path="blog">
            <Route index element={<Blog />} />
            <Route path=":page/:slug" element={<BlogDetails />} />
          </Route>
        </Route>
        <Route path="/initiate/pexo-app/download" element={<InstallPage />} />
        <Route path="/*" element={<NotFound404 />} />
      </Routes>
      <ToastContainer />
    </Suspense>
  );
}

export default App;
