import { alertMessage } from "utils/initial-state/initial-state";
import { hostURL } from "utils/initial-state/states";

export const dateToTimeAgo = (param) => {
  const date = new Date(param);
  const now = new Date(Date.now());
  const difftime = now.getTime() - date.getTime();
  const diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
  const [sec, min, hr, day, month] = [
    diffDate.getSeconds(),
    diffDate.getMinutes(),
    diffDate.getHours(),
    diffDate.getDate() - 1,
    diffDate.getMonth(),
  ];
  const f = (property, end) => {
    // console.log(property,end)
    return `${property} ${end}${property > 1 ? "s" : ""} ago`;
  };
  // console.log(diffDate.toLocaleString());
  return month >= 1
    ? f(month, "month")
    : day >= 1
    ? f(day, "day")
    : hr >= 1
    ? f(hr, "hr")
    : min >= 1
    ? f(min, "min")
    : day >= 1
    ? f(sec, "sec")
    : "";
};
// eslint-disable-next-line no-extend-native
Date.prototype.toShortFormat = function () {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

// eslint-disable-next-line no-extend-native
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};
export const getTime = (params) => {
    const anyDate = new Date(`${params}`);
    return anyDate.toShortFormat();
  },
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  },
  getPercentColor = (value) =>
    value < 0
      ? "text-red-600"
      : // : value < 1
        // ? "text-amber-600"
        "text-green-500",
  getPercent = (value) => {
    if (value < 0) {
      return <span style={{ color: "#f6465d" }}>{value}%</span>;
    }
    // else if (value < 1) {
    //   return <span style={{ color: "#f4862f" }}>{value}%</span>;
    // }
    else {
      return <span className="text-green-500">{value}%</span>;
    }
  },
  getPercentColorValue = (value) => {
    if (value < 0) {
      return "#f6465d";
    } else if (value < 1) {
      return "#f4862f";
    } else {
      return "green";
    }
  },
  getColorValue = (value) =>
    value < 0 ? "#f6465d" : value < 1 ? "#f4862f" : "#2fbd85";
export const _sizes = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
  },
  sizes = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
  };
export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  },
  apiHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  shareLink = (copyText, title) => {
    const uri = copyText;
    //
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: uri,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(uri).then(() => {
        alertMessage("success", "Link Copied");
      });
    }
  },
  copyToClipboard = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      alertMessage("success", "Copied to Clipboard");
    });

    return true;
  },
  getFinalPrice = (price, percent, _check = false) => {
    const count = parseFloat(price).countDecimals(),
      roi = parseFloat(price) * (parseFloat(percent) / 100),
      result = parseFloat(price + roi).toFixed(count);
    if (count === 0) {
      return parseFloat(result).toFixed(2);
    } else {
      return result;
    }
  },
  truncateNumber = (price, to) => {
    const count = parseFloat(to).countDecimals();
    return parseFloat(price).toFixed(count);
  },
  getCardBg = (symbol) => {
    switch (symbol) {
      case "btc":
        return "from-amber-400 to-orange-600";
      case "eth":
        return "from-purple-500 to-indigo-600";
      case "usdt":
        return "from-blue-500 to-blue-600";
      case "bnb":
        return "from-amber-200 to-orange-400";
      case "usdc":
        return "from-cyan-400 to-sky-600";
      case "ada":
        return "from-indigo-400 to-blue-600";

      default:
        return "from-pexo-gray-400 to-pexo-gray-600";
    }
  },
  truncateStr = (str = "", MAX = 20) => {
    const truncatedStr = str.substring(0, MAX);
    return str.length > MAX ? truncatedStr + "..." : truncatedStr;
  };

export const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  tradeOpts = {
    limit: "limit",
    market: "market",
  },
  tradeFunction = async (data, user) => {
    try {
      const result = await fetch(`${hostURL}/api/transaction/trade`, {
          method: "POST",
          headers: {
            ...apiHeaders,
            Authorization: `Bearer ${user.api_token}`,
            "X-Custom-Header": "header value",
          },
          body: JSON.stringify(data),
        }),
        res = await result.json();

      console.log(res);
      if (res.success) {
        alertMessage("success", res.message);
        return true;
      }
      alertMessage("warn", res.message);
      return false;
    } catch (error) {
      console.log(error);
      alertMessage("error", error.message);
      return false;
    }
  };
