import { createAction } from "../../utils/reducer/reducer.utils";
import { MARKET_ACTION_TYPES } from "./market.types";

export const fetchMarketValues = (data) => createAction(MARKET_ACTION_TYPES.FETCH_MARKET_VALUES,data);
// ------------------------------------------------------------------------------
export const fetchMarketValuesByBTC = (data) => createAction(MARKET_ACTION_TYPES.FETCH_MARKET_VALUES_BTC,data);
// ------------------------------------------------------------------------------
export const fetchTopMarketValues = (data) => createAction(MARKET_ACTION_TYPES.FETCH_TOP_MARKET_VALUES,data);
// ------------------------------------------------------------------------------
export const setMarketValues = () => createAction(MARKET_ACTION_TYPES.SET_MARKET_VALUES);
