/* eslint-disable no-unused-vars */
import BalanceCalc, { walletTypes } from "components/toolkits/balance.component";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "store/theme/theme.selector";
import { updateUserSettings } from "store/user/user.action";
import { selectSettings } from "store/user/user.selector";
import { colors } from "utils/helpers/colors";
import { classNames } from "utils/helpers/helpers";
function WalletBalance({ type = walletTypes.all, currency = 'btc', isDefault = true }) {
    const theme = useSelector(selectTheme), color = colors(theme)
    const settings = useSelector(selectSettings),
        dispatch = useDispatch(),
        viewBalance = settings.view,
        toggleBalance = () => dispatch(updateUserSettings('view', !viewBalance))
    return (
        // <div>
        //     {viewBalance ? <span className="text-pexo-gray-800 dark:text-white text-[24px] font-semibold">0.0000303 BTC</span>
        //         :
        //         <span className="text-pexo-gray-800 dark:text-white text-[24px] font-semibold">****************</span>}

        // </div>
        <div className="sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6 ">
            <div className={classNames("shadow rounded-md w-full relative h-full",
                isDefault ? 'bg-white dark:bg-pexo-dark-950 ' : "rounded-[10px]")}
                style={isDefault ? {} : { background: color.gradiantBG }}
            >
                <div className="flex-auto p-4">
                    <div className="grid md:grid-cols-1 gap-4 ">
                        <div className="ml-2 flex flex-col">
                            <div className="flex items-center space-x-2">
                                <p
                                    tabIndex={0}
                                    className="focus:outline-none text-gray-500 dark:text-gray-400 text-xs font-medium"
                                >
                                    Total Balance
                                </p>
                                <span className="p-1 rounded" role="button" onClick={toggleBalance}>
                                    {
                                        viewBalance ?
                                            <BsEye className="text-gray-500 dark:text-gray-400 font-bold" />
                                            :
                                            <BsEyeSlash className="text-gray-500 dark:text-gray-400 font-bold" />
                                    }
                                </span>
                            </div>
                            <span
                                tabIndex={0}
                                className="cursor-pointer hover:text-gray-500 focus:text-gray-500 text-gray-800 dark:text-gray-100 focus:outline-none focus:underline mt-3"
                            >
                                <h5 className="text-[24px] text-xl font-medium text-base">
                                    {viewBalance ? <><BalanceCalc type={type} vs={currency} /> {currency.toLocaleUpperCase()}</> : '******************'}
                                </h5>
                            </span>
                            <span
                                tabIndex={0}
                                className={isDefault ? "text-gray-500 dark:text-gray-400" : 'text-amber-500 dark:text-amber-500/50'}
                            >
                                {viewBalance ? <>≈ <BalanceCalc type={type} vs={'usd'} /></> : '********'}
                            </span>
                        </div>
                    </div>
                </div>
                {/*end card-body*/}
            </div>{" "}
            {/*end card*/}
        </div>
    );
}

export default WalletBalance;