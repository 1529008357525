import Pagination from "components/pagination/pagination.component";
import { useSelector } from "react-redux";
import { selectSettings } from "store/user/user.selector";
import { getFinalPrice } from "utils/helpers/helpers";
import { getNumberFormat } from "utils/initial-state/initial-state";
import { ITEMS_PER_PAGE } from "./balance-table.component";
import { Menu, Transition } from '@headlessui/react'
import { BiDotsHorizontal } from "react-icons/bi";
import { Fragment } from "react";
import { links } from "utils/helpers/links";
import { walletTypes } from "components/toolkits/balance.component";


const BalanceTableSM = ({ page, type, currentItems, stableCoinsMap, siteInfo, goToLink, marketList, paginate, nextPage, prevPage }) => {
    const settings = useSelector(selectSettings),
        viewBalance = settings.view
    const CoinMenu = ({ item }) => {
        return (
            <Menu>
                <Menu.Button><BiDotsHorizontal className="text-gray-700 dark:text-gray-200" /></Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-pexo-dark-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => goToLink(item)}
                                        className={`${active ? 'bg-pexo-gray-500 dark:bg-pexo-gray-950 text-white' : 'text-gray-900 dark:text-gray-200'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >

                                        Deposit
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => item.user_id == null ? {} : goToLink(item, links.default + links.convert)}
                                        className={`${active ? 'bg-pexo-gray-500 dark:bg-pexo-gray-950 text-white' : 'text-gray-900 dark:text-gray-200'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >

                                        Convert
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => goToLink(item, links.default + links.trade.spot + `/${item.symbol}/${item.coin_id}`)}
                                        className={`${active ? 'bg-pexo-gray-500 dark:bg-pexo-gray-950 text-white' : 'text-gray-900 dark:text-gray-200'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >

                                        Trade
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => item.user_id == null ? {} : goToLink(item, links.default + links.wallet.default + links.wallet.withdraw)}
                                        className={`${active ? 'bg-pexo-gray-500 dark:bg-pexo-gray-950 text-white' : 'text-gray-900 dark:text-gray-200'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >

                                        Withdraw
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }
    return (<div className="flex flex-col space-y-3 mt-2">
        {currentItems == null ? (new Array(12).fill(0).map((_, i) => <div key={i} className="flex justify-between items-center animate__infinite animate__slower animate__animated animate__flash">
            <div className="flex items-center">
                <span className="block h-8 w-8 rounded-full bg-gray-500/25 dark:bg-amber-500/25"></span>
                <div className="flex flex-col items-center">
                    <div className="w-9 text-center text-white text-sm font-normal h-5 rounded-full bg-gray-500/25 dark:bg-amber-500/25"></div>
                    <div className="text-center text-amber-500 text-xs font-normal mt-1 w-12 h-3 rounded-full bg-gray-500/25 dark:bg-amber-500/25"></div>
                </div>
            </div>
            <div className="">
                <div className=" text-center text-white text-sm font-normal h-5 rounded-full bg-gray-500/25 dark:bg-amber-500/25 w-9"></div>
                <div className="h-5 rounded-full text-center text-amber-500 text-xs font-normal bg-gray-500/25 mt-1 dark:bg-amber-500/25 w-9"></div>
            </div>
        </div>)) : currentItems?.map((item, i) => <div key={i} className="flex justify-between items-center"
        >
            <div className="flex items-center gap-2" role="button" onClick={() => type === walletTypes.spot && goToLink(item, links.default + '/wallet-detail/' + type + '/' + item.coin_id)}>
                <img src={item.image} alt={item.id} className="h-8 w-8 rounded-full" />
                <div className="flex flex-col justify-center ">
                    <span className="text-sm bold text-black dark:text-white uppercase font-normal">{item.symbol}</span>
                    <div className=" text-center text-amber-500 text-xs font-normal">{item.name}</div>
                </div>
            </div>
            <div className="flex gap-2">
                <div role="button" onClick={() => type === walletTypes.spot && goToLink(item, links.default + '/wallet-detail/' + type + '/' + item.coin_id)}>
                    <div className="text-end text-pexo-gray-950 dark:text-white text-sm font-normal">{viewBalance ? item.balance === '0.00' ? "0.00" : parseFloat(item.balance).toString() : '*******'}</div>
                    <div className="text-end text-amber-500 text-xs font-normal">≈ {viewBalance ? stableCoinsMap.includes(item.id) ? getNumberFormat((parseFloat(item.balance) * parseFloat(item.current_price)).toFixed(2)) : getNumberFormat(parseFloat(parseFloat(item.balance) * getFinalPrice(item.current_price, siteInfo.charge)).toFixed(2)) : '*******'}</div>
                </div>
                {type === walletTypes.spot && <CoinMenu item={item} />}
            </div>
        </div>)}

        {marketList?.length > ITEMS_PER_PAGE && <Pagination currentPage={page} itemsPerPage={ITEMS_PER_PAGE} totalItems={marketList == null ? 0 : marketList.length}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage} />}
    </div>);
}

export default BalanceTableSM;