import { createSelector } from "reselect";

const selectUserReducer = (state) => state.user;

export const selectUser = createSelector(
  [selectUserReducer],
  (user) => user.currentUser
);
export const selectWallets = createSelector([selectUserReducer], (user) =>
  user.wallets == null ? [] : user.wallets
);
export const selectSettings = createSelector(
  [selectUserReducer],
  (user) => user.settings
);
export const selectUserDetails = createSelector(
  [selectUserReducer],
  (user) => user.userDetails
);
export const selectIsLoggedIn = createSelector(
  [selectUserReducer],
  (user) => user.isLoggedIn
);

export const selectIsLoading = createSelector(
  [selectUserReducer],
  (user) => user.isLoading
);

export const selectTwoStepVerify = createSelector(
  [selectUserReducer],
  (user) => user.twoStepVerify
);

export const selectUnseen = createSelector([selectUserReducer], (user) => {
  return { unseen: user.unseen, unseenCount: user.unseenCount };
});
