import { combineReducers } from "@reduxjs/toolkit";
import infoSlice from "./info/info.slice";
import themeSlice from "./theme/theme-slice";
import userSlice from "./user/user.slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import marketSlice from "./market/market.slice";

const userPersistConfig = {
    key: "user",
    storage,
    blacklist: ["isLoading"],
  },
  userReducer = userSlice.reducer;

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  theme: themeSlice.reducer,
  info: infoSlice.reducer,
  market: marketSlice.reducer,
});
