import { createSelector } from "reselect";

export const selectInfoReducer = (state) => state.info;

export const selectInfo = createSelector(
  [selectInfoReducer],
  (info) => info.siteInfo
);

export const selectPlans = createSelector(
  [selectInfoReducer],
  (info) => info.plans
);
export const selectAccounts = createSelector(
  [selectInfoReducer],
  (info) => info.accounts
);

export const selectIsToneOn = createSelector(
  [selectInfoReducer],
  (info) => info.notifyTone
);

export const selectIsLocked = createSelector(
  [selectInfoReducer],
  (info) => info.locked
);
