import { Link } from "react-router-dom";
import { depositLink, links } from "utils/helpers/links";
import btcIcon from 'assets/img/btc.png'
import WalletBalance from "./balance.component";
import { SiConvertio } from "react-icons/si";
import { classNames } from "utils/helpers/helpers";
import { AlertMsg } from "routes/app/wallet";
import BalanceCalc, { walletTypes } from "components/toolkits/balance.component";
import BalanceTable from "./balance-table.component";
import GoBack from "components/app-elements/goback.component";

const spotLinks = [
    {
        title: 'Deposit',
        href: depositLink,
        icon: () => <svg
            width={35}
            height={35}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="17.5" cy="17.5" r="17.5" fill="#FFA800" fillOpacity="0.1" />
            <path
                d="M11.4286 12.1243H23.5715C23.7133 12.1242 23.855 12.1332 23.9957 12.1512C23.948 11.8165 23.833 11.4948 23.6577 11.2057C23.4823 10.9166 23.2502 10.6659 22.9754 10.4689C22.7005 10.2719 22.3887 10.1326 22.0585 10.0594C21.7284 9.98619 21.3869 9.98059 21.0545 10.0429L11.0681 11.7479H11.0568C10.4299 11.8677 9.87247 12.2224 9.49829 12.7394C10.062 12.3384 10.7368 12.1234 11.4286 12.1243Z"
                fill="#FFA800"
            />
            <path
                d="M23.5714 13.035H11.4286C10.7847 13.0357 10.1674 13.2918 9.71209 13.7471C9.2568 14.2024 9.0007 14.8197 9 15.4636V22.7493C9.0007 23.3932 9.2568 24.0105 9.71209 24.4658C10.1674 24.9211 10.7847 25.1772 11.4286 25.1779H23.5714C24.2153 25.1772 24.8326 24.9211 25.2879 24.4658C25.7432 24.0105 25.9993 23.3932 26 22.7493V15.4636C25.9993 14.8197 25.7432 14.2024 25.2879 13.7471C24.8326 13.2918 24.2153 13.0357 23.5714 13.035ZM21.769 20.3207C21.5288 20.3207 21.294 20.2495 21.0944 20.1161C20.8947 19.9827 20.739 19.793 20.6471 19.5711C20.5552 19.3493 20.5312 19.1051 20.578 18.8696C20.6249 18.634 20.7405 18.4177 20.9103 18.2478C21.0802 18.078 21.2965 17.9624 21.5321 17.9155C21.7676 17.8687 22.0118 17.8927 22.2337 17.9846C22.4555 18.0765 22.6452 18.2322 22.7786 18.4318C22.912 18.6315 22.9833 18.8663 22.9833 19.1065C22.9833 19.4285 22.8553 19.7374 22.6276 19.9651C22.3999 20.1928 22.091 20.3207 21.769 20.3207Z"
                fill="#FFA800"
            />
            <path
                d="M9.01898 18.025V14.2493C9.01898 13.427 9.47434 12.0484 11.0548 11.7498C12.3962 11.4982 13.7243 11.4982 13.7243 11.4982C13.7243 11.4982 14.5971 12.1053 13.8761 12.1053C13.1551 12.1053 13.1741 13.035 13.8761 13.035C14.5781 13.035 13.8761 13.9267 13.8761 13.9267L11.0491 17.1332L9.01898 18.025Z"
                fill="#FFA800"
            />
        </svg>
    },
    {
        title: 'Withdraw',
        href: links.default + links.wallet.default + links.wallet.withdraw,
        icon: () => <svg
            width={35}
            height={35}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={8} y="15.3333" width={19} height="10.8571" rx={1} fill="#FFA800" />
            <path d="M17.5 9L20.2424 13.75H14.7576L17.5 9Z" fill="#FFA800" />
            <path
                d="M17.0476 23.4762C18.5467 23.4762 19.7619 22.261 19.7619 20.7619C19.7619 19.2628 18.5467 18.0476 17.0476 18.0476C15.5485 18.0476 14.3333 19.2628 14.3333 20.7619C14.3333 22.261 15.5485 23.4762 17.0476 23.4762Z"
                fill="black"
            />
            <path
                d="M17.7733 22.497C17.8741 22.3962 17.9529 22.2754 18.0045 22.1425C18.0561 22.0095 18.0795 21.8672 18.0731 21.7248C18.0667 21.5823 18.0307 21.4427 17.9674 21.3149C17.9041 21.1871 17.8149 21.0738 17.7054 20.9824L16.825 20.2499C16.7878 20.219 16.7575 20.1806 16.7361 20.1373C16.7146 20.094 16.7023 20.0467 16.7001 19.9984C16.6979 19.9501 16.7058 19.9019 16.7233 19.8568C16.7407 19.8117 16.7674 19.7708 16.8015 19.7366C16.8679 19.6734 16.9559 19.6381 17.0475 19.6381C17.1391 19.6381 17.2272 19.6734 17.2935 19.7366L17.8255 20.2686L18.3053 19.7888L17.7733 19.2565C17.6641 19.1488 17.532 19.0671 17.3868 19.0176V18.071C17.1617 18.0398 16.9334 18.0398 16.7082 18.071V19.0176C16.5632 19.0667 16.4311 19.1479 16.3218 19.2551C16.2209 19.356 16.1422 19.4767 16.0906 19.6097C16.0389 19.7426 16.0156 19.8849 16.022 20.0274C16.0283 20.1698 16.0643 20.3094 16.1276 20.4372C16.1909 20.5651 16.2802 20.6783 16.3897 20.7697L17.2694 21.5026C17.3032 21.5333 17.3306 21.5704 17.3501 21.6117C17.3696 21.653 17.3807 21.6977 17.3829 21.7433C17.385 21.789 17.3782 21.8346 17.3628 21.8775C17.3473 21.9205 17.3236 21.96 17.2928 21.9938C17.2621 22.0276 17.225 22.055 17.1837 22.0745C17.1424 22.094 17.0977 22.1051 17.052 22.1073C17.0064 22.1095 16.9608 22.1026 16.9178 22.0872C16.8749 22.0717 16.8353 22.048 16.8015 22.0173L16.2695 21.4853L15.7898 21.965L16.3218 22.4973C16.4312 22.6045 16.5632 22.6857 16.7082 22.7348V23.4518C16.9334 23.483 17.1617 23.483 17.3868 23.4518V22.7359C17.532 22.6864 17.6641 22.6047 17.7733 22.497Z"
                fill="#FFA800"
            />
            <circle cx="17.5" cy="17.5" r="17.5" fill="#FFA800" fillOpacity="0.1" />
        </svg>
    },
    {
        title: 'Transfer',
        href: links.default + links.wallet.default + links.wallet.transfer,
        icon: () => <svg
            width={35}
            height={35}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="17.5" cy="17.5" r="17.5" fill="#FFA800" fillOpacity="0.1" />
            <path
                d="M23.6033 15.6525C23.6664 15.5002 23.6829 15.3326 23.6507 15.1709C23.6186 15.0092 23.5391 14.8607 23.4225 14.7442L19.2558 10.5775L18.0775 11.7558L20.8217 14.5H10.3333V16.1667H22.8333C22.9982 16.1667 23.1593 16.1179 23.2963 16.0264C23.4334 15.9349 23.5402 15.8048 23.6033 15.6525ZM10.3967 18.3475C10.3336 18.4998 10.3171 18.6674 10.3493 18.8291C10.3815 18.9908 10.4609 19.1393 10.5775 19.2558L14.7442 23.4225L15.9225 22.2442L13.1783 19.5H23.6667V17.8333H11.1667C11.0019 17.8332 10.8407 17.882 10.7036 17.9735C10.5665 18.065 10.4597 18.1952 10.3967 18.3475Z"
                fill="#FFA800"
            />
        </svg>
    },
    {
        title: 'Convert',
        href: links.default + links.convert,
        icon: () => <span className={classNames("flex justify-center items-center",
            "bg-amber-500/10 p-2 w-[35px] h-[35px] rounded-full"
        )} >
            <SiConvertio className="text-amber-500" />
        </span>
    },
],
    fundingLinks = [

        {
            title: 'Pexo Pay',
            href: links.default + links.wallet.default + links.wallet.pexo,
            icon: () => <span className="w-9 h-9 rounded-full bg-[#FFA800] bg-opacity-10 flex items-center justify-center">
                <svg width="60" height="79" viewBox="0 0 60 79" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-4">
                    <circle cx="17.5" cy="17.5" r="17.5" fill="#FFA800" fillOpacity="0.1" />
                    <path d="M0 30C0 24.0666 1.75947 18.2664 5.05591 13.3329C8.35235 8.39942 13.0377 4.55424 18.5195 2.28361C24.0013 0.012985 30.0333 -0.581115 35.8527 0.576442C41.6721 1.734 47.0176 4.59122 51.2132 8.7868C55.4088 12.9824 58.266 18.3279 59.4236 24.1473C60.5811 29.9667 59.987 35.9987 57.7164 41.4805C55.4458 46.9623 51.6006 51.6476 46.6671 54.9441C41.7336 58.2405 35.9334 60 30 60V44.2861C32.8255 44.2861 35.5876 43.4482 37.9369 41.8784C40.2862 40.3087 42.1173 38.0775 43.1986 35.467C44.2799 32.8566 44.5628 29.9842 44.0116 27.2129C43.4603 24.4417 42.0997 21.8962 40.1018 19.8982C38.1038 17.9003 35.5583 16.5397 32.7871 15.9885C30.0158 15.4372 27.1434 15.7201 24.533 16.8014C21.9225 17.8827 19.6914 19.7138 18.1216 22.0631C16.5518 24.4124 15.7139 27.1745 15.7139 30H0Z" fill="#FFA800"></path><path d="M0 40.2007H15.9152V62.2517C15.9152 71.0414 8.78973 78.1669 0 78.1669V40.2007Z" fill="#FFA800"></path></svg>
            </span>
        },
        {
            title: 'Transfer',
            href: links.default + links.wallet.default + links.wallet.transfer,
            icon: () => <svg
                width={35}
                height={35}
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="17.5" cy="17.5" r="17.5" fill="#FFA800" fillOpacity="0.1" />
                <path
                    d="M23.6033 15.6525C23.6664 15.5002 23.6829 15.3326 23.6507 15.1709C23.6186 15.0092 23.5391 14.8607 23.4225 14.7442L19.2558 10.5775L18.0775 11.7558L20.8217 14.5H10.3333V16.1667H22.8333C22.9982 16.1667 23.1593 16.1179 23.2963 16.0264C23.4334 15.9349 23.5402 15.8048 23.6033 15.6525ZM10.3967 18.3475C10.3336 18.4998 10.3171 18.6674 10.3493 18.8291C10.3815 18.9908 10.4609 19.1393 10.5775 19.2558L14.7442 23.4225L15.9225 22.2442L13.1783 19.5H23.6667V17.8333H11.1667C11.0019 17.8332 10.8407 17.882 10.7036 17.9735C10.5665 18.065 10.4597 18.1952 10.3967 18.3475Z"
                    fill="#FFA800"
                />
            </svg>
        },
    ]

function WalletBody({ user, type = walletTypes.spot, wallets }) {
    const firstLinks = type === walletTypes.spot ? spotLinks : fundingLinks
    return (<div className={classNames("ltr:flex flex-1 rtl:flex-row-reverse", "col-span-12 lg:col-span-10 space-y-6  lg:block relative")}>
        <div className="page-wrapper relative ltr:ml-auto rtl:mr-auto rtl:ml-0  px-4 pt-0  duration-300">
            <div className="xl:w-full">
                <div className="flex flex-wrap">
                    <div className="flex items-center py-4 w-full">
                        <div className="w-full">
                            <div className="">
                                <div className="flex flex-wrap justify-between">

                                    <div className="flex items-center gap-3">
                                        <GoBack isDefault={false} path={links.default + links.wallet.default + links.wallet.overview} />
                                        <h1 className="font-semibold text-xl mb-1 block dark:text-slate-100 capitalize">
                                            {type}
                                        </h1>
                                    </div>
                                    <div className="flex items-center">
                                        <Link to={links.default + links.transactions} className="px-3 py-2 lg:px-4 bg-amber-500 collapse:bg-green-100 text-white text-sm font-semibold rounded hover:bg-amber-600">
                                            History
                                        </Link>
                                    </div>

                                </div>
                                {user.kyc == null ?
                                    <AlertMsg message='Please complete Identity Verification (KYC) to continue using our services.' /> : !user.kyc.verified ? <AlertMsg message='Please wait while we verify your identity...' /> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end container*/}
            <div className="xl:w-full hidden lg:block">
                <div className="flex flex-wrap">
                    <div className="flex items-center pb-4 w-full">
                        <div className="w-full">
                            <div className="flex flex-wrap justify-between items-center">
                                <div className="flex items-center gap-2">
                                    <Link to={links.default + links.wallet.default + links.wallet.withdraw}
                                        className="inline-block focus:outline-none text-red-500 hover:bg-red-500 hover:text-white bg-transparent border border-gray-200 dark:bg-transparent dark:text-red-500 dark:hover:text-white dark:border-gray-700 dark:hover:bg-red-500  text-sm font-medium py-1 px-3 rounded"
                                    >
                                        Send
                                    </Link>
                                    <Link to={depositLink}
                                        className="inline-block focus:outline-none text-green-500 hover:bg-green-500 hover:text-white bg-transparent border border-gray-200 dark:bg-transparent dark:text-green-500 dark:hover:text-white dark:border-gray-700 dark:hover:bg-green-500  text-sm font-medium py-1 px-3 rounded"
                                    >
                                        Request
                                    </Link>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex items-center">
                                        <img
                                            className="w-9 h-9 overflow-hidden object-cover rounded object-center"
                                            src={btcIcon}
                                            alt="logo"
                                        />
                                        <div className="ml-2">
                                            <p
                                                tabIndex={0}
                                                className="focus:outline-none text-gray-500 dark:text-gray-400 text-xs font-medium"
                                            >
                                                Total Balance
                                            </p>
                                            <span
                                                tabIndex={0}
                                                className="cursor-pointer hover:text-gray-500 focus:text-gray-500 text-gray-800 dark:text-gray-100 focus:outline-none focus:underline"
                                            >
                                                <h5 className=" font-medium text-base">
                                                    <BalanceCalc vs="btc" type={type} /> BTC
                                                </h5>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end container*/}
            <div className="xl:w-full  min-h-[calc(100vh-138px)] relative pb-14 ">
                <div className="grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4 mb-4">
                    <WalletBalance type={type} isDefault={true} />
                    {/*end col*/}
                    <div className="sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6 ">
                        <div className=" sm:col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-3">
                            <div className={type === walletTypes.spot ?
                                "grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4" :
                                "flex items-center justify-center gap-4"}>
                                {firstLinks.map((link, i) => <Link to={link.href} key={i} className="grid-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 animate__animated animate__fadeInLeft">
                                    <div className="shadow rounded-md w-full relative p-4 bg-white dark:bg-pexo-dark-950"
                                    // style={{ background: color.gradiantBG }}
                                    >
                                        <div className="flex flex-col justify-center xl:gap-x-2 items-center">
                                            <div className="relative overflow-hidden">
                                                <link.icon />

                                            </div>
                                            <span className="my-1 font-semibold text-xs lg:text-sm text-slate-700 dark:text-slate-200">
                                                {link.title}
                                            </span>
                                        </div>
                                    </div>
                                    {/*end inner-grid*/}
                                </Link>)}

                            </div>
                        </div>
                    </div>
                    {/*end col*/}
                </div>
                {/*end inner-grid*/}
                <div className="mb-4">
                    <h4 className="font-medium flex-1 self-center mb-2 md:mb-0 dark:text-slate-300">My Balances</h4>
                    <div className="grid grid-cols-1 gap-4">
                        <BalanceTable {...{ wallets, type }} />
                    </div>
                </div>
                {/*end grid*/}

            </div>
            {/*end container*/}
        </div>
    </div>
    );
}

export default WalletBody;