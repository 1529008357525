// app colors based on theme
// default theme 'light'
export const colors = (theme = "light") => ({
  body: theme === "dark" ? "#161514" : "#fff",
  inputBg:
    theme === "dark" ? "rgba(255, 168, 0, 0.1)" : "rgba(101, 101, 101, 0.3)",
  cardBg:
    theme === "dark" ? "rgba(255, 168, 0, 0.1)" : "rgba(101, 101, 101, 0.13)",
  cardColor: theme === "dark" ? "#FFFFFF" : "#000000",
  buttonColor: theme === "dark" ? "#000000" : "#FFFFFF",
  productCard: theme === "dark" ? "#262626" : "#FFFFFF",
  grayCard: theme === "dark" ? "#262626" : "#E1E1E1",
  darkerCardBg: theme === "dark" ? "#121212" : "#FFFFFF",
  topBg: theme === "dark" ? "#1F1F1F" : "#002746",
  topSearchBg: theme === "dark" ? "#121212" : "rgba(18, 18, 18, 0.45)",
  googleBg: theme === "dark" ? "rgba(255,255,255,0.17)" : "rgba(0, 0, 0, 0.17)",
  bottomBg: theme === "dark" ? "#121212" : "#FFFFFF",
  bottomColor: theme === "dark" ? "#8A8A8A" : "#4B4B4B",
  cartIconColor: theme === "dark" ? "#383838" : "#8E8D8D",
  accountIconColor: theme === "dark" ? "#CCCCCC" : "#8A8A8A",
  quantityColor: theme === "dark" ? "#D9D9D9" : "#002746",
  borderColor: theme === "dark" ? "#545454" : "#ababab",
  activeBorderColor: theme === "dark" ? "#e2e5ec" : "#102847",
  gradiantBG:
    theme === "dark"
      ? "linear-gradient(180deg, rgba(255, 168, 0, 0.10) 0%, rgba(255, 168, 0, 0.03) 100%)"
      : "linear-gradient(180deg, rgba(101, 101, 101, 0.05) 0%, rgba(101, 101, 101, 0.01) 100%)",
  blue: "#002746",
  yellow: "#FC9F01",
  pendingBg: "#737373",
  pendingColor: "#FFFFFF",
  primaryBg: "#286AA7",
  primaryColor: "#FFFFFF",
  successBg: "#28A745",
  successColor: "#FFFFFF",
  active: "#0038FF",
  white: "#FFFFFF",
});
