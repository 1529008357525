import { createSlice } from "@reduxjs/toolkit";

const marketSlice = createSlice({
  name: "market",
  initialState: {
    all: null,
    gainers: null,
    btc: null,
  },
  reducers: {
    setMarketAll(state, action) {
      state.all = action.payload;
    },
    setMarketAllByBTC(state, action) {
      state.btc = action.payload;
    },
    setTopGainers(state, action) {
      state.gainers = action.payload;
    },
  },
});

export const marketActions = marketSlice.actions;
export default marketSlice;
