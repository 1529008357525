import { BsExclamationCircle, BsCheckCircleFill, BsExclamationDiamondFill, BsInfoCircleFill } from "react-icons/bs";

export const badgeType = {
    default: 'default',
    dark: 'dark',
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
}

function Badge({ type = badgeType.default, label = 'Default', }) {

    switch (type) {
        case badgeType.dark:
            return <span className="bg-gray-100 flex items-center w-fit text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300"><BsInfoCircleFill className="mr-1.5" /> {label}</span>
        case badgeType.error:
            return <span className="flex items-center w-fit bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300"><BsExclamationDiamondFill className="mr-1.5" /> {label}</span>
        case badgeType.warning:
            return <span className="flex items-center w-fit bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300"><BsExclamationCircle className="mr-1.5" /> {label}</span>
        case badgeType.info:
            return <span className="flex items-center w-fit bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300"><BsInfoCircleFill className="mr-1.5" /> {label}</span>
        case badgeType.success:
            return <span className="flex items-center w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300"><BsCheckCircleFill className="mr-1.5" /> {label}</span>
        default:
            return <span className="bg-blue-100 flex items-center w-fit text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"><BsInfoCircleFill className="mr-1.5" /> {label}</span>
    }
}

export default Badge;