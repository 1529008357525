import { classNames } from "utils/helpers/helpers";
import logo from 'assets/img/logo.svg'
import { Link } from "react-router-dom";
import { links } from "utils/helpers/links";

const headerStyles = {
    'box-sizing': 'border-box',
    margin: 0,
    minWidth: 0,
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    userSelect: 'none',
    '-webkit-box-align': 'center',
    height: 64,
    top: 0
}
function MobileHeader({ isFixed = false }) {
    const toggleSettings = () => {
        const className = "offcanvas-open", body = document.body;
        if (body.classList.contains(className)) {
            body.classList.remove(className);
        } else {
            body.classList.add(className);
        }
    }
    return (<header style={headerStyles} className={classNames("flex lg:hidden w-full items-center justify-between", isFixed ? 'fixed backdrop-blur-sm z-40' : '')}>
        <Link to="/" className="flex items-center justify-center">
            <img
                alt="Pexo - Trading and Exchange"
                loading="lazy"
                decoding="async"
                data-nimg={1}
                className="h-6 w-6"
                style={{ color: "transparent" }}
                src={logo}
            /><h1 style={{ color: '#ffa800', textTransform: 'lowercase' }} className="font-bold  text-center text-2xl dark:text-white md:text-left lg:text-4xl xl:text-4xl">
                EXO.
            </h1>
        </Link>

        <div className="space-x-4 flex items-center">
            <Link to={links.default + links.wallet.default + links.wallet.overview} className="px-3 py-2 text-xs font-medium text-center text-white bg-amber-500 rounded-lg hover:bg-amber-700 focus:ring-4 focus:outline-none focus:ring-amber-300 dark:bg-amber-500 dark:hover:bg-amber-600 dark:focus:ring-amber-600">Wallet</Link>
            <span role="button" onClick={toggleSettings}><svg
                viewBox="0 0 24 24"
                width={23}
                height={23}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white dark:text-pexo-gray-950"
            >
                <path
                    fill="#FFA800"
                    d="M2 12.947v-1.771c0-1.047.85-1.913 1.899-1.913 1.81 0 2.549-1.288 1.64-2.868a1.919 1.919 0 0 1 .699-2.607l1.729-.996c.79-.474 1.81-.192 2.279.603l.11.192c.9 1.58 2.379 1.58 3.288 0l.11-.192c.47-.795 1.49-1.077 2.279-.603l1.73.996a1.92 1.92 0 0 1 .699 2.607c-.91 1.58-.17 2.868 1.639 2.868 1.04 0 1.899.856 1.899 1.912v1.772c0 1.047-.85 1.912-1.9 1.912-1.808 0-2.548 1.288-1.638 2.869.52.915.21 2.083-.7 2.606l-1.729.997c-.79.473-1.81.191-2.279-.604l-.11-.191c-.9-1.58-2.379-1.58-3.288 0l-.11.19c-.47.796-1.49 1.078-2.279.605l-1.73-.997a1.919 1.919 0 0 1-.699-2.606c.91-1.58.17-2.869-1.639-2.869A1.911 1.911 0 0 1 2 12.947Z"
                />
                <path
                    fill="currentColor"
                    d="M11.995 15.332c1.794 0 3.248-1.464 3.248-3.27 0-1.807-1.454-3.272-3.248-3.272-1.794 0-3.248 1.465-3.248 3.271 0 1.807 1.454 3.271 3.248 3.271Z"
                />
            </svg></span>
            <Link
                to={links.default + links.notifications}
                className="dropdown-toggle border-pexo-gray-100 hover:bg-amber-500 focus:bg-amber-500 group dark:hover:bg-amber-500 ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                <div
                    className="inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-white"
                >
                    <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 14 20"
                    >
                        <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                    </svg>
                </div>
            </Link>

        </div>
    </header>);
}

export default MobileHeader;