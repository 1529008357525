import { createSlice } from "@reduxjs/toolkit";
const settings = {
  currency: "btc",
  view: true,
};
const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    wallets: null,
    userDetails: null,
    isLoggedIn: false,
    isLoading: true,
    error: null,
    done: false,
    unseen: null,
    unseenCount: 0,
    registered: false,
    twoStepVerify: false,
    settings: settings,
  },

  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setWallets(state, action) {
      state.wallets = action.payload;
    },
    updateSettings(state, action) {
      if (action.payload.name == null || action.payload.value == null)
        return false;
      state.settings = {
        ...state.settings,
        [action.payload.name]: action.payload.value,
      };
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setTwoStepVerify(state, action) {
      state.twoStepVerify = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUnseen(state, action) {
      if (state.unseenCount !== action.payload.unseenCount) {
        state.unseen = action.payload.unseen;
        state.unseenCount = action.payload.unseenCount;
      }
    },
    logout(state, action) {
      state.currentUser = null;
      state.wallets = null;
      state.userDetails = null;
      state.isLoggedIn = false;
      state.error = null;
      state.done = false;
      state.unseen = null;
      state.unseenCount = 0;
      state.registered = false;
      state.twoStepVerify = false;
      state.settings = settings;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.error = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
