/* eslint-disable react/react-in-jsx-scope */
import Ellipse from "components/ellipse/ellipse";
import { useSelector } from "react-redux";
import { selectTheme } from "store/theme/theme.selector";
import { colors } from "utils/helpers/colors";
import InitialState from "utils/initial-state/initial-state";

const Preloader = () => {
    const theme = useSelector(selectTheme)
    const color = colors(theme);
    return (
        <div style={{
            inset: 0,
            position: 'fixed',
            background: color.body,
            zIndex: 9999999,
        }}
            className="preloader">

            <InitialState />
            <Ellipse />
            <div role="status">
                <svg width="60" height="79" viewBox="0 0 60 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 30C0 24.0666 1.75947 18.2664 5.05591 13.3329C8.35235 8.39942 13.0377 4.55424 18.5195 2.28361C24.0013 0.012985 30.0333 -0.581115 35.8527 0.576442C41.6721 1.734 47.0176 4.59122 51.2132 8.7868C55.4088 12.9824 58.266 18.3279 59.4236 24.1473C60.5811 29.9667 59.987 35.9987 57.7164 41.4805C55.4458 46.9623 51.6006 51.6476 46.6671 54.9441C41.7336 58.2405 35.9334 60 30 60L30 44.2861C32.8255 44.2861 35.5876 43.4482 37.9369 41.8784C40.2862 40.3087 42.1173 38.0775 43.1986 35.467C44.2799 32.8566 44.5628 29.9842 44.0116 27.2129C43.4603 24.4417 42.0997 21.8962 40.1018 19.8982C38.1038 17.9003 35.5583 16.5397 32.7871 15.9884C30.0158 15.4372 27.1434 15.7201 24.533 16.8014C21.9225 17.8827 19.6914 19.7138 18.1216 22.0631C16.5518 24.4124 15.7139 27.1745 15.7139 30H0Z" fill="#FFA800" />
                    <path d="M0 40.2007H15.9152V62.2517C15.9152 71.0414 8.78973 78.1669 0 78.1669V78.1669V40.2007Z" fill="#FFA800" />
                </svg>


                <span className="sr-only">Loading...</span>
            </div>

        </div>
    );
}
export const PageLoader = () => {

    return <div style={{
        inset: 0,
        position: 'fixed',
        background: 'rgba(22, 21, 20, 0.5)',
        zIndex: 9999999,
        opacity: 0.5
    }}>
        <div className="runner"></div>
        <Ellipse />


    </div>
}

export default Preloader;