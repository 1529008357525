import InitialState from "utils/initial-state/initial-state";

function LockSite() {
    return (
        <div className="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative">

            <InitialState />
            <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/2">
                    <div className="mb-10 lg:mb-20">
                        <svg width="60" height="79" className="w-48" viewBox="0 0 60 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 30C0 24.0666 1.75947 18.2664 5.05591 13.3329C8.35235 8.39942 13.0377 4.55424 18.5195 2.28361C24.0013 0.012985 30.0333 -0.581115 35.8527 0.576442C41.6721 1.734 47.0176 4.59122 51.2132 8.7868C55.4088 12.9824 58.266 18.3279 59.4236 24.1473C60.5811 29.9667 59.987 35.9987 57.7164 41.4805C55.4458 46.9623 51.6006 51.6476 46.6671 54.9441C41.7336 58.2405 35.9334 60 30 60L30 44.2861C32.8255 44.2861 35.5876 43.4482 37.9369 41.8784C40.2862 40.3087 42.1173 38.0775 43.1986 35.467C44.2799 32.8566 44.5628 29.9842 44.0116 27.2129C43.4603 24.4417 42.0997 21.8962 40.1018 19.8982C38.1038 17.9003 35.5583 16.5397 32.7871 15.9884C30.0158 15.4372 27.1434 15.7201 24.533 16.8014C21.9225 17.8827 19.6914 19.7138 18.1216 22.0631C16.5518 24.4124 15.7139 27.1745 15.7139 30H0Z" fill="#FFA800" />
                            <path d="M0 40.2007H15.9152V62.2517C15.9152 71.0414 8.78973 78.1669 0 78.1669V78.1669V40.2007Z" fill="#FFA800" />
                        </svg>
                    </div>
                    <div className="mb-10 md:mb-20 text-gray-600 font-light">
                        <h1 className="font-black uppercase text-3xl lg:text-5xl text-yellow-500 mb-10">
                            This site seem to be locked!
                        </h1>
                        <p>The Website you're looking for isn't available.</p>
                        <p>If you are the owner of this site please contact your developer.</p>
                    </div>
                    <div className="mb-20 md:mb-0">
                        <button
                            onclick="window.location.href='https://google.com'"
                            className="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-yellow-500 hover:text-yellow-600"
                        >
                            <i className="mdi mdi-arrow-left mr-2" />
                            Go Back
                        </button>
                    </div>
                </div>
                <div className="w-full md:w-1/2 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-full max-w-lg lg:max-w-full mx-auto"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 1120 699"
                        version="1.1"
                    >
                        <title>Group</title>
                        <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g id="Group">
                                <circle
                                    id="Oval"
                                    fill="#F2F2F2"
                                    fillRule="nonzero"
                                    cx="292.609"
                                    cy={213}
                                    r={213}
                                />
                                <path
                                    d="M0,51.14237 C0,128.64026 48.6181,191.35056 108.70073,191.35056"
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M108.70073,191.35056 C108.70073,112.98191 162.95573,49.567 230.00445,49.567 M39.38432,58.16768 C39.38432,131.78244 70.38717,191.35056 108.70073,191.35056"
                                    id="Shape"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M108.70073,191.35056 C108.70073,91.21284 171.41103,10.18268 248.90892,10.18268"
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M85.8329,192.33905 C85.8329,192.33905 101.24845,191.86426 105.89431,188.55605 C110.54017,185.24784 129.60731,181.29755 130.75984,186.60327 C131.91237,191.90899 153.92655,212.99148 136.52247,213.13187 C119.11839,213.27226 96.08312,210.42087 91.4462,207.59638 C86.80928,204.77189 85.8329,192.33905 85.8329,192.33905 Z"
                                    id="Path"
                                    fill="#A8A8A8"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M136.83311,211.28489 C119.42903,211.42531 96.39378,208.57395 91.75685,205.74941 C88.22559,203.59841 86.81842,195.87996 86.34759,192.31898 C86.02152,192.33298 85.83296,192.33898 85.83296,192.33898 C85.83296,192.33898 86.80934,204.77174 91.44627,207.59628 C96.0832,210.42082 119.11844,213.27217 136.52253,213.13175 C141.54639,213.09123 143.28173,211.30382 143.18644,208.65649 C142.48846,210.256 140.5724,211.25474 136.83311,211.28489 Z"
                                    id="Path"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.2"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    cx="198.609"
                                    cy="424.5"
                                    rx={187}
                                    ry="25.44"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.1"
                                    cx="198.609"
                                    cy="424.5"
                                    rx={157}
                                    ry="21.359"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    cx="836.609"
                                    cy="660.5"
                                    rx={283}
                                    ry="38.5"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    cx="310.609"
                                    cy="645.5"
                                    rx={170}
                                    ry="23.127"
                                />
                                <path
                                    d="M462.60911,626 C552.60911,649 725.60911,596 744.60911,536 M309.60911,259 C309.60911,259 439.60911,223 447.60911,339 C455.60911,455 340.60911,488 430.60911,511 M184.01144,537.28332 C184.01144,537.28332 223.08374,526.46332 225.48819,561.32781 C227.89264,596.1923 193.32868,606.11068 220.37873,613.02347"
                                    id="Shape"
                                    stroke="#2F2E41"
                                    strokeWidth={2}
                                />
                                <path
                                    d="M778.70465,563.23988 L770.82711,613.53505 C770.82711,613.53505 732.04529,634.13789 759.31376,634.74386 C786.58223,635.34983 915.047,634.74386 915.047,634.74386 C915.047,634.74386 939.89161,634.74386 900.50382,612.92908 L892.62626,560.21008 L778.70465,563.23988 Z"
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M753.82817,634.19812 C760.02117,628.68773 770.82707,622.94612 770.82707,622.94612 L778.70463,572.65092 L892.62623,572.75809 L900.50379,622.34009 C909.68879,627.4272 915.37869,631.32709 918.70741,634.31827 C923.76623,633.16405 929.29457,628.87474 900.50379,612.92906 L892.62623,560.21006 L778.70463,563.23989 L770.82711,613.53506 C770.82711,613.53506 738.23896,630.84968 753.82817,634.19812 Z"
                                    id="Path"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.1"
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    x="578.433"
                                    y="212.689"
                                    width="513.253"
                                    height="357.52"
                                    rx="18.046"
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#F2F2F2"
                                    fillRule="nonzero"
                                    x="595.703"
                                    y="231.777"
                                    width="478.713"
                                    height="267.837"
                                />
                                <circle
                                    id="Oval"
                                    fill="#F2F2F2"
                                    fillRule="nonzero"
                                    cx="835.059"
                                    cy="223.293"
                                    r="3.03"
                                />
                                <path
                                    d="M1091.68605,520.82226 L1091.68605,552.1628 C1091.68605,556.948996 1089.78561,561.539336 1086.40126,564.923688 C1083.01691,568.30804 1078.42657,570.209082 1073.64037,570.20848 L596.4786,570.20848 C591.692402,570.209085 587.10206,568.308044 583.717706,564.923691 C580.333352,561.539339 578.43291,556.948998 578.43291,552.1628 L578.43291,520.82226 L1091.68605,520.82226 Z"
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                />
                                <polygon
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    points="968.978 667.466 968.978 673.526 642.968 673.526 642.968 668.678 643.417 667.466 651.452 645.651 962.312 645.651"
                                />
                                <path
                                    d="M1094.43711,661.53359 C1093.84328,664.07259 1091.6012,666.75102 1086.53533,669.28391 C1068.35633,678.3734 1031.39243,666.86005 1031.39243,666.86005 C1031.39243,666.86005 1002.91203,662.01232 1002.91203,649.28705 C1003.71171,648.739453 1004.54561,648.243577 1005.40861,647.80246 C1013.05155,643.75895 1038.3931,633.78124 1083.32631,648.22494 C1086.63658,649.264003 1089.5934,651.201671 1091.86737,653.82209 C1093.68819,655.95353 1095.1158,658.65715 1094.43711,661.53359 Z"
                                    id="Path"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M1094.43711,661.53359 C1072.18611,670.05959 1052.35281,670.69579 1031.9984,656.55859 C1021.73333,649.43242 1012.40751,647.66904 1005.40861,647.80241 C1013.05155,643.7589 1038.3931,633.78119 1083.32631,648.22489 C1086.63658,649.263953 1089.5934,651.201621 1091.86737,653.82204 C1093.68819,655.95353 1095.1158,658.65715 1094.43711,661.53359 Z"
                                    id="Path"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.1"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#F2F2F2"
                                    fillRule="nonzero"
                                    cx="1066.538"
                                    cy="654.135"
                                    rx="7.878"
                                    ry="2.424"
                                />
                                <circle
                                    id="Oval"
                                    fill="#F2F2F2"
                                    fillRule="nonzero"
                                    cx="835.059"
                                    cy="545.667"
                                    r="11.513"
                                />
                                <polygon
                                    id="Path"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.1"
                                    points="968.978 667.466 968.978 673.526 642.968 673.526 642.968 668.678 643.417 667.466"
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    x="108.609"
                                    y={159}
                                    width={208}
                                    height={242}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    x="87.609"
                                    y={135}
                                    width={250}
                                    height={86}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    x="87.609"
                                    y={237}
                                    width={250}
                                    height={86}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    x="87.609"
                                    y={339}
                                    width={250}
                                    height={86}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    opacity="0.4"
                                    x="271.609"
                                    y={150}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    opacity="0.8"
                                    x="294.609"
                                    y={150}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#FF0000"
                                    fillRule="nonzero"
                                    x="317.609"
                                    y={150}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    opacity="0.4"
                                    x="271.609"
                                    y={251}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    opacity="0.8"
                                    x="294.609"
                                    y={251}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#FF0000"
                                    fillRule="nonzero"
                                    x="317.609"
                                    y={251}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    opacity="0.4"
                                    x="271.609"
                                    y={352}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#DC3545"
                                    fillRule="nonzero"
                                    opacity="0.8"
                                    x="294.609"
                                    y={352}
                                    width={16}
                                    height={16}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                    x="317.609"
                                    y={352}
                                    width={16}
                                    height={16}
                                />
                                <circle
                                    id="Oval"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    cx="316.609"
                                    cy={538}
                                    r={79}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    x="280.609"
                                    y={600}
                                    width={24}
                                    height={43}
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    x="328.609"
                                    y={600}
                                    width={24}
                                    height={43}
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    cx="300.609"
                                    cy="643.5"
                                    rx={20}
                                    ry="7.5"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    cx="348.609"
                                    cy="642.5"
                                    rx={20}
                                    ry="7.5"
                                />
                                <circle
                                    id="Oval"
                                    fill="#FFFFFF"
                                    fillRule="nonzero"
                                    cx="318.609"
                                    cy={518}
                                    r={27}
                                />
                                <circle
                                    id="Oval"
                                    fill="#3F3D56"
                                    fillRule="nonzero"
                                    cx="318.609"
                                    cy={518}
                                    r={9}
                                />
                                <path
                                    d="M239.97644,464.53228 C233.59755,435.9647 253.98829,407.09836 285.52044,400.05751 C317.05259,393.01666 347.78554,410.46751 354.16444,439.03511 C360.54334,467.60271 339.64583,478.1389 308.11369,485.17975 C276.58155,492.2206 246.35533,493.09986 239.97644,464.53228 Z"
                                    id="Path"
                                    fill="#008FFF"
                                    fillRule="nonzero"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    transform="translate(385.824000, 510.844000) rotate(-23.171000) translate(-385.824000, -510.844000) "
                                    cx="385.824"
                                    cy="510.844"
                                    rx="39.5"
                                    ry="12.4"
                                />
                                <ellipse
                                    id="Oval"
                                    fill="#2F2E41"
                                    fillRule="nonzero"
                                    transform="translate(237.824000, 563.844000) rotate(-23.171000) translate(-237.824000, -563.844000) "
                                    cx="237.824"
                                    cy="563.844"
                                    rx="39.5"
                                    ry="12.4"
                                />
                                <path
                                    d="M362.60911,561 C362.60911,568.732 342.7005,584 320.60911,584 C298.51772,584 277.60911,569.732 277.60911,562 C277.60911,554.268 298.51772,556 320.60911,556 C342.7005,556 362.60911,553.268 362.60911,561 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                    fillRule="nonzero"
                                />
                                <rect
                                    id="Rectangle"
                                    stroke="#2F2E41"
                                    strokeWidth={4}
                                    fill="#FFFFFF"
                                    x={793}
                                    y={320}
                                    width={92}
                                    height={92}
                                    rx={8}
                                />
                                <path
                                    d="M809.9948,387.897104 C822.669441,365.364408 853.250726,376.593105 868.424658,391.1073"
                                    id="Path-2"
                                    stroke="#2F2E41"
                                    strokeWidth={3}
                                />
                                <text
                                    id="X"
                                    fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                                    fontSize={28}
                                    fontWeight="bold"
                                    fill="#DD0000"
                                >
                                    <tspan x={810} y={353}>
                                        X
                                    </tspan>
                                </text>
                                <text
                                    id="X"
                                    fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                                    fontSize={28}
                                    fontWeight="bold"
                                    fill="#DD0000"
                                >
                                    <tspan x={852} y={352}>
                                        X
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </svg>
                    <a
                        href="mailto:?subject=Payment-Issues"
                        target="_blank"
                        className="text-xs text-gray-300" rel="noreferrer"
                    >
                        This message is showing because of error in communications - contact
                        developer.
                    </a>
                </div>
            </div>
            <div className="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform" />
            <div className="w-96 h-full bg-yellow-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform" />
        </div>
    );
}

export default LockSite;