import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeActions } from "store/theme/theme-slice";

import { toast } from "react-toastify";

import { selectTheme } from "store/theme/theme.selector";
import { checkUnseen, checkUserSession, getUserWallets } from "store/user/user.actions";
import { fetchSiteInfo } from "store/info/info.actions";
import { NOTIFICATION_TONE, USER_CURRENCY } from "./states";
import { NumericFormat } from "react-number-format";
import INFO_TONE from "assets/sounds/sound4.ogg";
import ERROR_TONE from "assets/sounds/sound5.ogg";
import WARN_TONE from "assets/sounds/sound6.ogg";
import SUCCESS_TONE from "assets/sounds/sound2.ogg";
import { selectInfo, selectIsToneOn } from "store/info/info.selector";
import { selectUser } from "store/user/user.selector";
import { updateUserSettings } from "store/user/user.action";
import { fetchMarketValues, fetchMarketValuesByBTC } from "store/market/market.actions";

export const alertMessage = (type, msg) => {
  const theme =
    localStorage.theme != null
      ? localStorage.theme
      : window.matchMedia("(prefers-color-scheme: dark)")
        ? "dark"
        : "light",
    notifyTone = localStorage.getItem(NOTIFICATION_TONE),
    isToneOn =
      notifyTone == null ? false : notifyTone === "false" ? false : true,
    tone =
      type === "error"
        ? ERROR_TONE
        : type === "info"
          ? INFO_TONE
          : type === "success"
            ? SUCCESS_TONE
            : WARN_TONE;
  if (isToneOn) {
    const audio = new Audio(tone);
    try {
      audio.play();
    } catch (error) {
      console.log(error);
    }
  }
  const toastC =
    type === "error"
      ? toast.error
      : type === "info"
        ? toast.info
        : type === "success"
          ? toast.success
          : type === "warn"
            ? toast.warn
            : toast;
  toastC(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  });
};

// const ip_details = JSON.parse(localStorage.getItem(USER_IP_AND_LOCATION));

export const getNumberFormat = (value) => (
  <NumericFormat
    value={value}
    displayType={"text"}
    thousandSeparator={true}
    prefix={'$'}
    renderText={(formattedValue) => <span>{formattedValue}</span>} // <--- Don't forget this!
  />
), getNumberFormatWithOut = (value) => (
  <NumericFormat
    value={value}
    displayType={"text"}
    thousandSeparator={true}
    prefix={''}
    renderText={(formattedValue) => <span>{formattedValue}</span>} // <--- Don't forget this!
  />
),
  getRoundedFormat = (num) => {
    return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
  };
const InitialState = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme),
    siteInfo = useSelector(selectInfo),
    notifyTone = useSelector(selectIsToneOn);
  const user = useSelector(selectUser);
  useEffect(() => {
    localStorage.setItem(NOTIFICATION_TONE, JSON.stringify(notifyTone));
  }, [notifyTone]);
  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      dispatch(themeActions.changeMode("dark"));
    } else {
      dispatch(themeActions.changeMode("light"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    // console.log(theme);
    if (theme === "dark") {
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
    }
  }, [theme, dispatch]);
  useEffect(() => {
    dispatch(checkUserSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchSiteInfo());
  }, [dispatch]);
  const checkUserNotifications = () => {
    if (user != null) {
      dispatch(checkUnseen(user.id));
      dispatch(getUserWallets(user))
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkUserNotifications();
      if (siteInfo.whitelist != null) {
        if (siteInfo.whitelist.length > 0) {
          dispatch(fetchMarketValues(siteInfo.whitelist))
          dispatch(fetchMarketValuesByBTC(siteInfo.whitelist))
        }
      }
    }, 30000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    const userCurrency = localStorage.getItem(USER_CURRENCY);
    if (userCurrency == null) {
      localStorage.setItem(USER_CURRENCY, 'btc')
    } else {
      updateUserSettings('currency', userCurrency)
    }
  }, [])
  return <></>
};

export default InitialState;
