import { createSelector } from "reselect";

const selectMarketReducer = (state) => state.market;

export const selectAllMarketValues = createSelector(
    [selectMarketReducer],
    (market) => market.all
  ),
  selectTopGainers = createSelector(
    [selectMarketReducer],
    (market) => market.gainers
  ),
  selectBTCMarketValues = createSelector(
    [selectMarketReducer],
    (market) => market.btc
  );
