import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils/helpers/helpers";

function GoBack({ path = null, isDefault = true }) {
    const navigate = useNavigate(),
        goback = () => {
            if (path != null) { navigate(path) } else {
                navigate(-1)
            }
        }
    return (<span className={classNames("block lg:hidden w-fit", isDefault ? ' mt-6 ' : '')} role="button" onClick={goback}>
        <BiLeftArrowAlt className="h-6 w-6 text-black dark:text-white" /></span>);
}

export default GoBack;