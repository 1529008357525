import imageSvg from 'assets/img/500.svg'
import InitialState from 'utils/initial-state/initial-state';

function Error503() {
    return (<div id="root" className="h-screen flex grow">
        <InitialState />
        <main className="grid w-full grow grid-cols-1 place-items-center">
            <div className="max-w-md p-6 text-center">
                <div className="w-full">
                    <img
                        className="w-full"
                        src={imageSvg}
                        alt="503 Error"
                    />

                </div>
                <p className="pt-4 text-7xl font-bold text-primary dark:text-accent">
                    503
                </p>
                <p className="pt-4 text-xl font-semibold text-slate-800 dark:text-zinc-50">
                    Site Under Maintenance!
                </p>
                <p className="pt-2 text-slate-500 dark:text-zinc-200">
                    The server will be done for a while. Please be patient or try again
                    later
                </p>
            </div>
        </main>
    </div>
    );
}

export default Error503;