import { Link } from "react-router-dom";
import icon from 'assets/img/coming-soon.png'
import bgLight from 'assets/img/_images_gradient.jpg'
import bgDark from 'assets/img/_images_gradient_dark.jpg'

function ComingSoon() {
    return (<section className="relative py-16 md:py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
                alt="gradient"
                loading="lazy"
                width={1920}
                height={789}
                decoding="async"
                data-nimg={1}
                style={{ color: "transparent" }}
                srcSet={bgLight}
                src={bgLight}
            />
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
            <img
                alt="gradient dark"
                loading="lazy"
                width={1519}
                height={760}
                decoding="async"
                data-nimg={1}
                className="h-full w-full object-cover"
                src={bgDark}
                style={{ color: "transparent" }}
            />
        </picture>
        <div className="container">
            <div className="mx-auto max-w-lg text-center">
                <span className="mb-14 inline-block">
                    <img
                        alt="gradient"
                        loading="lazy"
                        width={336}
                        height={165}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent" }}
                        srcSet={icon}
                        src={icon}
                    />

                </span>
                <h3 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white md:text-3xl">
                    🚀 Exciting Things Are On The Horizon 🚀
                </h3>
                <p className="dark:text-amber-100 mb-12 text-lg leading-normal">
                    At Pexo, we've been working tirelessly behind the scenes to bring you an incredible new experience. We can't wait to share it with you.
                </p>
                <Link
                    className="bg-amber-500 shadow-inner shadow-xl shadow-orange-600 hover:bg-amber-700 inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                    to="/"
                >
                    Navigate Back Home
                </Link>
            </div>
        </div>
    </section>
    );
}

export default ComingSoon;