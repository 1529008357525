export const stapleCoins = [
  {
    id: "tether",
    symbol: "usdt",
    name: "Tether",
    image:
      "https://assets.coingecko.com/coins/images/325/large/Tether.png?1668148663",
    current_price: 0.99977,
    market_cap: 83039520662,
    market_cap_rank: 3,
    fully_diluted_valuation: 83039520662,
    total_volume: 8284937720,
    high_24h: 1.001,
    low_24h: 0.996235,
    price_change_24h: 0.0002206,
    price_change_percentage_24h: 0.02207,
    market_cap_change_24h: 65321280,
    market_cap_change_percentage_24h: 0.07872,
    circulating_supply: 83016246102.3236,
    total_supply: 83016246102.3236,
    max_supply: null,
    ath: 1.32,
    ath_change_percentage: -24.39846,
    ath_date: "2018-07-24T00:00:00.000Z",
    atl: 0.572521,
    atl_change_percentage: 74.71505,
    atl_date: "2015-03-02T00:00:00.000Z",
    roi: null,
    last_updated: "2023-09-11T07:25:00.311Z",
  },
  {
    id: "usd-coin",
    symbol: "usdc",
    name: "USD Coin",
    image:
      "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
    current_price: 1.001,
    market_cap: 26181423314,
    market_cap_rank: 5,
    fully_diluted_valuation: 26181515232,
    total_volume: 3540223673,
    high_24h: 1.002,
    low_24h: 0.998316,
    price_change_24h: 0.00081083,
    price_change_percentage_24h: 0.0811,
    market_cap_change_24h: 35346238,
    market_cap_change_percentage_24h: 0.13519,
    circulating_supply: 26169908699.7355,
    total_supply: 26170000577.3197,
    max_supply: null,
    ath: 1.17,
    ath_change_percentage: -14.68115,
    ath_date: "2019-05-08T00:40:28.300Z",
    atl: 0.877647,
    atl_change_percentage: 14.00254,
    atl_date: "2023-03-11T08:02:13.981Z",
    roi: null,
    last_updated: "2023-09-11T07:28:44.982Z",
  },
  {
    id: "binance-usd",
    symbol: "busd",
    name: "Binance USD",
    image:
      "https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766",
    current_price: 1.001,
    market_cap: 2582617355,
    market_cap_rank: 27,
    fully_diluted_valuation: 2582617355,
    total_volume: 2258014580,
    high_24h: 1.002,
    low_24h: 0.996745,
    price_change_24h: 0.00064903,
    price_change_percentage_24h: 0.06491,
    market_cap_change_24h: -6833654.4281692505,
    market_cap_change_percentage_24h: -0.2639,
    circulating_supply: 2583617003.92,
    total_supply: 2583617003.92,
    max_supply: null,
    ath: 1.15,
    ath_change_percentage: -13.37075,
    ath_date: "2020-03-13T02:35:42.953Z",
    atl: 0.901127,
    atl_change_percentage: 10.95862,
    atl_date: "2021-05-19T13:04:37.445Z",
    roi: null,
    last_updated: "2023-09-11T07:28:44.063Z",
  },
];
