import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootSaga } from "./root-saga";

import { rootReducer } from "./root-reducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["info", "theme", "user", "market"],
  },
  persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middleWares = [
  // process.env.NODE_ENV !== "production" && logger,
  sagaMiddleware,
].filter(Boolean);
// console.log(middleWares);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: middleWares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
