import { all, takeLatest, put, call } from "redux-saga/effects";
import { hostURL } from "../../utils/initial-state/states";
import { setAccounts, setPlans, setSiteInfo } from "./info.actions";
import { infoActions } from "./info.slice";
import { INFO_ACTION_TYPES } from "./info.types";

// ---------------------------------------------------------------

export function* fetchInfo() {
  const uri = `${hostURL}/api/get/site_data`;
  try {
    const response = yield fetch(uri);
    const json = yield response.json();
    // const makeObject = json.reduce(
    //   (a, v) => ({ ...a, [v.setting_name]: v.setting }),
    //   {}
    // );
    yield put(setSiteInfo(json));
  } catch (error) {
    console.log(error);
  }
}
// ---------------------------------------------------------------

export function* fetchPlans() {
  const uri = `${hostURL}/api/get/plans`;
  try {
    const response = yield fetch(uri);
    const data = yield response.json();
    yield put(setPlans(data));
  } catch (error) {
    console.log(error);
  }
}
// ---------------------------------------------------------------

export function* fetchAccounts() {
  const uri = `${hostURL}/api/accounts`;
  try {
    const response = yield fetch(uri);
    const data = yield response.json();
    yield put(setAccounts(data));
  } catch (error) {
    console.log(error);
  }
}
// --------------------------------------------------------------
export function* setInfo({ payload }) {
  yield put(infoActions.setInfo(payload));
}
export function* setTradePlans({ payload }) {
  yield put(infoActions.setPlans(payload));
}
export function* setTradeAccounts({ payload }) {
  yield put(infoActions.setAccounts(payload));
}
// -------------------------------------------------------------
export function* onFetchInfo() {
  yield takeLatest(INFO_ACTION_TYPES.FETCH_INFO, fetchInfo);
  // yield takeLatest(INFO_ACTION_TYPES.FETCH_INFO, fetchPlans);
  // yield takeLatest(INFO_ACTION_TYPES.FETCH_INFO, fetchAccounts);
}
export function* onSetInfo() {
  yield takeLatest(INFO_ACTION_TYPES.SET_INFO, setInfo);
}
// ---------------------------------------------------------------

export function* onSetPlans() {
  yield takeLatest(INFO_ACTION_TYPES.SET_PLANS, setTradePlans);
}
// ---------------------------------------------------------------

export function* onSetAccounts() {
  yield takeLatest(INFO_ACTION_TYPES.SET_ACCOUNTS, setTradeAccounts);
}
// ---------------------------------------------------------------

export function* infoSagas() {
  yield all([
    call(onFetchInfo),
    call(onSetInfo),
    call(onSetAccounts),
    call(onSetPlans),
  ]);
}
