import { createAction } from "../../utils/reducer/reducer.utils";
import { infoActions } from "./info.slice";
import { INFO_ACTION_TYPES } from "./info.types";

export const fetchSiteInfo = () => createAction(INFO_ACTION_TYPES.FETCH_INFO);
// ------------------------------------------------------------------------------

export const setSiteInfo = (data) =>
  createAction(INFO_ACTION_TYPES.SET_INFO, data);
// ------------------------------------------------------------------------------

export const setPlans = (data) =>
  createAction(INFO_ACTION_TYPES.SET_PLANS, data);
// ------------------------------------------------------------------------------

export const setAccounts = (data) =>
  createAction(INFO_ACTION_TYPES.SET_ACCOUNTS, data);
// ------------------------------------------------------------------------------

export const setIsToneOn = (bool) => infoActions.setNotifyTone(bool);
// ------------------------------------------------------------------------------
