
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectInfo } from "store/info/info.selector";
import { depositLink } from "utils/helpers/links";
import { stapleCoins } from "utils/helpers/staple-coins";
import BalanceTableLg from "./balance-table-lg.component";
import BalanceTableSM from "./balance-table-sm.component";
import { selectAllMarketValues } from "store/market/market.selector";
import { CustomInput } from "components/form-input/form-input.component";
import { AiOutlineSearch } from "react-icons/ai";

export const ITEMS_PER_PAGE = 24,
    DEFAULTDATA = {
        "wallet_address": null,
        "type": "spot",
        "user_id": null,
        "coin_id": null,
        "total_deposit": "0.00",
        "balance": "0.00",
        "created_at": "2023-09-06T20:41:22.000000Z",
        "updated_at": "2023-09-06T20:41:59.000000Z"
    }, getMerge = (wallets, response) => {
        const userWallets = wallets.map(i => i.coin_id)
        const data = response.filter(data => userWallets.includes(data.id)).map(item => ({
            ...item, ...wallets.find(a => a.coin_id === item.id)
        })),
            others = response.filter(data => !userWallets.includes(data.id)).map(i => ({ ...i, ...DEFAULTDATA, coin_id: i.id }))
        // console.log(data, others);
        return ([...data, ...others]);

    }, sortByProperty = (arr, prop) => {
        // Use the Array.sort() method to sort the array based on the specified property
        arr.sort(function (a, b) {
            // Ensure the property values are of the same type before comparison
            const propA = typeof a[prop] === 'string' ? a[prop].toUpperCase() : a[prop];
            const propB = typeof b[prop] === 'string' ? b[prop].toUpperCase() : b[prop];

            if (propA < propB) {
                return -1;
            }
            if (propA > propB) {
                return 1;
            }
            return 0;
        });

        return arr;
    }

const BalanceTable = ({ wallets, type }) => {
    const [searchValue, setSearchValue] = useState(''),
        [page, setPage] = useState(1),
        [marketList, setMarketList] = useState(null),
        marketListValues = useSelector(selectAllMarketValues),
        siteInfo = useSelector(selectInfo),
        navigate = useNavigate(),
        // colors = ['text-red-600', 'text-green-500', 'text-gray-600 dark:text-white'],
        stableCoinsMap = stapleCoins.map(i => i.id)
    const getMarketList = async () => {
        try {
            const res = marketListValues,
                userWallets = wallets?.map(i => i.coin_id)
            setMarketList(state => {
                const data = res?.filter(data => userWallets.includes(data.id)).map(item => ({
                    ...item, ...wallets.find(a => a.coin_id === item.id)
                })),
                    others = res?.filter(data => !userWallets.includes(data.id)).map(i => ({ ...i, ...DEFAULTDATA, coin_id: i.id }))
                // console.log(data, others);
                return ([...data, ...sortByProperty(others, 'symbol')]);

            }
            );
        } catch (error) {
            console.log(error);
        }
    },
        goToLink = (data, toLink = depositLink) => {
            navigate(toLink, {
                state: { coin: data }
            })
        }, paginate = (pageNum) => {
            setPage(pageNum)
        }
    const indexOfLastPost = page * ITEMS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - ITEMS_PER_PAGE,
        filtered = marketList?.filter(item => String(item.name).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || String(item.symbol).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    const currentItems = filtered?.slice(indexOfFirstPost, indexOfLastPost);
    const nextPage = () => { setPage(currentPage => currentPage + 1) };

    const prevPage = () => { setPage(currentPage => currentPage - 1) };

    useEffect(() => {
        getMarketList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log('wallets: ', wallets);
    return (
        <><div className="grid grid-cols-12 gap-2 py-2 ">
            <div className="col-span-12 lg:col-span-6">
                <CustomInput
                    type="search"
                    id="searchValue"
                    name="searchValue"
                    placeholder="Search Coin Name"
                    value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value); paginate(1) }}
                    icon={<AiOutlineSearch className="text-stone-500 dark:text-amber-500 opacity-50" />}
                    required />
            </div>
            {/* <div className="col-span-1 m-3">
                    <AiOutlineEllipsis className="text-stone-500 dark:text-amber-500 opacity-50" />
                </div> */}
        </div>
            <div className="hidden lg:block">
                <BalanceTableLg {...{ marketList: filtered, type, page, paginate, prevPage, nextPage, siteInfo, stableCoinsMap, currentItems, goToLink }} />
            </div>
            <div className="block lg:hidden">
                <BalanceTableSM {...{ marketList: filtered, type, page, paginate, prevPage, nextPage, siteInfo, stableCoinsMap, currentItems, goToLink }} />
            </div>
        </>
    );
}

export default BalanceTable;