export const USER_ACTION_TYPES = {
  SET_CURRENT_USER: "user/SET_CURRENT_USER",
  CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
  GOOGLE_SIGN_IN_START: "user/GOOGLE_SIGN_IN_START",
  EMAIL_SIGN_IN_START: "user/EMAIL_SIGN_IN_START",
  FORGOT_PASSWORD: "user/FORGOT_PASSWORD",
  SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
  SIGN_IN_FAILED: "user/SIGN_IN_FAILED",
  USER_SIGN_UP_START: "user/USER_SIGN_UP_START",
  SIGN_UP_SUCCESS: "user/SIGN_UP_SUCCESS",
  SIGN_UP_FAILED: "user/SIGN_UP_FAILED",
  SIGN_OUT_START: "user/SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "user/SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILED: "user/SIGN_OUT_FAILED",
  SET_IS_LOADING: "user/SET_IS_LOADING",
  UPDATE_USER: "user/UPDATE_USER",
  CHANGE_PASSWORD: "user/CHANGE_PASSWORD",
  CHECK_UNSEEN: "user/CHECK_UNSEEN",
  SET_TWO_STEP_VERIFICATION: "user/SET_TWO_STEP_VERIFICATION",
  FETCH_USER_IP_LOCATION: "user/FETCH_USER_IP_LOCATION",
  FETCH_USER_DETAILS: "user/FETCH_USER_DETAILS",
  FETCH_USER_WALLETS: "user/FETCH_USER_WALLETS",
  PAY_NOW_DEFAULT: "user/PAY_NOW_DEFAULT",
  RESEND_CODE: "user/RESEND_CODE",
};
