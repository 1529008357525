import { all, takeLatest, put, call } from "redux-saga/effects";

import { alertMessage } from "../../utils/initial-state/initial-state";
import {
  AUTH_TOKEN,
  hostURL,
  ipURL,
  getLocationURL,
  makeId,
  OTP_TOKEN,
  JWT_TOKEN_STRING,
  USER_IP_AND_LOCATION,
  // transPaymentType,
  feeRate,
} from "../../utils/initial-state/states";
import jwtDecode from "jwt-decode";
import jwt from "jwt-encode";
// import { fetchUserStore } from "../user-store/store-action";

import {
  signUpFailed,
  signOutFailed,
  checkUserSession,
  checkUnseen,
  getUserWallets,
} from "./user.actions";
import { userActions } from "./user.slice";

import { USER_ACTION_TYPES } from "./user.types";
import { apiHeaders } from "utils/helpers/helpers";
import { authLinks } from "utils/helpers/links";

export const setCurrentUser = (user) => userActions.setCurrentUser(user);

export const setIsLoggedIn = (boolean) => userActions.setIsLoggedIn(boolean);

export const setIsLoading = (boolean) => userActions.setIsLoading(boolean);

export function* updateLoginAuthentication({ payload }) {
  yield put(userActions.setTwoStepVerify(payload));
}

export function checkInternetConnection() {
  return true;
  // return navigator.onLine;
}

export function* checkUnseenNofications({ payload }) {
  try {
    const notifyURL = `${hostURL}/api/activities/unseen/${payload}`;
    const response = yield fetch(notifyURL);
    const data = yield response.json();
    yield put(
      userActions.setUnseen({ unseen: data, unseenCount: data.length })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* checkWallet({ payload }) {
  try {
    const uri = `${hostURL}/api/user/balance/${payload.id}`,
      response = yield fetch(uri, {
        headers: {
          ...apiHeaders,
          Authorization: `Bearer ${payload.api_token}`,
          "X-Custom-Header": "header value",
        },
      });
    const data = yield response.json();
    yield put(userActions.setWallets(data));
  } catch (error) {
    console.log(error);
  }
}

export function* fetchUserDetails({ payload }) {
  try {
    yield put(setIsLoading(true));
    const detailsUrl = `${hostURL}/api/user/${payload}`;
    const response = yield fetch(detailsUrl);
    const data = yield response.json();
    yield put(setCurrentUser({ ...data, role: "user" }));
  } catch (error) {
    console.log(error.message);
  } finally {
    yield put(setIsLoading(true));
  }
}

export function* getIPLocation() {
  try {
    const res = yield fetch(ipURL);
    const data = yield res.json();
    // console.log("ip", data);
    if (!data) return;

    const locFetch = yield fetch(getLocationURL(data.ip));
    const location = yield locFetch.json();
    const value = JSON.stringify(location);
    localStorage.setItem(USER_IP_AND_LOCATION, value);
  } catch (error) {
    console.log(error.message);
  }
}
export function* sendOTP({ id, role, email }) {
  try {
    const res = yield fetch(ipURL);
    const data = yield res.json();
    // console.log("ip", data);
    if (!data) return;

    const locFetch = yield fetch(getLocationURL(data.ip));
    const location = yield locFetch.json();

    const otp = makeId(6);
    const details = { ...location, otp, email, user_id: id, role };

    const submit = yield fetch(`${hostURL}/api/sendOTP`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });

    const response = yield submit.json();
    if (response) {
      alertMessage(response.type, response.message);
      yield put(userActions.setTwoStepVerify(true));
    }
    yield put(setIsLoading(false));
  } catch (error) {
    alertMessage("error", "Error trying to sent OTP_TOKEN");
    yield put(setIsLoading(false));
  }
}
function* resendOTP({ payload }) {
  yield put(setIsLoading(true));
  yield call(sendOTP, payload);
}

export function* getSnapshotFromUserAuth({
  user_id,
  email,
  role,
  from = "session",
}) {
  console.log(email);
  try {
    const uri =
      role === "user"
        ? `${hostURL}/api/user/${user_id}`
        : `${hostURL}/api/admin/${user_id}`;
    if (role === "user") {
      yield put(checkUnseen(user_id));
    }
    const res = yield fetch(uri);
    const user = yield res.json();
    if (user) {
      yield put(getUserWallets(user));
      if(user.wallets!=null){
        yield put(userActions.setWallets(user.wallets));
      }
      if (from === "session") {
        yield put(setCurrentUser({ ...user, role }));
        yield put(setIsLoggedIn(true));
        yield put(setIsLoading(false));
      } else if (from === "proceed") {
        const token = jwt({ user_id, email, role }, JWT_TOKEN_STRING, {
          expiresIn: "10h",
        });
        sessionStorage.setItem(AUTH_TOKEN, token);
        yield put(setCurrentUser({ ...user, role }));
        yield put(setIsLoggedIn(true));
        yield put(setIsLoading(false));
      } else {
        if (user.twoStepVerify === 1) {
          yield call(sendOTP, user);
        } else {
          const token = jwt({ user_id, email, role }, JWT_TOKEN_STRING, {
            expiresIn: "10h",
          });
          sessionStorage.setItem(AUTH_TOKEN, token);
          yield put(setCurrentUser({ ...user, role }));
          yield put(setIsLoggedIn(true));
          yield put(setIsLoading(false));
        }
      }
    } else {
      alertMessage("error", "user is not an admin");
    }
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

export function* signInWithGoogle() {
  try {
    yield put(setIsLoading(true));
    // const { user } = yield call(signInWithGooglePopup);
    // yield call(getSnapshotFromUserAuth, user);
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(userActions.setError(error));
    yield put(setIsLoading(false));
  }
}
export function* signInWithEmailAndPassord({
  payload: { email, password, role, proceed = false },
}) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }
  try {
    yield put(setIsLoading(true));
    // console.log("role", role);
    const uri =
      role === "user"
        ? `${hostURL}/api/user/login`
        : `${hostURL}/api/admin/login`;
    const submit = yield fetch(uri, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    const res = yield submit.json();
    if (res.type === "success") {
      const user = { user_id: res.id, email, role };
      // alertMessage("success", res.message);
      if (proceed) {
        yield call(getSnapshotFromUserAuth, { ...user, from: "proceed" });
      } else {
        yield call(getSnapshotFromUserAuth, { ...user, from: "login" });
      }
      alertMessage(res.type, res.message);
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    alertMessage("error", error.message);
    yield put(userActions.setError(error));
    yield put(setIsLoading(false));
  }
}
export function* userSignUp({
  payload: { email, password, name, referrer, navigate },
}) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }
  try {
    yield put(setIsLoading(true));
    const uri = `${hostURL}/api/user/register`;

    const submit = yield fetch(uri, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, name, referrer }),
    });
    const res = yield submit.json();
    // console.log(res);
    if (res.type === "success") {
      const user = { user_id: res.id, email, role: "user" };
      const token = jwt(user, JWT_TOKEN_STRING, {
        expiresIn: "10h",
      });
      sessionStorage.setItem(AUTH_TOKEN, token);
      alertMessage("success", res.message);
      yield put(setIsLoading(false));
      navigate(authLinks.default + authLinks.confirmed);
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    alertMessage("error", error.message);
    yield put(signUpFailed(error));
  }
}

export function* signInAfterSignUp({ payload: user }) {
  try {
    yield call(getSnapshotFromUserAuth, { ...user, from: "proceed" });
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userToken = sessionStorage.getItem(AUTH_TOKEN);
    if (userToken != null && userToken) {
      const decodeToken = jwtDecode(userToken);
      // console.log(decodeToken);
      if (decodeToken.exp * 1000 < Date.now()) {
        sessionStorage.removeItem(AUTH_TOKEN);
        yield put(userActions.logout());
        yield put(setIsLoading(false));

        sessionStorage.removeItem(OTP_TOKEN);
      } else {
        yield call(getSnapshotFromUserAuth, decodeToken);
        // yield put(setIsLoggedIn(true));
      }
    } else {
      yield put(userActions.logout());
      sessionStorage.removeItem(OTP_TOKEN);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(userActions.setError(error));
    yield put(setIsLoading(false));
    sessionStorage.removeItem(OTP_TOKEN);
  }
}
export function* signOut() {
  try {
    // yield call(signOutUser);

    const userToken = sessionStorage.getItem(AUTH_TOKEN);
    if (userToken != null && userToken) {
      sessionStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(USER_IP_AND_LOCATION);
      alertMessage("info", "Logged Out");
      yield put(checkUserSession());
    }
    yield put(userActions.logout());
  } catch (error) {
    yield put(signOutFailed(error));
  }
}

export function* forgotPasswordCallback({ payload: { email } }) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }
  try {
    yield put(setIsLoading(true));
    const uri = `${hostURL}/api/user/forgetPassword`;

    const submit = yield fetch(uri, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const res = yield submit.json();
    if (res.type === "success") {
      alertMessage("success", res.message);
      yield put(setIsLoading(false));
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    alertMessage("error", "Sorry, Something went wrong");
    yield put(setIsLoading(false));
  }
}

export function* updateCurrentUser({ payload }) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }

  try {
    yield put(setIsLoading(true));
    const uri = `${hostURL}/api/user/update/${payload.id}`;

    const submit = yield fetch(uri, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = yield submit.json();
    if (res.type === "success") {
      alertMessage("success", res.message);
      yield put(setIsLoading(false));
      yield put(checkUserSession());
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    alertMessage("error", "Sorry, Something went wrong");
    yield put(setIsLoading(false));
  }
}

export function* updateUserPassword({ payload }) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }

  try {
    yield put(setIsLoading(true));
    const uri =
      payload.role === "user"
        ? `${hostURL}/api/user/changePassword/${payload.id}`
        : `${hostURL}/api/admin/changePassword/${payload.id}`;

    const submit = yield fetch(uri, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = yield submit.json();
    if (res.type === "success") {
      alertMessage("success", res.message);
      yield put(setIsLoading(false));
      yield put(checkUserSession());
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    alertMessage("error", "Sorry, Something went wrong");
    yield put(setIsLoading(false));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(
    USER_ACTION_TYPES.EMAIL_SIGN_IN_START,
    signInWithEmailAndPassord
  );
}

const setConfirmDetails = (data) => {
  // console.log("data: ", data);

  const total = Number(data.amount_usd) / data.current_price,
    fee = Number(data.amount_usd) * feeRate;

  localStorage.setItem(
    "_transaction_details",
    JSON.stringify({
      ...data,
      coin_amount: total,
      fee,
    })
  );
  window.location.reload();
};
export function* confirmDetails(trans_details = {}) {
  try {
    const { coin_name } = trans_details;
    const payurl = `https://api.coingecko.com/api/v3/simple/price?ids=${coin_name}&&vs_currencies=usd`;
    const getCurrentPrice = yield fetch(payurl);
    const data = yield getCurrentPrice.json();

    setConfirmDetails({
      ...trans_details,
      current_price: data[coin_name].usd,
    });
  } catch (error) {
    alertMessage("error", "No Network Connection.");
  }
}

export function* paymentDefault({ payload }) {
  const isOnline = yield call(checkInternetConnection);
  if (!isOnline) {
    alertMessage("error", "No Internet connection");
    return;
  }

  try {
    yield put(setIsLoading(true));
    const uri = `${hostURL}/api/transaction/create`;
    const submit = yield fetch(uri, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = yield submit.json(),
      data = res;

    if (res.type === "success") {
      sessionStorage.setItem("transaction_id", res.tran_id);
      alertMessage("success", res.message);
      const trans_details = data.trans_details;
      // console.log(trans_details);
      yield call(confirmDetails, trans_details);
    } else {
      alertMessage(res.type, res.message);
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(userActions.setError(error));
    yield put(setIsLoading(false));
  }
}

export function* triggerLoading({ payload }) {
  yield put(userActions.setIsLoading(payload));
}

export function* onGoogleSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onSignUpStart() {
  yield takeLatest(USER_ACTION_TYPES.USER_SIGN_UP_START, userSignUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
}
export function* onSignInSuccess() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_IN_SUCCESS, signInAfterSignUp);
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* onSetIsLoading() {
  yield takeLatest(USER_ACTION_TYPES.SET_IS_LOADING, triggerLoading);
}
export function* onForgotPassword() {
  yield takeLatest(USER_ACTION_TYPES.FORGOT_PASSWORD, forgotPasswordCallback);
}
export function* onUpdateUser() {
  yield takeLatest(USER_ACTION_TYPES.UPDATE_USER, updateCurrentUser);
}

export function* onChangePassword() {
  yield takeLatest(USER_ACTION_TYPES.CHANGE_PASSWORD, updateUserPassword);
}
export function* onCheckUnseen() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_UNSEEN, checkUnseenNofications);
}

export function* onFetchUserDetails() {
  yield takeLatest(USER_ACTION_TYPES.FETCH_USER_DETAILS, fetchUserDetails);
}

export function* onGetWallets() {
  yield takeLatest(USER_ACTION_TYPES.FETCH_USER_WALLETS, checkWallet);
}

export function* onTwoStepVerify() {
  yield takeLatest(
    USER_ACTION_TYPES.SET_TWO_STEP_VERIFICATION,
    updateLoginAuthentication
  );
}
export function* onFetchUserIpLocation() {
  yield takeLatest(USER_ACTION_TYPES.FETCH_USER_IP_LOCATION, getIPLocation);
}

export function* onPayNowDefault() {
  yield takeLatest(USER_ACTION_TYPES.PAY_NOW_DEFAULT, paymentDefault);
}
export function* onSendOTPCode() {
  yield takeLatest(USER_ACTION_TYPES.RESEND_CODE, resendOTP);
}

export function* userSagas() {
  yield all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignInSuccess),
    call(onSignOutStart),
    call(onSetIsLoading),
    call(onForgotPassword),
    call(onUpdateUser),
    call(onChangePassword),
    call(onCheckUnseen),
    call(onTwoStepVerify),
    call(onFetchUserIpLocation),
    call(onFetchUserDetails),
    call(onPayNowDefault),
    call(onSendOTPCode),
    call(onGetWallets),
  ]);
}
