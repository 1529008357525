function ComSvg() {
    return (<svg
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-name="com"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", width: 0, height: 0, overflow: "hidden" }}
        aria-hidden="true"
    >
        <symbol viewBox="0 0 24 24" id="Dex-g">
            <g>
                <path
                    d="M8.666 6.193L6.717 5.071 12.017 2l5.316 3.07-1.949 1.123-3.367-1.933-3.351 1.933zM18.72 8.126v2.26l1.949-1.122v-2.26L18.72 5.866l-1.948 1.122 1.948 1.138zM10.07 7.004l1.948 1.122 1.949-1.122-1.949-1.138-1.948 1.138zm7.264 1.933l-1.949-1.122-3.367 1.932-3.351-1.933-1.949 1.123v2.26l3.352 1.933v3.866l1.948 1.123 1.949-1.123V13.13l3.367-1.933v-2.26zm1.387 6.937l-3.351 1.933v2.26l5.316-3.07V10.87l-1.965 1.138v3.866zm-3.351.327l1.948-1.122v-2.276l-1.948 1.122v2.276zm-5.3 2.416v2.26L12.017 22l1.949-1.122v-2.26l-1.949 1.122-1.948-1.123zM3.35 9.264L5.3 10.387v-2.26l1.948-1.123-1.933-1.138-1.948 1.122v2.276H3.35zm1.964 2.744l-1.948-1.123v6.127l5.315 3.07v-2.26l-3.367-1.948v-3.866zm3.352 1.933l-1.949-1.123v2.26l1.949 1.123v-2.26z"
                    fill="url(#paint0_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.017"
                        y1={2}
                        x2="12.017"
                        y2={22}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F8D12F" />
                        <stop offset={1} stopColor="#F0B90B" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="Group-51714vipIcon">
            <g>
                <path fill="url(#paint0_linear)" d="M2 13h20v7H2z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 5H9v2h6V5zM7 7V3h10v4h5v6H2V7h5z"
                    fill="#76808F"
                />
                <path fill="#1E2026" d="M5 17h2v-4H5z" />
                <path fill="#1E2026" d="M17 17h2v-4h-2z" />
                <path fill="url(#paint1_linear)" d="M17 11h2v2h-2z" />
                <path fill="url(#paint2_linear)" d="M5 11h2v2H5z" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={13}
                        x2={12}
                        y2={20}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1={18}
                        y1={11}
                        x2={18}
                        y2={13}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1={6}
                        y1={11}
                        x2={6}
                        y2={13}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="ac-statement">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.29047 5.04291C7.90004 3.72197 9.91782 3 12 3V12L18.364 5.63604C19.8363 7.10838 20.7526 9.04568 20.9567 11.1178C21.1526 13.1071 20.6805 15.1026 19.619 16.7907L13.4142 10.5859L12 12.0002L18.3639 18.3641C17.3237 19.4042 16.0375 20.1802 14.6126 20.6125C12.62 21.2169 10.4796 21.1117 8.55586 20.3149C6.63216 19.5181 5.04426 18.0789 4.06272 16.2426C3.08118 14.4062 2.76673 12.2864 3.17295 10.2442C3.57916 8.202 4.68091 6.36384 6.29047 5.04291Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="academy-g">
            <g>
                <path
                    d="M7 14.002L3.248 10.25l9.75 4.25 9.75-4.25L19 13.998V17.5l-6 6-6-6v-3.498z"
                    fill="#76808F"
                />
                <path
                    d="M13.293 12.207l-6-6L3.25 10.25 13 20l9.75-9.75L13 .5 8.707 4.793l6 6-1.414 1.414z"
                    fill="url(#paint0_linear)"
                />
                <path d="M2.75 14L.5 16.25l2.25 2.25L5 16.25 2.75 14z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={13}
                        y1={20}
                        x2={13}
                        y2=".5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="accept-crypto-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.9645 18.8689H16.0355C17.2424 17.7714 18 16.1885 18 14.4287C18 11.115 15.3137 8.42871 12 8.42871C8.68629 8.42871 6 11.115 6 14.4287C6 16.1885 6.75763 17.7714 7.9645 18.8689ZM9.42896 14.4288L12 16.9999L14.5711 14.4288L12 11.8577L9.42896 14.4288Z"
                fill="url(#paint0_linear_12843_362365)"
            />
            <path d="M12 7L17 2H7L12 7Z" fill="#76808F" />
            <path
                d="M3 22V17H6.57739C6.91518 17.7111 7.38871 18.3452 7.9645 18.8688H16.0355C16.6113 18.3452 17.0848 17.7111 17.4226 17H21V22H3Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_12843_362365"
                    x1={12}
                    y1="8.42871"
                    x2={12}
                    y2="18.8689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="announcement-g">
            <path d="M3 12.9997L5 13V8.00031L3 8L3 12.9997Z" fill="#76808F" />
            <path
                d="M16.9999 6L5.00146 6L5.00146 15L16.9999 15L21 19L20.9999 2L16.9999 6Z"
                fill="url(#paint0_linear_6_14)"
            />
            <path d="M14 15H7V22H11V17H14V15Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_6_14"
                    x1="13.0007"
                    y1={19}
                    x2="13.0007"
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="api-g">
            <path d="M22 5H20V19H22V5Z" fill="#76808F" />
            <path d="M18 14L14 14L14 16L18 16L18 14Z" fill="#76808F" />
            <path d="M18 8L14 8L14 10L18 10L18 8Z" fill="#76808F" />
            <path
                d="M4 13.0002L1.00006 13.0002L1.00006 11.0002L4 11.0002L4 13.0002Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 12C3 15.866 6.13401 19 10 19H14V5H10C6.13401 5 3 8.13401 3 12Z"
                fill="url(#paint0_linear_1288_6847)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1288_6847"
                    x1="8.5"
                    y1={19}
                    x2="8.5"
                    y2={5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="asset-management-solution-g">
            <g clipPath="url(#clip0_42197_26753)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9874 4.3911L17.0535 5.58574L19.7249 5.4254L21.543 8.57458L20.0691 10.8069V13.1931L21.543 15.4254L19.7249 18.5746L17.0535 18.4143L14.9874 19.6089L13.7918 22H10.1555L8.95993 19.6089L6.89381 18.4142L4.22248 18.5746L2.4043 15.4254L3.87821 13.1931V10.8069L2.4043 8.5746L4.22248 5.42542L6.89381 5.58576L8.95993 4.3911L10.1555 2H13.7918L14.9874 4.3911ZM11.9975 6.54574C15.01 6.54574 17.4521 8.98782 17.4521 12.0003C17.4521 15.0127 15.01 17.4548 11.9975 17.4548C8.98505 17.4548 6.54297 15.0127 6.54297 12.0003C6.54297 8.98782 8.98505 6.54574 11.9975 6.54574Z"
                    fill="url(#paint0_linear_42197_26753)"
                />
                <path
                    d="M9.27002 12.0002L11.9973 9.27295L14.7246 12.0002L11.9973 14.7275L9.27002 12.0002Z"
                    fill="#76808F"
                />
                <circle
                    r="5.83333"
                    transform="matrix(1 0 0 -1 11.9998 12.0002)"
                    fill="#76808F"
                />
                <path
                    d="M12.6345 16.6668V15.6274C14.0746 15.3892 14.7459 14.4905 14.7459 13.4294C14.7459 12.325 14.0205 11.7078 12.5046 11.3613V9.56397C13.0243 9.68307 13.3708 9.92128 13.6307 10.2136L14.616 9.32576C14.1396 8.78439 13.5007 8.44873 12.6345 8.35128V7.3335H12.0174H11.4002V8.35128C10.0034 8.5137 9.25635 9.27162 9.25635 10.4085C9.25635 11.4588 9.91683 12.1734 11.541 12.5091V14.458C10.9346 14.3714 10.4257 14.079 10.0143 13.6459L9.03979 14.5338C9.58117 15.1293 10.3066 15.5732 11.4002 15.6707V16.6668H12.6345ZM10.7505 10.3652C10.7505 9.94293 10.9888 9.65059 11.541 9.54231V11.1448C10.9888 10.9932 10.7505 10.7767 10.7505 10.3652ZM13.2517 13.5485C13.2517 13.9599 13.0135 14.2956 12.5046 14.4364V12.7256C13.1218 12.9097 13.2517 13.2345 13.2517 13.5485Z"
                    fill="url(#paint1_linear_42197_26753)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_42197_26753"
                    x1="11.9737"
                    y1={22}
                    x2="11.9737"
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_42197_26753"
                    x1="11.8928"
                    y1="7.3335"
                    x2="11.8928"
                    y2="16.6668"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <clipPath id="clip0_42197_26753">
                    <rect width={24} height={24} fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="auto-invest">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.07114 6.34315C8.59945 4.81484 9.88191 4 12 4C16.4182 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2C9.14747 2 7.38599 3.19988 5.65693 4.92893L3.82846 6.7574L2 4.92893V10H7.07107L5.24268 8.17161L7.07114 6.34315Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9289 17.6569C15.4006 19.1852 14.1181 20 12 20C7.58175 20 4.00003 16.4183 4.00003 12H2.00003C2.00003 17.5228 6.47718 22 12 22C14.8525 22 16.614 20.8001 18.3431 19.0711L20.2071 17.2071L22 19V14H17L18.7929 15.7929L16.9289 17.6569Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 13.6L10.4 12L12 10.4L13.6 12L12 13.6Z"
                fill="url(#paint0_linear_8486_78758)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_8486_78758"
                    x1={12}
                    y1={16}
                    x2={12}
                    y2={8}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="bab-token-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 3.00012H12H14.5L21 9.50012V14.5001L14.5 21.0001H12H9.5L3 14.5001V9.50012L9.5 3.00012Z"
                fill="url(#paint0_linear_5116_130596)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 17.0001C14.7614 17.0001 17 14.7615 17 12.0001C17 9.2387 14.7614 7.00012 12 7.00012C9.23858 7.00012 7 9.2387 7 12.0001C7 14.7615 9.23858 17.0001 12 17.0001ZM8.71291 12.3573L11.0107 14.6611L15.1971 10.4806L14.3429 9.62646L11.0107 12.9588L9.56097 11.5092L8.71291 12.3573Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5116_130596"
                    x1={12}
                    y1="3.00012"
                    x2={12}
                    y2="21.0001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="bank-g">
            <g>
                <path fill="#76808F" d="M5 11h2v8H5z" />
                <path fill="#76808F" d="M9 11h2v8H9z" />
                <path fill="#76808F" d="M13 11h2v8h-2z" />
                <path fill="#76808F" d="M17 11h2v8h-2z" />
                <path d="M12 2l9 7v2H3V9l9-7z" fill="url(#paint0_linear)" />
                <path d="M3 22v-3h18v3H3z" fill="url(#paint1_linear)" />
                <circle cx={12} cy={7} r={2} fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={3}
                        y1={12}
                        x2={21}
                        y2={12}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1={3}
                        y1={12}
                        x2={21}
                        y2={12}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="battle-g">
            <g>
                <path d="M18 3h3v3L9.5 17.5l-3-3L18 3z" fill="#76808F" />
                <path d="M6 3H3v3l11.5 11.5 3-3L6 3z" fill="#76808F" />
                <path d="M15.5 17.5l2-2L22 20l-2 2-4.5-4.5z" fill="url(#paint0_linear)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.207 20.207l6-6-1.414-1.414-6 6 1.414 1.414z"
                    fill="url(#paint1_linear)"
                />
                <path d="M18 3h3v3L9.5 17.5l-3-3L18 3z" fill="url(#paint2_linear)" />
                <path d="M8.5 17.5l-2-2L2 20l2 2 4.5-4.5z" fill="#76808F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.793 20.207l-6-6 1.414-1.414 6 6-1.414 1.414z"
                    fill="#76808F"
                />
                <path d="M14 2l1 1-1 1-1-1 1-1z" fill="#76808F" />
                <path d="M3 9l1 1-1 1-1-1 1-1z" fill="#76808F" />
                <path d="M19 10.5L20.5 9l1.5 1.5-1.5 1.5-1.5-1.5z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="18.75"
                        y1="15.5"
                        x2="18.75"
                        y2={22}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="16.5"
                        y1="12.793"
                        x2="16.5"
                        y2="20.207"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1="13.75"
                        y1="17.5"
                        x2="13.75"
                        y2={3}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="blog-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0002 5.99988L2.00024 5.99988L2.00024 19.9999L22.0002 19.9999L22.0002 5.99988ZM4.00024 9.99988L20.0002 9.99988L20.0002 7.99988L4.00024 7.99988L4.00024 9.99988ZM12.0002 11.9999L20.0002 11.9999L20.0002 13.9999L12.0002 13.9999L12.0002 11.9999ZM12.0002 15.9999L16.0002 15.9999L16.0002 17.9999L12.0002 17.9999L12.0002 15.9999ZM4.00024 11.9999L10.0002 11.9999L10.0002 17.9999L4.00024 17.9999L4.00024 11.9999Z"
                fill="url(#paint0_linear_7_39)"
            />
            <path
                d="M2 3.99805L22 3.99805L22 5.99805L2 5.99805L2 3.99805Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_39"
                    x1="2.00024"
                    y1="12.9999"
                    x2="22.0002"
                    y2="12.9999"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="broker-g">
            <g>
                <path d="M10 14H3V3h11v7h-4v4z" fill="url(#paint0_linear)" />
                <path
                    d="M2 19.5L4.5 22 7 19.5 4.5 17 2 19.5z"
                    fill="url(#paint1_linear)"
                />
                <path d="M19.5 2L17 4.5 19.5 7 22 4.5 19.5 2z" fill="#76808F" />
                <path d="M10 21v-7h4v-4h7v11H10z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="8.5"
                        y1={14}
                        x2="8.5"
                        y2={3}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="4.5"
                        y1={22}
                        x2="4.5"
                        y2={17}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="capital-intro-g">
            <rect
                x={3}
                y={3}
                width={18}
                height="16.879"
                stroke="#76808F"
                strokeWidth={2}
            />
            <path
                d="M4 19.1121L17 22V6.77579L4 4V19.1121Z"
                fill="url(#paint0_linear_3074_72019)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3074_72019"
                    x1="10.5"
                    y1={22}
                    x2="10.5"
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="card-binance-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 20H2V4h20v16zM7 12H4v-2h3v2z"
                    fill="#76808F"
                />
                <path
                    d="M11.586 13.894l-1.787 1.789-1.787-1.789 1.787-1.788 1.787 1.788zm4.313-4.33l3.056 3.06 1.787-1.789-3.056-3.046L15.899 6l-1.775 1.789-3.056 3.046 1.787 1.789 3.044-3.06zm6.1 2.554l-1.774 1.776 1.774 1.777v-3.553zm-6.1 6.106l-3.056-3.06-1.775 1.79L14.112 20h3.574l3.056-3.059-1.787-1.776-3.056 3.059zm0-2.553l1.787-1.777-1.787-1.788-1.775 1.788 1.775 1.777z"
                    fill="url(#paint0_linear)"
                />
                <path d="M4 7.5L5.5 9 7 7.5 5.5 6 4 7.5z" fill="url(#paint1_linear)" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="15.006"
                        y1={20}
                        x2="15.006"
                        y2={6}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="5.5"
                        y1={9}
                        x2="5.5"
                        y2={6}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="card-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 5H3V8H21V5ZM21 10.5H3V19H21V10.5ZM6 13H11V15.5H6V13ZM15.5 13H13V15.5H15.5V13Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="card-g">
            <g>
                <path
                    transform="matrix(1 0 0 -1 2 20)"
                    fill="url(#paint0_linear)"
                    d="M0 0h20v16H0z"
                />
                <path fill="#76808F" d="M2 7h20v3H2z" />
                <path fill="#76808F" d="M17 16h3v2h-3z" />
                <path fill="#76808F" d="M4 16h11v2H4z" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={10}
                        y1={0}
                        x2={10}
                        y2={16}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="certified-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9113 22.8662L16.5923 19.9547H19.9539V16.593L22.8658 14.9117L21.1848 12.0002L22.8658 9.08865L19.9539 7.40739V4.04572H16.5924L14.9114 1.13418L11.9998 2.81511L9.08826 1.13418L7.40728 4.04572H4.04493V7.40791L1.13384 9.08864L2.8148 12.0002L1.13379 14.9118L4.04493 16.5925V19.9547H7.40732L9.0883 22.8662L11.9998 21.1853L14.9113 22.8662Z"
                fill="url(#paint0_linear_7_139)"
            />
            <path
                d="M10.4099 16.3701L6.58985 12.54L7.99976 11.1301L10.4099 13.54L15.9497 8L17.3699 9.42L10.4099 16.3701Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_139"
                    x1="11.9998"
                    y1="22.8662"
                    x2="11.9998"
                    y2="1.13418"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="charity-g">
            <g>
                <path
                    d="M13.22 2.556l-1.221 1.22-1.22-1.22a3.615 3.615 0 0 0-5.112 5.112L11.999 14l6.332-6.332a3.615 3.615 0 1 0-5.112-5.112z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M9.536 15.036L3 8.5v3.929a5 5 0 0 0 1.464 3.535L11 22.5v-3.929a5 5 0 0 0-1.464-3.536z"
                    fill="#76808F"
                />
                <path
                    d="M14.464 15.036L21 8.5v3.929a5 5 0 0 1-1.465 3.535L13 22.5v-3.929a5 5 0 0 1 1.464-3.536z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="11.999"
                        y1={14}
                        x2="11.999"
                        y2="1.497"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="chart-bar-g">
            <rect
                width={5}
                height={7}
                transform="matrix(1 0 0 -1 2 18)"
                fill="url(#paint0_linear)"
            />
            <path d="M2 18H7V21H2V18Z" fill="#76808F" />
            <rect
                width={5}
                height={8}
                transform="matrix(1 0 0 -1 17 16)"
                fill="url(#paint1_linear)"
            />
            <path d="M9.5 11H14.5V2H9.5V11Z" fill="url(#paint2_linear)" />
            <path d="M9.5 21H14.5V11H9.5V21Z" fill="#76808F" />
            <path d="M17 21H22V16H17V21Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="2.5"
                    y1={0}
                    x2="2.5"
                    y2={7}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="2.5"
                    y1={0}
                    x2="2.5"
                    y2={8}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1={12}
                    y1={11}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="chart-donut-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4123 19.1912C15.906 20.3267 14.0316 20.9998 12 20.9998C7.02944 20.9998 3 16.9704 3 11.9998C3 7.45343 6.37108 3.69438 10.75 3.08594V8.19612C9.15149 8.72112 7.99725 10.2257 7.99725 11.9998C7.99725 14.2105 9.78934 16.0026 12 16.0026C12.6466 16.0026 13.2574 15.8493 13.7981 15.577L17.4123 19.1912ZM19.1817 17.425C20.323 15.9165 21 14.0372 21 11.9998C21 7.45343 17.6289 3.69438 13.25 3.08594V8.19612C14.8485 8.72112 16.0028 10.2257 16.0028 11.9998C16.0028 12.6524 15.8466 13.2686 15.5695 13.8129L19.1817 17.425Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="chart-pie-g">
            <path
                d="M2 12C2 6.47715 6.47715 2 12 2V12L19.0711 19.0711C17.2614 20.8807 14.7614 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill="url(#paint0_linear_8674_47282)"
            />
            <path
                d="M19.0711 4.92883C22.9763 8.83408 22.9763 15.1657 19.0711 19.071L12 11.9999L19.0711 4.92883Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_8674_47282"
                    x1="10.5355"
                    y1={22}
                    x2="10.5355"
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="chat-g">
            <path d="M22 3V18H12L7 23V18H2V3H22Z" fill="url(#paint0_linear_7_93)" />
            <path d="M5 7L19 7L19 9L5 9L5 7Z" fill="#76808F" />
            <path d="M5 12L13 12L13 14L5 14L5 12Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_7_93"
                    x1={12}
                    y1={23}
                    x2={12}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="circled-arrow-right-g">
            <g>
                <circle
                    r={10}
                    transform="matrix(1 0 0 -1 12 12)"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M18.956 11.956l-5.5 5.544.044-4.544h-8v-2h8V6.5l5.456 5.456z"
                    fill="#2B3139"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={10}
                        y1={0}
                        x2={10}
                        y2={20}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F8D12F" />
                        <stop offset={1} stopColor="#F0B90B" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="cloud-g">
            <g>
                <path d="M1 16a7 7 0 0 1 7-7v7H1z" fill="#76808F" />
                <path
                    d="M8 7.077C8 4.335 10.239 2 13 2c2.762 0 5 2.335 5 5.077V16H8V7.077z"
                    fill="#76808F"
                />
                <path d="M7 19a8 8 0 1 1 16 0H7z" fill="url(#paint0_linear)" />
                <path fill="#76808F" d="M20 6h3v3h-3z" />
                <path fill="#F0B90B" d="M4 22h3v-3H4z" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={15}
                        y1={19}
                        x2={15}
                        y2={11}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="coin-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.0004 7.99992L16.0004 11.9999L12.0004 15.9999L8.00041 11.9999L12.0004 7.99992Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="coin-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
                fill="url(#paint0_linear_7_107)"
            />
            <path d="M7 12L12 7L17 12L12 17L7 12Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_7_107"
                    x1={12}
                    y1={22}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="coins-g">
            <path
                d="M18 6C18 4.34315 16.6569 3 15 3C13.3431 3 12 4.34315 12 6C12 7.65685 13.3431 9 15 9C16.6569 9 18 7.65685 18 6Z"
                fill="url(#paint0_linear_7_306)"
            />
            <path
                d="M9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13Z"
                fill="url(#paint1_linear_7_306)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 15.5C21 18.5376 18.5376 21 15.5 21C12.4624 21 10 18.5376 10 15.5C10 12.4624 12.4624 10 15.5 10C18.5376 10 21 12.4624 21 15.5ZM13 15.5L15.5 18L18 15.5L15.5 13L13 15.5Z"
                fill="url(#paint2_linear_7_306)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM5.5 7L7 5.5L8.5 7L7 8.5L5.5 7Z"
                fill="#76808F"
            />
            <path
                d="M21 9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9C19 8.44772 19.4477 8 20 8C20.5523 8 21 8.44772 21 9Z"
                fill="#76808F"
            />
            <path
                d="M9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.3431 4.34315 15 6 15C7.65685 15 9 16.3431 9 18Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_306"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_7_306"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_7_306"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="convert-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 4H20V9L15 4ZM12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6ZM12 14.4L14.4 12L12 9.6L9.6 12L12 14.4ZM4 20H9L4 15V20Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="convert-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 5a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 10l-3-3 3-3 3 3-3 3z"
                    fill="url(#paint0_linear)"
                />
                <path d="M15 3h6v6l-6-6z" fill="#76808F" />
                <path d="M9 21H3v-6l6 6z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={19}
                        x2={12}
                        y2={5}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 96 96" id="custody-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76 84H12V40H76V57L81 62L76 67V84ZM48 50V74H40V50H48Z"
                fill="url(#paint0_linear_7251_12)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 76C79.732 76 86 69.732 86 62C86 54.268 79.732 48 72 48C65.658 48 60.3009 52.217 58.5798 58L40 58V74H48L48 66L58.5798 66C60.3009 71.783 65.658 76 72 76ZM66 62L72 68L78 62L72 56L66 62Z"
                fill="#76808F"
            />
            <path d="M72 20L76 24L80 20L76 16L72 20Z" fill="#E6E8EA" />
            <path d="M85 79.5L87.5 82L90 79.5L87.5 77L85 79.5Z" fill="#E6E8EA" />
            <path d="M5 53H8V50H5V53Z" fill="#E6E8EA" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 18C37.9249 18 33 22.9249 33 29V40H25V29C25 18.5066 33.5066 10 44 10C54.4934 10 63 18.5066 63 29V30H55V29C55 22.9249 50.0751 18 44 18Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7251_12"
                    x1="46.5"
                    y1={84}
                    x2="46.5"
                    y2={40}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="customer-service-g">
            <path
                d="M6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10V17H20V10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10V17H6V10Z"
                fill="#76808F"
            />
            <path
                d="M12 18C13.3807 18 14.5 19.1193 14.5 20.5C14.5 21.8807 13.3807 23 12 23C10.6193 23 9.5 21.8807 9.5 20.5C9.5 19.1193 10.6193 18 12 18Z"
                fill="#76808F"
            />
            <path
                d="M0.5 13.5C0.5 15.433 2.067 17 4 17V10C2.067 10 0.5 11.567 0.5 13.5Z"
                fill="url(#paint0_linear_7_85)"
            />
            <path
                d="M23.5 13.5C23.5 15.433 21.933 17 20 17V10C21.933 10 23.5 11.567 23.5 13.5Z"
                fill="url(#paint1_linear_7_85)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_85"
                    x1={12}
                    y1={17}
                    x2={12}
                    y2={10}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_7_85"
                    x1={12}
                    y1={17}
                    x2={12}
                    y2={10}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="defi-wallet-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.50007 8C4.11936 8 3.00007 6.88071 3.00007 5.5L3.00004 11.25L6.25 11.2499V14.5H3.00003L3 17.75H6.25L6.25001 21H21.0001V8H9.50001V11.2499H6.25V8H5.50007ZM6.25 17.75V14.5L9.50001 14.5V17.75L6.25 17.75Z"
                fill="url(#paint0_linear_3881_100014)"
            />
            <path
                d="M5.5 3H21.0002V8H5.5C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3Z"
                fill="#76808F"
            />
            <path d="M21 12V17H16V12H21Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_3881_100014"
                    x1={12}
                    y1="5.5"
                    x2={12}
                    y2={21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="deposit-new-f">
            {" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 3H10.5V9.94961L10.197 9.94695L6.03809 9.98779L12.0129 15.9626L17.9878 9.98779L13.8115 9.9787L13.5 9.97597V3ZM3.5 14H6.5V18H17.5V14H20.5V18V21H3.5V18V14Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 25 24" id="double-no-touch-f">
            {" "}
            <path
                d="M3.75073 2.99988H21.75V5.99988H3.75073V2.99988Z"
                fill="currentColor"
            />{" "}
            <path
                d="M3.75073 17.9999H21.75V20.9999H3.75073V17.9999Z"
                fill="currentColor"
            />{" "}
            <path
                d="M15.2501 12.3831L17.6895 9.94373L15.7501 8.00434H21.7501V14.0043L19.8108 12.0651L15.2554 16.6205L10.7756 12.1628L5.84837 17.09L3.72705 14.9687L10.7703 7.92542L15.2501 12.3831Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 25 24" id="double-no-touch-g">
            <path
                d="M9.12535 6.83264L14.7099 12.359L17.631 9.4378L15.1934 7.00012L22.1934 7.00012V14.0001L19.7524 11.5591L14.721 16.5906L9.13645 11.0642L2.1936 18.0071V13.7645L9.12535 6.83264Z"
                fill="url(#paint0_linear_3376_58016)"
            />
            <path d="M22.1936 2H2.1936V4H22.1936V2Z" fill="#76808F" />
            <path d="M22.1936 19.9999H2.1936V21.9999H22.1936V19.9999Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_3376_58016"
                    x1="12.1935"
                    y1="6.83264"
                    x2="12.1935"
                    y2="18.0071"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-eth2">
            <circle cx={12} cy={12} r={10} fill="url(#paint0_linear_11883_82856)" />
            <path d="M12 15.5365V19.0194L16.2867 13.0002L12 15.5365Z" fill="#76808F" />
            <g style={{ mixBlendMode: "soft-light" }} opacity="0.6">
                <path
                    d="M12 15.5365V19.0194L16.2867 13.0002L12 15.5365Z"
                    fill="url(#paint1_linear_11883_82856)"
                />
            </g>
            <path d="M12 10.1616V14.6448L16.2867 12.1085L12 10.1616Z" fill="#76808F" />
            <g style={{ mixBlendMode: "soft-light" }} opacity="0.6">
                <path
                    d="M12 10.1616V14.6448L16.2867 12.1085L12 10.1616Z"
                    fill="url(#paint2_linear_11883_82856)"
                />
            </g>
            <path d="M12 4.99969V10.1616L16.2867 12.1085L12 4.99969Z" fill="#76808F" />
            <path
                d="M11.9996 15.5365V19.0194L7.71289 13.0002L11.9996 15.5365Z"
                fill="#76808F"
            />
            <path
                d="M11.9996 10.1616V14.6448L7.71289 12.1085L11.9996 10.1616Z"
                fill="#76808F"
            />
            <g style={{ mixBlendMode: "soft-light" }} opacity="0.2">
                <path
                    d="M11.9996 10.1616V14.6448L7.71289 12.1085L11.9996 10.1616Z"
                    fill="url(#paint3_linear_11883_82856)"
                />
            </g>
            <path
                d="M11.9996 4.99969V10.1616L7.71289 12.1085L11.9996 4.99969Z"
                fill="#76808F"
            />
            <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
                <path
                    d="M12.0004 4.99969V10.1616L16.2871 12.1085L12.0004 4.99969Z"
                    fill="url(#paint4_linear_11883_82856)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11883_82856"
                    x1={12}
                    y1={2}
                    x2={12}
                    y2={22}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8D33A" />
                    <stop offset={1} stopColor="#F0B90B" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11883_82856"
                    x1="14.1434"
                    y1="13.0002"
                    x2="14.1434"
                    y2="19.0194"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11883_82856"
                    x1="14.1434"
                    y1="10.1616"
                    x2="14.1434"
                    y2="14.6448"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11883_82856"
                    x1="9.85625"
                    y1="10.1616"
                    x2="9.85625"
                    y2="14.6448"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11883_82856"
                    x1="14.1438"
                    y1="4.99969"
                    x2="14.1438"
                    y2="12.1085"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 6a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 8.5L9.5 12 12 9.5l2.5 2.5-2.5 2.5z"
                    fill="url(#paint0_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2c-.406 0-.807.024-1.2.071l.237 1.986m0 0a8 8 0 1 1-4.694 13.6L4.93 19.07A9.972 9.972 0 0 0 12 22"
                    fill="#76808F"
                />
                <path
                    d="M7.197 5.602a7.99 7.99 0 0 1 1.664-.963L8.075 2.8a9.99 9.99 0 0 0-2.08 1.204l1.202 1.599z"
                    fill="#76808F"
                />
                <path
                    d="M4.64 8.86a7.99 7.99 0 0 1 .962-1.663L4.003 5.995A9.99 9.99 0 0 0 2.8 8.075l1.84.786z"
                    fill="#76808F"
                />
                <path
                    d="M4 12c0-.326.02-.648.057-.963l-1.986-.238a10.094 10.094 0 0 0-.02 2.223l1.99-.202A8.107 8.107 0 0 1 4 12z"
                    fill="#76808F"
                />
                <path
                    d="M5.17 16.168a7.958 7.958 0 0 1-.706-1.476l-1.884.672c.232.65.53 1.268.884 1.847l1.706-1.043z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={18}
                        x2={12}
                        y2={6}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-lending-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5572 6.26976L16.5 3H7.5L10.4428 6.26976C7.3263 6.97773 5 9.76516 5 13.0959V20.9709H19V13.0959C19 9.76516 16.6737 6.97773 13.5572 6.26976ZM12 17.0002C14.2091 17.0002 16 15.2094 16 13.0002C16 10.7911 14.2091 9.00023 12 9.00023C9.79086 9.00023 8 10.7911 8 13.0002C8 15.2094 9.79086 17.0002 12 17.0002ZM9.87868 12.9998L12 10.8785L14.1213 12.9998L12 15.1211L9.87868 12.9998Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-lending-g">
            {" "}
            <path
                d="M11.0552 5.0552L7 1H17L12.9448 5.0552C16.9178 5.52265 20 8.90138 20 13V22H4V13C4 8.90138 7.0822 5.52265 11.0552 5.0552Z"
                fill="#76808F"
            />{" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C15.3137 7 18 9.68629 18 13C18 16.3137 15.3137 19 12 19C8.68629 19 6 16.3137 6 13C6 9.68629 8.68629 7 12 7ZM12 10.5L9.5 13L12 15.5L14.5 13L12 10.5Z"
                fill="url(#paint0_linear_1_11)"
            />{" "}
            <defs>
                {" "}
                <linearGradient
                    id="paint0_linear_1_11"
                    x1={12}
                    y1={19}
                    x2={12}
                    y2={7}
                    gradientUnits="userSpaceOnUse"
                >
                    {" "}
                    <stop stopColor="#F0B90B" /> <stop offset={1} stopColor="#F8D33A" />{" "}
                </linearGradient>{" "}
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-savings-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 6C22 3.79086 20.2091 2 18 2C15.7909 2 14 3.79086 14 6C14 8.20914 15.7909 10 18 10C20.2091 10 22 8.20914 22 6ZM16.3335 6.00008L18.0002 7.66675L19.6668 6.00008L18.0002 4.33341L16.3335 6.00008Z"
                fill="url(#paint0_linear_7105_37452)"
            />
            <path
                d="M3.5438 16H2V22H22V16H14.4557C13.9077 17.1939 12.9794 18.177 11.8259 18.7941H6.17363C5.02009 18.177 4.09176 17.1939 3.5438 16Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99976 7.5C12.3135 7.5 14.9998 10.1863 14.9998 13.5C14.9998 15.7919 13.7147 17.7836 11.8259 18.7941H6.17363C4.28477 17.7836 2.99976 15.7919 2.99976 13.5C2.99976 10.1863 5.68605 7.5 8.99976 7.5ZM8.99976 16L6.49976 13.5L8.99976 11L11.4998 13.5L8.99976 16Z"
                fill="url(#paint1_linear_7105_37452)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7105_37452"
                    x1={18}
                    y1={2}
                    x2={18}
                    y2={10}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_7105_37452"
                    x1="8.99976"
                    y1="7.5"
                    x2="8.99976"
                    y2="18.7941"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="earn-staking-g">
            <path
                d="M18 6C18 4.34315 16.6569 3 15 3C13.3431 3 12 4.34315 12 6C12 7.65685 13.3431 9 15 9C16.6569 9 18 7.65685 18 6Z"
                fill="url(#paint0_linear_7105_37458)"
            />
            <path
                d="M9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13Z"
                fill="url(#paint1_linear_7105_37458)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 15.5C21 18.5376 18.5376 21 15.5 21C12.4624 21 10 18.5376 10 15.5C10 12.4624 12.4624 10 15.5 10C18.5376 10 21 12.4624 21 15.5ZM13 15.5L15.5 18L18 15.5L15.5 13L13 15.5Z"
                fill="url(#paint2_linear_7105_37458)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM5.5 7L7 5.5L8.5 7L7 8.5L5.5 7Z"
                fill="#76808F"
            />
            <path
                d="M21 9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9C19 8.44772 19.4477 8 20 8C20.5523 8 21 8.44772 21 9Z"
                fill="#76808F"
            />
            <path
                d="M9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.3431 4.34315 15 6 15C7.65685 15 9 16.3431 9 18Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7105_37458"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_7105_37458"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_7105_37458"
                    x1={14}
                    y1={21}
                    x2={14}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="eco-trust-s24">
            <g>
                <path
                    d="M19.583 14.562l-7 5.604-3.966-3.088-3.034-2.516L5 4.61h3.617l3.966-1.944L16.55 4.61h3.617l-.584 9.95z"
                    fill="url(#paint0_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.583"
                        y1="2.667"
                        x2="12.583"
                        y2="20.166"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F8D12F" />
                        <stop offset={1} stopColor="#F0B90B" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="edit-g">
            <path d="M3 21H21V6L18 3H3V21Z" fill="url(#paint0_linear_7_44)" />
            <path
                d="M6 18V13.5L15.0855 4.4145L19.5855 8.9145L10.5 18H6Z"
                fill="#76808F"
            />
            <path
                d="M20.9997 7.50029L23 5.5L18.5 1L16.4997 3.00029L20.9997 7.50029Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_44"
                    x1={12}
                    y1={21}
                    x2={12}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="educate-g">
            <path
                d="M8 4H2V20H12V8C12 5.79086 10.2091 4 8 4Z"
                fill="url(#paint0_linear_9944_23638)"
            />
            <path d="M8 16H2V20H12C12 17.7909 10.2091 16 8 16Z" fill="#76808F" />
            <path
                d="M16 4H22V20H12V8C12 5.79086 13.7909 4 16 4Z"
                fill="url(#paint1_linear_9944_23638)"
            />
            <path d="M16 16H22V20H12C12 17.7909 13.7909 16 16 16Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_9944_23638"
                    x1={7}
                    y1={20}
                    x2={7}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_9944_23638"
                    x1={17}
                    y1={20}
                    x2={17}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 96 96" id="execution-otc-g">
            <path opacity="0.3" d="M0 3.5L3.5 7L7 3.5L3.5 0L0 3.5Z" fill="#AEB4BC" />
            <path
                opacity="0.3"
                d="M83.9995 33.7532L88.4995 38.2532L92.9995 33.7532L88.4995 29.2532L83.9995 33.7532Z"
                fill="#AEB4BC"
            />
            <path opacity="0.3" d="M3 66.751H12V57.751H3V66.751Z" fill="#AEB4BC" />
            <circle
                r="10.5"
                transform="matrix(1 -2.18557e-08 -2.18557e-08 -1 35.0039 35.0037)"
                fill="#F8D33A"
            />
            <circle
                r="7.1842"
                transform="matrix(1 -2.18557e-08 -2.18557e-08 -1 35.004 35.0048)"
                fill="url(#paint0_linear_47958_27009)"
            />
            <path
                d="M31.1353 35.0037L35.0037 38.8721L38.8721 35.0037L35.0037 31.1352L31.1353 35.0037Z"
                fill="#F0B90B"
            />
            <circle
                r="10.5"
                transform="matrix(1 -2.18557e-08 -2.18557e-08 -1 60.9966 60.9964)"
                fill="#F8D33A"
            />
            <circle
                r="7.1842"
                transform="matrix(1 -2.18557e-08 -2.18557e-08 -1 60.9967 60.9974)"
                fill="url(#paint1_linear_47958_27009)"
            />
            <path
                d="M57.1279 60.9963L60.9963 64.8647L64.8648 60.9963L60.9963 57.1279L57.1279 60.9963Z"
                fill="#F0B90B"
            />
            <path
                d="M83.9995 19.002L64.9976 0V12.0025H12.0029V47.501H19.0029V19.0025L83.9995 19.002Z"
                fill="url(#paint2_linear_47958_27009)"
            />
            <path
                d="M31.002 83.9976H83.9966V48.499H76.9966V76.9976L12 76.9981L31.002 96.0001V83.9976Z"
                fill="url(#paint3_linear_47958_27009)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5156 65.5348L65.5353 25.5151L70.4851 30.4649L30.4654 70.4846L25.5156 65.5348Z"
                fill="url(#paint4_linear_47958_27009)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_47958_27009"
                    x1="7.1842"
                    y1={0}
                    x2="7.1842"
                    y2="14.3684"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_47958_27009"
                    x1="7.1842"
                    y1={0}
                    x2="7.1842"
                    y2="14.3684"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_47958_27009"
                    x1="48.0012"
                    y1={0}
                    x2="48.0012"
                    y2="47.501"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#929AA5" />
                    <stop offset={1} stopColor="#76808F" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_47958_27009"
                    x1="47.9983"
                    y1="48.499"
                    x2="47.9983"
                    y2="96.0001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_47958_27009"
                    x1="48.0003"
                    y1="25.5151"
                    x2="48.0003"
                    y2="70.4846"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#929AA5" />
                    <stop offset={1} stopColor="#76808F" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="fan-token">
            <path d="M2 16H8V20H2V16Z" fill="#76808F" />
            <path d="M16 13H22V20H16V13Z" fill="#76808F" />
            <path d="M8 20H16V10H8V20Z" fill="url(#paint0_linear)" />
            <path d="M9 5L12 2L15 5L12 8L9 5Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={12}
                    y1={20}
                    x2={12}
                    y2={10}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 25" id="faq-g">
            <path
                d="M20 2.5V18.5H14L9 23.5V18.5H4V2.5H20Z"
                fill="url(#paint0_linear_2_187)"
            />
            <path d="M13 14.5V16.5H11V14.5H13Z" fill="#76808F" />
            <path
                d="M10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8C13.5 8.41449 13.3332 8.78812 13.0607 9.06066L11 11.1213V13H13V11.9497L14.4749 10.4749C15.1072 9.84259 15.5 8.96622 15.5 8C15.5 6.067 13.933 4.5 12 4.5C10.067 4.5 8.5 6.067 8.5 8V8.5H10.5V8Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2_187"
                    x1={12}
                    y1="23.5"
                    x2={12}
                    y2="2.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-coin-m-g">
            <g>
                <path d="M6 2h15v17a2 2 0 0 1-2 2H4l2-2V2z" fill="url(#paint0_linear)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 14a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm0-2.864L11.864 9.5 13.5 7.864 15.136 9.5 13.5 11.136z"
                    fill="#76808F"
                />
                <path d="M9 16h9v2H9v-2z" fill="#76808F" />
                <path d="M4 21a2 2 0 0 0 2-2v-7H2v7a2 2 0 0 0 2 2z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.5"
                        y1={21}
                        x2="12.5"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 3H20V16C20 18.2091 18.2091 20 16 20H7.5C5.567 20 4 18.433 4 16.5V9H8.5V3ZM11 14.5056V17.0056L17.5 17.0056V14.5056H11ZM8.5 11.5H6.5V16.5C6.5 17.0523 6.94772 17.5 7.5 17.5C8.05228 17.5 8.5 17.0523 8.5 16.5V11.5ZM12.5001 6.00562H17.5001V11.0056L15.8846 9.3901L13.2691 12.0056L11.5013 10.2379L14.1168 7.62233L12.5001 6.00562Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-g">
            <g>
                <path d="M6 2h15v17a2 2 0 0 1-2 2H4l2-2V2z" fill="url(#paint0_linear)" />
                <path d="M4 21a2 2 0 0 0 2-2v-7H2v7a2 2 0 0 0 2 2z" fill="#76808F" />
                <path d="M9 16h9v2H9v-2z" fill="#76808F" />
                <path
                    d="M18 5v7.5l-3.043-3.043L10.414 14 9 12.586l4.543-4.543L10.5 5H18z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.5"
                        y1={21}
                        x2="12.5"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-leveraged-token-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10zm-10 7l6-6H6l6 6z"
                    fill="url(#paint0_linear)"
                />
                <path d="M18 11l-6-6-6 6h12z" fill="#76808F" />
                <path d="M18 13l-6 6-6-6h12z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={22}
                        x2={12}
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset=".333" stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#FBDA3C" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-options-european-f">
            {" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 3H8.5V9H4V16.5C4 18.433 5.567 20 7.5 20H16C18.2091 20 20 18.2091 20 16V3ZM6.5 11.5H8.5V16.5C8.5 17.0523 8.05228 17.5 7.5 17.5C6.94772 17.5 6.5 17.0523 6.5 16.5V11.5ZM16.8637 15.25H11.6611V7.75H16.8637V9.44977H13.536V10.6141H16.3898V12.3035H13.536V13.5502H16.8637V15.25Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-options-european-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 2H6V19C6 20.1046 5.10457 21 4 21H19C20.1046 21 21 20.1046 21 19V2ZM11.5 10.5L11.5 7L14 7L10.5 3.5L7 7L9.5 7L9.5 10.5H11.5ZM11.5 12.5H9.5V16H7L10.5 19.5L14 16H11.5L11.5 12.5ZM14.3159 15H19.1717V13.4135H16.0658V12.25H18.7293V10.6731H16.0658V9.58645H19.1717V8H14.3159V15Z"
                fill="url(#paint0_linear_1107_26006)"
            />
            <path
                d="M11.5 7L11.5 10.5H9.5L9.5 7L7 7L10.5 3.5L14 7L11.5 7Z"
                fill="#76808F"
            />
            <path
                d="M9.5 12.5H11.5L11.5 16H14L10.5 19.5L7 16H9.5V12.5Z"
                fill="#76808F"
            />
            <path
                d="M14.3159 15H19.1717V13.4135H16.0658V12.25H18.7293V10.6731H16.0658V9.58645H19.1717V8H14.3159V15Z"
                fill="#76808F"
            />
            <path
                d="M6 19C6 20.1046 5.10457 21 4 21C2.89543 21 2 20.1046 2 19V12H6V19Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1107_26006"
                    x1="12.5"
                    y1={2}
                    x2="12.5"
                    y2={21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.333333" stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#FBDA3C" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-options-vanilla-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 3H8.5V9H4V16.5C4 18.433 5.567 20 7.5 20H16C18.2091 20 20 18.2091 20 16V3ZM6.5 11.5H8.5V16.5C8.5 17.0523 8.05228 17.5 7.5 17.5C6.94772 17.5 6.5 17.0523 6.5 16.5V11.5ZM17.7194 8.5L15.5479 15.5H14.003H12.4328L10.2805 8.5H12.4964L14.003 14.0559L15.5479 8.5H17.7194Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-options-vanilla-g">
            <g>
                <path d="M6 2h15v17a2 2 0 0 1-2 2H4l2-2V2z" fill="url(#paint0_linear)" />
                <path d="M4 21a2 2 0 0 0 2-2v-7H2v7a2 2 0 0 0 2 2z" fill="#76808F" />
                <path d="M9.5 10.5h2V7H14l-3.5-3.5L7 7h2.5v3.5z" fill="#76808F" />
                <path d="M11.5 12.5h-2V16H7l3.5 3.5L14 16h-2.5v-3.5z" fill="#76808F" />
                <path
                    d="M15.846 16h1.298L19.5 9h-1.817l-1.178 3.913L15.307 9H13.5l2.346 7z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.5"
                        y1={21}
                        x2="12.5"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset=".333" stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#FBDA3C" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="futures-usdt-m-g">
            <g>
                <path d="M6 2h15v17a2 2 0 0 1-2 2H4l2-2V2z" fill="url(#paint0_linear)" />
                <path
                    d="M14.5 18v-1.448c2.006-.332 2.941-1.583 2.941-3.061 0-1.539-1.01-2.398-3.122-2.88V8.106c.724.166 1.207.497 1.569.905l1.372-1.237c-.664-.754-1.553-1.222-2.76-1.357V5h-1.72v1.418c-1.945.226-2.985 1.282-2.985 2.865 0 1.463.92 2.458 3.182 2.926v2.714c-.845-.12-1.553-.527-2.127-1.13l-1.357 1.236c.754.83 1.765 1.448 3.288 1.584V18h1.72zm-2.624-8.777c0-.588.332-.996 1.1-1.146v2.232c-.768-.212-1.1-.513-1.1-1.086zm3.484 4.434c0 .573-.332 1.04-1.04 1.236V12.51c.859.257 1.04.71 1.04 1.147z"
                    fill="#76808F"
                />
                <path d="M4 21a2 2 0 0 0 2-2v-7H2v7a2 2 0 0 0 2 2z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.5"
                        y1={21}
                        x2="12.5"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 40 40" id="gift-card">
            <path d="M33 30H7V10H33V30Z" fill="url(#paint0_linear)" />
            <path
                d="M14.8 10H17.4V16.9825L20.7615 13.7504L22.6 15.5182L19.2388 18.75H33V21.25H19.2381L22.5997 24.4823L20.7611 26.25L17.4 23.0182V30H14.8V23.0183L11.4394 26.25L9.60101 24.4823L12.9621 21.25H7V18.75H12.9613L9.59996 15.5177L11.4385 13.7499L14.8 16.9825V10Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={20}
                    y1={30}
                    x2={20}
                    y2={10}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="historical-market-date-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 18H22V4H20L13 11H8L4 15V18Z"
                fill="url(#paint0_linear_1535_81)"
            />
            <path d="M4 4H2V20L22 20V18L4 18V4Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_1535_81"
                    x1={13}
                    y1={18}
                    x2={13}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="institutional-home-g">
            <path
                d="M21 11L20.9999 21H15V13H9V21H3V11L12 2L21 11Z"
                fill="url(#paint0_linear_1330_2488)"
            />
            <rect
                width={6}
                height={8}
                transform="matrix(1 0 0 -1 9 21)"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1330_2488"
                    x1={12}
                    y1={21}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="labs-g">
            <g>
                <path
                    d="M10 17.171a6.002 6.002 0 0 1 4-5.658V7h4v4.513c2.33.823 4 3.046 4 5.658H10z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M11.586 5.828L8.757 3l-6 6h5.657l3.172-3.172z"
                    fill="url(#paint1_linear)"
                />
                <path d="M14.272 1l-2 2 2 2 2-2-2-2z" fill="#76808F" />
                <path d="M22 5h-2v2h2V5z" fill="#76808F" />
                <path
                    d="M8.414 9l-4 4a2 2 0 0 1-2.828-2.828L2.757 9h5.657z"
                    fill="#76808F"
                />
                <path d="M16 23.171a6 6 0 0 0 6-6H10a6 6 0 0 0 6 6z" fill="#76808F" />
                <path d="M10.272 10l1-1 1 1-1 1-1-1z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={16}
                        y1="17.171"
                        x2={16}
                        y2={7}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="7.172"
                        y1={9}
                        x2="7.172"
                        y2={3}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="launchpad-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0107 3.99117L13.5965 3.99117L9.40887 8.1788L4.4652 8.23405L1.64419 11.055L6.23463 15.6459L9.76263 12.1179L11.884 14.2392L8.35585 17.7673L12.9491 22.361L15.7701 19.54L15.8231 14.593L20.0107 10.4054L20.0107 3.99117ZM8.34759 17.7756L6.22627 15.6543L4.107 17.7736L6.22832 19.8949L8.34759 17.7756Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="launchpad-g">
            <g>
                <path d="M15.914 14.5v5L12 23.415l-2.5-2.5 6.414-6.415z" fill="#76808F" />
                <path d="M.586 12l4-4h5l-6.5 6.5-2.5-2.5z" fill="#76808F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 2h-3.929a6 6 0 0 0-4.242 1.757L3.086 14.5 9.5 20.914l10.743-10.743A6 6 0 0 0 22 5.93v-3.93zm-6.5 4a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z"
                    fill="url(#paint0_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.293 19.293l7-7 1.414 1.414-7 7-1.414-1.414z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.543"
                        y1="20.914"
                        x2="12.543"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="leaderboard-g">
            <path
                d="M6 5H2V10.4104L6 14.4624V11.6344L4 9.58957V7H6V5Z"
                fill="#76808F"
            />
            <path
                d="M11.0001 17V20H6.00012V22H18.0001V20H13.0001V17H11.0001Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0001 14.4624L22 10.4104V5H18.0001V14.4624ZM20 9.58957L18.0001 11.6343V7H20V9.58957Z"
                fill="#76808F"
            />
            <path
                d="M6 2V14.4853L7.75736 16.2426C8.84315 17.3284 10.3431 18 12 18C13.6569 18 15.1569 17.3284 16.2426 16.2426L18 14.4853V2H6Z"
                fill="url(#paint0_linear_4968_126172)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_4968_126172"
                    x1={12}
                    y1={18}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="liquid-swap-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 5.714L10.286 12 12 10.286 13.714 12 12 13.714z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M1 11c0-1.38.595-2.595 1.5-3.5C3.405 6.595 4.62 6 6 6v8.5L3.5 17 1 14.5V11z"
                    fill="#76808F"
                />
                <path
                    d="M23 13c0 1.38-.595 2.595-1.5 3.5-.905.905-2.12 1.5-3.5 1.5V9.5L20.5 7 23 9.5V13z"
                    fill="#76808F"
                />
                <path
                    d="M2.464 7.535c.905-.905 2.12-1.5 3.5-1.5h9v-5h-3.93A5 5 0 0 0 7.5 2.5L2.464 7.535z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M21.5 16.5c-.905.905-2.12 1.5-3.5 1.5H9v5h3.929a5 5 0 0 0 3.535-1.465L21.5 16.5z"
                    fill="url(#paint2_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={16}
                        x2={12}
                        y2={8}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="8.714"
                        y1="7.535"
                        x2="8.714"
                        y2="1.035"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1="15.25"
                        y1="16.5"
                        x2="15.25"
                        y2={23}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="list-grid-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4V11H11V4H4ZM13 11V4H20V11H13ZM16.5 20.7426L12.2573 16.5L16.5 12.2574L20.7426 16.5L16.5 20.7426ZM4 13H11V20H4V13Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 60 60" id="live">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.6829 7.31707H7.31707V52.6829H52.6829V7.31707ZM0 0V60H60V0H0Z"
                fill="url(#paint0_linear_191_5623)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.561 14.6342H24.878V45.3659H17.561V14.6342ZM27.8049 21.9512H35.122V38.0488H27.8049V21.9512ZM45.366 26.3414H38.0489V33.6585H45.366V26.3414Z"
                fill="url(#paint1_linear_191_5623)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_191_5623"
                    x1="37.3171"
                    y1="47.561"
                    x2="53.8636"
                    y2="3.40909"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_191_5623"
                    x1="32.9269"
                    y1="18.2927"
                    x2="24.3599"
                    y2="42.7699"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#969FAB" />
                    <stop offset={1} stopColor="#76808E" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="lock-close-g">
            <path d="M4 22H20V9H4V22Z" fill="url(#paint0_linear_7_182)" />
            <path
                d="M9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V9H17V6C17 3.23858 14.7614 1 12 1C9.23858 1 7 3.23858 7 6V9H9V6Z"
                fill="#76808F"
            />
            <path d="M13 19V12H11V19H13Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_7_182"
                    x1={12}
                    y1={22}
                    x2={12}
                    y2={9}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="margin-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7678 7.01874L5.82854 19.958L4.06077 18.1902L17 5.25098L17.8839 6.13486L18.7678 7.01874Z"
                fill="currentColor"
            />
            <path d="M12 4H20V12L12 4Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11ZM7.5 5.96875L5.96875 7.5L7.5 9.03125L9.03125 7.50017L7.5 5.96875Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20ZM16.5 14.9688L14.9688 16.5L16.5 18.0312L18.0312 16.5002L16.5 14.9688Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="margin-g">
            <g>
                <path
                    d="M21 3v8l-3.293-3.293L4.414 21 3 19.586 16.293 6.293 13 3h8z"
                    fill="url(#linerxxxx0)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 11a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm0-6.5l-2 2 2 2 2-2-2-2z"
                    fill="url(#linerxxxx1)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 22a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm0-6.5l-2 2 2 2 2-2-2-2z"
                    fill="url(#linerxxxx2)"
                />
                <defs>
                    <linearGradient
                        id="linerxxxx0"
                        x1={12}
                        y1={3}
                        x2={12}
                        y2={21}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#929AA5" />
                        <stop offset={1} stopColor="#76808F" />
                    </linearGradient>
                    <linearGradient
                        id="linerxxxx1"
                        x1="6.5"
                        y1={11}
                        x2="6.5"
                        y2={2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="linerxxxx2"
                        x1="17.5"
                        y1={22}
                        x2="17.5"
                        y2={13}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="market-overview-g">
            <path
                d="M16 1H18V4H21V18H18V22H16V18H13V4H16V1Z"
                fill="url(#paint0_linear_1994_24364)"
            />
            <path d="M6 7H8V10H11V18H8V22H6V18H3V10H6V7Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_1994_24364"
                    x1={17}
                    y1={22}
                    x2={17}
                    y2={1}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="market-s24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 4H14.5V19H9.5V4ZM3 11H7.5V19H3V11ZM21 9H16.5V19H21V9Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="news-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.01379 21L20.0005 21C21.1051 21 22.0005 20.1046 22.0005 19V3H6.00048L6.00004 18.8572C6.00004 20.0358 5.11203 20.9921 4.01379 21ZM8.00048 11.0005H13.0005V17.0005H8.00048V11.0005ZM20.0005 11.0005H15.0005V13.0005H20.0005V11.0005ZM15.0005 15.0005H20.0005V17.0005H15.0005V15.0005Z"
                fill="url(#paint0_linear_7_31)"
            />
            <path
                d="M18.0004 8.00049L10.0004 8.00049V6.00049H18.0004V8.00049Z"
                fill="#76808F"
            />
            <path
                d="M2 18.8572V9.00005L6 9.00005L6 18.8572C6 20.0407 5.10457 21 4 21C2.89543 21 2 20.0407 2 18.8572Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_31"
                    x1="16.5004"
                    y1="13.5"
                    x2="16.5004"
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="nft-marketplace-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.979 19.9844L15.979 15.9844L19.979 11.9844L15.979 7.98438L11.9946 4H20V20H4V12.0054L7.97897 15.9844L11.979 19.9844ZM7.97897 7.98438V15.9844H15.979V7.98438H7.97897ZM7.97897 7.98438L11.9633 4H4V11.9633L7.97897 7.98438ZM9.47893 11.9844L11.9789 9.48438L14.4789 11.9844L11.9789 14.4844L9.47893 11.9844Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="nft-marketplace-g">
            <rect
                width={18}
                height={18}
                transform="matrix(1 0 0 -1 3 21)"
                fill="url(#paint0_linear_108_52)"
            />
            <path d="M4 12L12 4L20 12L12 20L4 12Z" fill="#76808F" />
            <path d="M8 8V16H16V8H8Z" fill="url(#paint1_linear_108_52)" />
            <path d="M9.5 12L12 14.5L14.5 12L12 9.5L9.5 12Z" fill="#76808F" />
            <path d="M5 16L8 19H5V16Z" fill="#76808F" />
            <path d="M5 8L8 5H5V8Z" fill="#76808F" />
            <path d="M16 5H19V8L16 5Z" fill="#76808F" />
            <path d="M16 19H19V16L16 19Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_108_52"
                    x1={9}
                    y1={0}
                    x2={9}
                    y2={18}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_108_52"
                    x1={12}
                    y1={16}
                    x2={12}
                    y2={8}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="otc-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 3H15L21 9V3ZM3 21H9L3 15V21Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5ZM12 15L9 12L12 9L15 12L12 15Z"
                fill="url(#paint0_linear_2611_147362)"
            />
            <path d="M15 21H21V15L15 21Z" fill="url(#paint1_linear_2611_147362)" />
            <path d="M9 3H3V9L9 3Z" fill="url(#paint2_linear_2611_147362)" />
            <defs>
                <linearGradient
                    id="paint0_linear_2611_147362"
                    x1={12}
                    y1={5}
                    x2={12}
                    y2={19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2611_147362"
                    x1={18}
                    y1={15}
                    x2={18}
                    y2={21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2611_147362"
                    x1={6}
                    y1={3}
                    x2={6}
                    y2={9}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="p2p-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 4H18.5H21V6.5V8.5H18.5V6.5H15V4ZM5.83333 9.99805C4.54467 9.99805 3.5 11.0427 3.5 12.3314V13.998H11.5V12.3314C11.5 11.0427 10.4553 9.99805 9.16667 9.99805H5.83333ZM14.8333 16C13.5447 16 12.5 17.0447 12.5 18.3333V20H20.5V18.3333C20.5 17.0447 19.4553 16 18.1667 16H14.8333ZM9 17.5H5.5V15.5H3V17.5V20H5.5H9V17.5ZM19 12.502C19 13.8827 17.8807 15.002 16.5 15.002C15.1193 15.002 14 13.8827 14 12.502C14 11.1212 15.1193 10.002 16.5 10.002C17.8807 10.002 19 11.1212 19 12.502ZM7.5 9C8.88071 9 10 7.88071 10 6.5C10 5.11929 8.88071 4 7.5 4C6.11929 4 5 5.11929 5 6.5C5 7.88071 6.11929 9 7.5 9Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="p2p-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                    fill="url(#paint0_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    fill="#76808F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 13a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v3H2v-3z"
                    fill="#76808F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 17a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v3h-9v-3z"
                    fill="url(#paint1_linear)"
                />
                <path d="M13 4V2h9v4h-2V4h-7z" fill="#76808F" />
                <path d="M11 22v-2H4v-2H2v4h9z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="6.5"
                        y1={9}
                        x2="6.5"
                        y2={3}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="17.5"
                        y1={20}
                        x2="17.5"
                        y2={14}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="pay-f">
            <path d="M3 5H21V8H3V5Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 10.5V19H12.5278C11.5777 17.9385 11 16.5367 11 15C11 13.208 11.7856 11.5994 13.0313 10.5H3ZM5 15H9V17H5V15Z"
                fill="currentColor"
            />
            <path
                d="M20.9687 10.5C20.9792 10.5092 20.9896 10.5185 21 10.5278V10.5H20.9687Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 19C19.2091 19 21 17.2091 21 15C21 12.7909 19.2091 11 17 11C14.7909 11 13 12.7909 13 15C13 17.2091 14.7909 19 17 19ZM15 15L17 13L19 15L17 17L15 15Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="pay-g">
            <rect x={2} y={4} width={20} height={15} fill="#76808F" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 22C20.7614 22 23 19.7614 23 17C23 14.2386 20.7614 12 18 12C15.2386 12 13 14.2386 13 17C13 19.7614 15.2386 22 18 22ZM18 15L16 17L18 19L20 17L18 15Z"
                fill="url(#paint0_linear)"
            />
            <path d="M22 10L2 10L2 7L22 7L22 10Z" fill="url(#paint1_linear)" />
            <path d="M10 14L4 14L4 12L10 12L10 14Z" fill="url(#paint2_linear)" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={18}
                    y1={22}
                    x2={18}
                    y2={12}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1={2}
                    y1="10.5"
                    x2={22}
                    y2="10.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1={2}
                    y1="10.5"
                    x2={22}
                    y2="10.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="payment-g">
            <g>
                <circle
                    r={10}
                    transform="matrix(1 0 0 -1 12 12)"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M13.088 20v-1.782c2.468-.408 3.62-1.949 3.62-3.768 0-1.893-1.244-2.951-3.843-3.545V7.824c.89.204 1.485.612 1.93 1.113l1.69-1.522c-.817-.928-1.912-1.503-3.397-1.67V4h-2.116v1.745c-2.395.278-3.675 1.578-3.675 3.526 0 1.8 1.132 3.026 3.916 3.601v3.341c-1.04-.148-1.912-.65-2.617-1.392l-1.67 1.522c.927 1.021 2.171 1.782 4.046 1.95V20h2.116zM9.858 9.197c0-.724.408-1.225 1.355-1.41v2.747c-.947-.26-1.355-.631-1.355-1.337zm4.288 5.457c0 .706-.409 1.281-1.281 1.522v-2.932c1.058.315 1.28.872 1.28 1.41z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={10}
                        y1={0}
                        x2={10}
                        y2={20}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="piggy-bank-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0135 1C9.30088 1 7.10183 3.19905 7.10183 5.91171C7.10183 6.83267 7.3553 7.69443 7.79628 8.43103C5.55306 9.4109 3.95023 11.5837 3.7896 14.1476H2V15.9337H3.91462C4.26878 17.6501 5.27957 19.1265 6.67276 20.0888L5.02451 21.7371L6.28746 23L8.3573 20.9302C9.023 21.1518 9.73515 21.2719 10.4753 21.2719H15.7789L17.3705 22.8635L18.6334 21.6006L17.2935 20.2606L21.6429 15.9112L21.6429 13.2243H19.7091C19.5089 12.122 19.0149 11.1221 18.311 10.3087L20.7459 7.87386H16.5177C16.7798 7.27288 16.9252 6.60927 16.9252 5.91171C16.9252 3.19905 14.7262 1 12.0135 1ZM8.88791 5.91171C8.88791 4.18547 10.2873 2.78608 12.0135 2.78608C13.7398 2.78608 15.1392 4.18547 15.1392 5.91171C15.1392 7.63795 13.7398 9.03734 12.0135 9.03734C10.2873 9.03734 8.88791 7.63795 8.88791 5.91171ZM10.674 5.91171L12.0135 4.57215L13.3531 5.91171L12.0135 7.25127L10.674 5.91171ZM9.7484 13.2344H14.2787V11.4484H9.7484V13.2344Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="piggy-bank">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 14H3.15003C3.4738 15.595 4.30376 17.0062 5.46493 18.0587L6.14248 18.7362L4.37868 20.5L6.5 22.6214L9.12135 20H14.9999L17.5 22.5001L19.6213 20.3787L18.1213 18.8787L23 14V11H20.85C20.6005 9.77089 20.0504 8.65093 19.2798 7.72018L22 5H10.5C6.52588 5 3.2737 8.09098 3.0164 12H1V14ZM18 12C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11C17 11.5523 17.4477 12 18 12Z"
                fill="#76808E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 11C14.7614 11 17 8.76142 17 6C17 3.23858 14.7614 1 12 1C9.23858 1 7 3.23858 7 6C7 8.76142 9.23858 11 12 11ZM12 8L10 6L12 4L14 6L12 8Z"
                fill="url(#paint0_linear_9022_83782)"
            />
            <path d="M12 8L10 6L12 4L14 6L12 8Z" fill="#76808E" />
            <defs>
                <linearGradient
                    id="paint0_linear_9022_83782"
                    x1={12}
                    y1={11}
                    x2={12}
                    y2={1}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="pool-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2804 5.59659C13.8786 3.65962 10.8238 2.5 7.49805 2.5L13.4375 8.43945L2.9375 18.9395L5.05882 21.0608L15.5588 10.5608L21.498 16.5C21.498 13.1744 20.3385 10.1196 18.4017 7.71793L20.5588 5.56077L18.4375 3.43945L16.2804 5.59659ZM13.998 17H17.998V21H13.998V17ZM4.49805 7L2.99805 8.5L4.49805 10L5.99805 8.5L4.49805 7Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="pool-g">
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.586 18L16.293 2.293l1.414 1.414L2 19.414.586 18z"
                    fill="#76808F"
                />
                <path
                    d="M15.607 4.393A14.953 14.953 0 0 0 5 0l15 15c0-4.142-1.679-7.892-4.393-10.607z"
                    fill="url(#paint0_linear)"
                />
                <path d="M12 13l2 2-2 2-2-2 2-2z" fill="#76808F" />
                <path d="M22 7l1 1-1 1-1-1 1-1z" fill="#76808F" />
                <path d="M15 17h5v5h-5v-5z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="12.5"
                        y1={15}
                        x2="12.5"
                        y2={0}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="proof-reserve-f">
            {" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 3V21H19V7L15 3H5ZM8 9.5H16V12H8V9.5ZM8 14.5H16V17H8V14.5Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="rebalancing-bot-f">
            {" "}
            <path d="M4 15L4 20L9 20L4 15Z" fill="currentColor" />{" "}
            <path d="M20 9L20 4L15 4L20 9Z" fill="currentColor" />{" "}
            <circle
                cx="9.69231"
                cy="9.6923"
                r="5.43928"
                transform="rotate(45 9.69231 9.6923)"
                fill="currentColor"
            />{" "}
            <circle
                cx="14.3077"
                cy="14.3077"
                r="5.43928"
                transform="rotate(45 14.3077 14.3077)"
                fill="currentColor"
            />{" "}
            <path
                d="M8.92279 15.0772C10.5587 15.3097 12.2801 14.7968 13.5384 13.5384C14.7968 12.2801 15.3097 10.5587 15.0772 8.92279C13.4413 8.6903 11.7199 9.20322 10.4615 10.4615C9.20322 11.7199 8.6903 13.4413 8.92279 15.0772Z"
                fill="white"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="recurring-buy-g">
            <circle
                r={8}
                transform="matrix(1 0 0 -1 13.2759 12)"
                fill="url(#paint0_linear_3295_130337)"
            />
            <path
                d="M14.2927 18.3996V16.9741C16.2677 16.6474 17.1883 15.4149 17.1883 13.9597C17.1883 12.4451 16.1934 11.5987 14.1145 11.1235V8.65854C14.8273 8.82188 15.3025 9.14856 15.6588 9.54949L17.0101 8.33186C16.3568 7.5894 15.4807 7.12908 14.2927 6.99543V5.59961H13.4463H12.5999V6.99543C10.6844 7.21817 9.65978 8.25761 9.65978 9.81678C9.65978 11.2571 10.5656 12.2372 12.793 12.6975V15.3704C11.9614 15.2516 11.2635 14.8507 10.6992 14.2567L9.36279 15.4743C10.1053 16.291 11.1001 16.8998 12.5999 17.0335V18.3996H14.2927ZM11.709 9.75738C11.709 9.17826 12.0356 8.77733 12.793 8.62884V10.8265C12.0356 10.6186 11.709 10.3217 11.709 9.75738ZM15.1391 14.123C15.1391 14.6873 14.8124 15.1476 14.1145 15.3407V12.9945C14.9609 13.2469 15.1391 13.6924 15.1391 14.123Z"
                fill="#76808F"
            />
            <path
                d="M5.27588 12C5.27588 7.58172 8.8576 4 13.2759 4C17.6942 4 21.2759 7.58172 21.2759 12C21.2759 16.4183 17.6942 20 13.2759 20C11.0562 20 9.04919 19.0973 7.59879 17.6365L6.17955 19.0457C7.99004 20.8691 10.5018 22 13.2759 22C18.7987 22 23.2759 17.5228 23.2759 12C23.2759 6.47715 18.7987 2 13.2759 2C7.75303 2 3.27588 6.47715 3.27588 12H0L4.27597 16.276L8.55194 12H5.27588Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3295_130337"
                    x1={8}
                    y1={0}
                    x2={8}
                    y2={16}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="reponsible-trading-g">
            <path d="M21 17V3H3V17L12 23L21 17Z" fill="url(#paint0_linear)" />
            <path
                d="M12 5C13.933 5 15.5 6.567 15.5 8.5C15.5 10.433 13.933 12 12 12C10.067 12 8.5 10.433 8.5 8.5C8.5 6.567 10.067 5 12 5Z"
                fill="#76808F"
            />
            <path
                d="M8.11111 14C6.39289 14 5 15.3929 5 17.1111V18.3333L12 23L19 18.3333V17.1111C19 15.3929 17.6071 14 15.8889 14H14.5L12 16.5L9.5 14H8.11111Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={12}
                    y1={23}
                    x2={12}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="research-g">
            <g>
                <path d="M7.5 2.5l-2 2L4 3l2-2 1.5 1.5z" fill="#76808F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 20a5 5 0 0 1-3.536-8.536l1.243-1.242-1.414-1.414L7.05 10.05A7 7 0 0 0 12 22v-2z"
                    fill="#76808F"
                />
                <path d="M13 20h9v-2h-9v2z" fill="#76808F" />
                <path d="M4 20h18v2H4v-2z" fill="#76808F" />
                <path d="M16 8l-5 5-7-7 5-5 7 7z" fill="url(#paint0_linear)" />
                <path d="M10.585 12.586l5-5L17 9l-5 5-1.414-1.414z" fill="#76808F" />
                <path
                    d="M19.035 12.5a2.5 2.5 0 1 0-3.535 3.536 2.5 2.5 0 0 0 3.535-3.536z"
                    fill="url(#paint1_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="13.5"
                        y1="10.5"
                        x2="6.5"
                        y2="3.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="19.035"
                        y1="16.035"
                        x2="15.5"
                        y2="12.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="rewards-hub-f">
            {" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9585 3.62321L13.8756 1.74756L12 2.83043L10.1244 1.74756L9.04147 3.62321H6.87538V5.78916L5 6.8719L6.08292 8.74756L5.00003 10.6232L6.87538 11.706V13.8719H9.04144L10.1243 15.7476L12 14.6647L13.8757 15.7476L14.9586 13.8719H17.1241V11.7063L19 10.6232L17.9171 8.74756L19 6.87191L17.1241 5.78883V3.62321H14.9585Z"
                fill="currentColor"
            />{" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 15.3719V22.2524L12 18.8774L16.5 22.2524V15.3719H15.8246L14.4247 17.7965L12 16.3967L9.57527 17.7965L8.17539 15.3719H7.5ZM7.5 13.8719V5.37744H16.5V13.8719H14.9585L13.8756 15.7475L12 14.6646L10.1243 15.7475L9.04142 13.8719H7.5Z"
                fill="currentColor"
            />{" "}
            <path
                d="M14.75 8.74756C14.75 10.2663 13.5188 11.4976 12 11.4976C10.4812 11.4976 9.25 10.2663 9.25 8.74756C9.25 7.22878 10.4812 5.99756 12 5.99756C13.5188 5.99756 14.75 7.22878 14.75 8.74756Z"
                fill="white"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="rules-f">
            <path
                d="M11 8.2002C11 5.99106 9.20914 4.2002 7 4.2002H3V18.2002H8.16C9.35253 18.2002 10.4033 18.8352 11.0205 19.7998H12.9795C13.5967 18.8352 14.6475 18.2002 15.84 18.2002H21V4.2002H17C14.7909 4.2002 13 5.99106 13 8.2002V16.2002H11V8.2002Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="savings-dual-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 16C19.3137 16 22 13.3137 22 10C22 6.68629 19.3137 4 16 4C13.913 4 12.0749 5.06551 11 6.6822V13.3178C12.0749 14.9345 13.913 16 16 16Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4C11.3137 4 14 6.68629 14 10C14 13.3137 11.3137 16 8 16C4.68629 16 2 13.3137 2 10C2 6.68629 4.68629 4 8 4ZM8 12.5L5.5 10L8 7.5L10.5 10L8 12.5Z"
                fill="url(#paint0_linear)"
            />
            <rect x={2} y={18} width={20} height={2} fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={8}
                    y1={16}
                    x2={8}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="security-g">
            <path d="M12 23L3 17V3H21V17.1L12 23Z" fill="url(#paint0_linear_7_162)" />
            <path d="M8 12L12 8L16 12L12 16L8 12Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_7_162"
                    x1={12}
                    y1={23}
                    x2={12}
                    y2={3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="spot-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0002 3.5C12.471 3.5 10.3406 5.20717 9.6983 7.53206C13.2833 7.86295 16.1373 10.7169 16.4682 14.3019C18.7931 13.6597 20.5002 11.5292 20.5002 9C20.5002 5.96243 18.0378 3.5 15.0002 3.5ZM14.5 15C14.5 11.9624 12.0376 9.5 9 9.5C5.96243 9.5 3.5 11.9624 3.5 15C3.5 18.0376 5.96243 20.5 9 20.5C12.0376 20.5 14.5 18.0376 14.5 15ZM6.5 15L9 17.5L11.5 15L9 12.5L6.5 15ZM9.00006 4H4.00006V9L9.00006 4ZM20.0001 20H15.0001L20.0001 15V20Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="spot-g">
            <g>
                <path d="M21.5 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0z" fill="#76808F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 9.5a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 8.5L6 15.5 8.5 13l2.5 2.5L8.5 18z"
                    fill="url(#paint0_linear)"
                />
                <path d="M9 3H3v6l6-6z" fill="url(#paint1_linear)" />
                <path d="M15 21h6v-6l-6 6z" fill="url(#paint2_linear)" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="8.5"
                        y1="21.5"
                        x2="8.5"
                        y2="9.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1={6}
                        y1={9}
                        x2={6}
                        y2={3}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1={18}
                        y1={21}
                        x2={18}
                        y2={15}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="stock-token-g">
            <g>
                <circle
                    r={10}
                    transform="matrix(1 0 0 -1 12 12)"
                    fill="url(#paint0_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.8 14H18v-4h-2V8h-3V4h-2v4H8v2H6v4H2.2A10.001 10.001 0 0 0 6 20a9.986 9.986 0 0 0 2 1.168V20h3v1.95a10.106 10.106 0 0 0 2 0V20h3v1.168A9.986 9.986 0 0 0 18 20l.001-.001a10.001 10.001 0 0 0 3.8-6zM13 18h3v-2h-3v2zm0-4h3v-2h-3v2zm-2-2v2H8v-2h3zm0 4v2H8v-2h3z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={10}
                        y1={0}
                        x2={10}
                        y2={20}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="strategy-f">
            {" "}
            <path
                d="M15.145 3H21V8.85498L19.1589 7.01385L12.0244 14.1483L9.12068 11.2445L5.14012 15.2251L3.0188 13.1038L9.12068 7.00191L12.0244 9.90565L17.0375 4.89253L15.145 3Z"
                fill="currentColor"
            />{" "}
            <path
                d="M21 8.85498V20.9999H3L9.09879 14.901L12.0025 17.8047L21 8.85498Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="strategy-trading-g">
            <path
                d="M9.5 11.5L3 18V21H21V7L13 15L9.5 11.5Z"
                fill="url(#paint0_linear_11027:40088)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9999 4.41421L13 12.4137L9.50002 8.91371L4.41476 13.999L3.00055 12.5848L8.79235 6.79295L9.50002 6.08528L13 9.58528L19.5857 3L20.9999 4.41421Z"
                fill="#76808F"
            />
            <path d="M16 3H21V8L16 3Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_11027:40088"
                    x1={12}
                    y1={7}
                    x2={12}
                    y2={21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="swap-farming">
            <path d="M21 7.99999L6 8V12H4V6L15 5.99999V2L21 7.99999Z" fill="#76808F" />
            <path
                d="M3 16L18 16V12H20V18L8.99999 18V22L3 16Z"
                fill="url(#paint0_linear_9345_86684)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9345_86684"
                    x1="11.5"
                    y1={22}
                    x2="11.5"
                    y2={12}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="tax-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0002 3H6V21H18V15H6.00024V10H18V7L14.0002 3Z"
                fill="url(#paint0_linear_4525_123358)"
            />
            <path d="M14 3L18 7H14V3Z" fill="#76808E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9995 9H4V16.8751H19.9995V9ZM11.872 10.9336L13.5777 14.9418H12.6623L12.2984 14.0492H10.6156L10.2517 14.9418H9.35909L11.0647 10.9336H11.872ZM10.9283 13.276H11.9857L11.457 11.9854L10.9283 13.276ZM9.53764 10.962V11.7693H8.32665V14.9418H7.4511V11.7693H6.24011V10.962H9.53764ZM17.7597 14.9418L16.3725 12.9064L17.7029 10.962H16.7079L15.8665 12.2526L15.0307 10.962H14.0074L15.3377 12.9178L13.9505 14.9418H14.9454L15.8437 13.5773L16.7363 14.9418H17.7597Z"
                fill="#76808E"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_4525_123358"
                    x1={12}
                    y1={3}
                    x2={12}
                    y2={21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 25 24" id="tooltip-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0469 18.0004H8.04688V21H16.0469V18.0004ZM7.71676 15.5004C6.09075 14.2186 5.04688 12.2312 5.04688 10C5.04688 6.13401 8.18088 3 12.0469 3C15.9129 3 19.0469 6.13401 19.0469 10C19.0469 12.2312 18.003 14.2186 16.377 15.5004H13.2969V13.1453L15.9674 10.4748L14.1996 8.70703L12.0469 10.8598L9.89416 8.70703L8.12638 10.4748L10.7969 13.1453V15.5004H7.71676Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="trade-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0002 3.5C12.471 3.5 10.3406 5.20717 9.6983 7.53206C13.2833 7.86295 16.1373 10.7169 16.4682 14.3019C18.7931 13.6597 20.5002 11.5292 20.5002 9C20.5002 5.96243 18.0378 3.5 15.0002 3.5ZM14.5 15C14.5 11.9624 12.0376 9.5 9 9.5C5.96243 9.5 3.5 11.9624 3.5 15C3.5 18.0376 5.96243 20.5 9 20.5C12.0376 20.5 14.5 18.0376 14.5 15ZM6.5 15L9 17.5L11.5 15L9 12.5L6.5 15Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="trade-trading-bot-f">
            {" "}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 4H15V6H13V7H20V11.5H22V15.5H20V20H4V15.5H2V11.5H4V7H11V6H9V4ZM10 11.5C10 12.3284 9.32843 13 8.5 13C7.67157 13 7 12.3284 7 11.5C7 10.6716 7.67157 10 8.5 10C9.32843 10 10 10.6716 10 11.5ZM9 15H15V16.5H9V15ZM15.5 13C16.3284 13 17 12.3284 17 11.5C17 10.6716 16.3284 10 15.5 10C14.6716 10 14 10.6716 14 11.5C14 12.3284 14.6716 13 15.5 13Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="trade-trading-bot-g">
            <rect x={1} y="10.5" width={2} height={5} fill="#76808F" />
            <rect x={21} y="10.5" width={2} height={5} fill="#76808F" />
            <path d="M3 6H21V20H3V6Z" fill="url(#paint0_linear_1141_32699)" />
            <rect x={11} y={5} width={2} height={1} fill="#76808F" />
            <rect x={9} y={3} width={6} height={2} fill="#76808F" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.8 11.075C9.8 11.9863 9.06127 12.725 8.15 12.725C7.23873 12.725 6.5 11.9863 6.5 11.075C6.5 10.1638 7.23873 9.42505 8.15 9.42505C9.06127 9.42505 9.8 10.1638 9.8 11.075ZM8.69604 14.7985H15.296L13.8022 16.4485H10.1961L8.69604 14.7985ZM15.85 12.725C16.7613 12.725 17.5 11.9863 17.5 11.075C17.5 10.1638 16.7613 9.42505 15.85 9.42505C14.9387 9.42505 14.2 10.1638 14.2 11.075C14.2 11.9863 14.9387 12.725 15.85 12.725Z"
                fill="#76808F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1141_32699"
                    x1={12}
                    y1={6}
                    x2={12}
                    y2={20}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="trading-data-g">
            <rect
                width={5}
                height={7}
                transform="matrix(1 0 0 -1 2 18)"
                fill="url(#paint0_linear_1994_24389)"
            />
            <path d="M2 18H7V21H2V18Z" fill="#76808F" />
            <rect
                width={5}
                height={8}
                transform="matrix(1 0 0 -1 17 16)"
                fill="url(#paint1_linear_1994_24389)"
            />
            <path d="M9.5 11H14.5V2H9.5V11Z" fill="url(#paint2_linear_1994_24389)" />
            <path d="M9.5 21H14.5V11H9.5V21Z" fill="#76808F" />
            <path d="M17 21H22V16H17V21Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_1994_24389"
                    x1="2.5"
                    y1={0}
                    x2="2.5"
                    y2={7}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1994_24389"
                    x1="2.5"
                    y1={0}
                    x2="2.5"
                    y2={8}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1994_24389"
                    x1={12}
                    y1={11}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="trading-fullscreen-g">
            <path
                d="M22 6.00024L2 6.00024L2 4.00025L22 4.00024L22 6.00024Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0547 20.0002H14.4806L14.4806 8.00024H22.0547V20.0002ZM2.05469 8.00024H12.5194L12.5194 20.0002H2.05469V8.00024ZM7.3125 10.0002V18.0002L3.40625 14.094L7.3125 10.0002ZM16.7969 18.0002L16.7969 10.0002L20.7031 13.9065L16.7969 18.0002Z"
                fill="url(#paint0_linear_29203_26016)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_29203_26016"
                    x1="12.0547"
                    y1="8.00024"
                    x2="12.0547"
                    y2="20.0002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="trading-interface-advanced-g">
            <g>
                <path d="M2 6h20V4H2v2z" fill="#76808F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8h9v12H2V8zm3 6l4-4v8l-4-4z"
                    fill="url(#paint0_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 8h9v12h-9V8zm6 6l-4-4v8l4-4z"
                    fill="#76808F"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="6.5"
                        y1={20}
                        x2="6.5"
                        y2={8}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="trading-interface-classic-g">
            <g>
                <path d="M22 6H2V4h20v2z" fill="#76808F" />
                <path d="M2 20h11V8H2v12z" fill="url(#paint0_linear)" />
                <path d="M15 20h7V8h-7v12z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="7.5"
                        y1={20}
                        x2="7.5"
                        y2={8}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
        <symbol viewBox="0 0 24 24" id="transaction-history-f">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5969 16.5942C15.3277 17.8634 13.6642 18.498 12.0007 18.498H10.7507V20.998H12.0007C14.304 20.998 16.6073 20.1194 18.3646 18.362C21.8794 14.8473 21.8794 9.1488 18.3646 5.63409C14.8499 2.11937 9.15144 2.11937 5.63672 5.63409L7.40449 7.40185C9.94289 4.86344 14.0585 4.86344 16.5969 7.40185C19.1353 9.94026 19.1353 14.0558 16.5969 16.5942ZM13.25 8H10.75V12.5178L13.935 15.7027L15.7027 13.935L13.25 11.4822V8ZM5.62695 9.56836V10.7671L5.53498 10.7745C4.76841 10.8362 4.16351 11.0636 3.75156 11.4356C3.3419 11.8055 3.11328 12.327 3.11328 12.9999C3.11328 13.6835 3.35345 14.2228 3.7888 14.6417C4.22696 15.0634 4.86853 15.3684 5.67775 15.5671L5.75391 15.5858V17.4872L5.63096 17.4582C5.3568 17.3936 5.06065 17.2872 4.79344 17.1534C4.56662 17.0397 4.35592 16.9039 4.19711 16.7531L3.13862 17.9347C3.58482 18.3511 4.44775 18.7193 5.40673 18.9091L5.4873 18.9251V20.2017H7.06465V18.9955L7.15097 18.9836C7.94731 18.8736 8.50751 18.5361 8.86926 18.0926C9.23197 17.6479 9.40059 17.0899 9.40059 16.5314C9.40059 15.8621 9.15539 15.3544 8.73281 14.9631C8.30682 14.5686 7.6959 14.2883 6.96078 14.0892L6.88691 14.0692V12.267L7.01226 12.2999C7.41846 12.4063 7.82818 12.6105 8.11087 12.8579L9.14567 11.7222C8.7157 11.3225 8.04405 11.0255 7.27075 10.8606L7.1916 10.8438V9.56836H5.62695ZM5.81738 13.8398L5.67964 13.7837C5.45876 13.6937 5.29901 13.5986 5.19495 13.4808C5.08694 13.3586 5.04609 13.2204 5.04609 13.0627C5.04609 12.9304 5.07409 12.765 5.17186 12.6153C5.27119 12.4632 5.43668 12.3362 5.69397 12.2743L5.81738 12.2446V13.8398ZM6.87422 17.5178V15.7862L7.02148 15.8651C7.18744 15.9541 7.31318 16.0698 7.39684 16.2121C7.48042 16.3542 7.51855 16.5169 7.51855 16.6948C7.51855 16.8295 7.49833 16.9829 7.42252 17.1239C7.34533 17.2674 7.21476 17.3899 7.00937 17.467L6.87422 17.5178Z"
                fill="currentColor"
            />
        </symbol>
        <symbol viewBox="0 0 24 24" id="user-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 13C4.79086 13 3 14.7909 3 17V21H21V17C21 14.7909 19.2091 13 17 13H15L12 16L9 13L7 13Z"
                fill="#76808F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 6.5C16.5 4.01472 14.4853 2 12 2C9.51472 2 7.5 4.01472 7.5 6.5C7.5 8.98528 9.51472 11 12 11C14.4853 11 16.5 8.98528 16.5 6.5Z"
                fill="url(#paint0_linear_7_82)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7_82"
                    x1={12}
                    y1={11}
                    x2={12}
                    y2={2}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="vault-g">
            <rect x={3} y={3} width={18} height={18} fill="#76808F" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5ZM12 14.9167L9.08333 12L12 9.08333L14.9167 12L12 14.9167Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={12}
                    y1={19}
                    x2={12}
                    y2={5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="vip-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 10L7 4H17L23 10L12 21L1 10ZM10 8H8V10H10V8Z"
                fill="url(#paint0_linear_737_33914)"
            />
            <path d="M19 3L20.5 1.5L22 3L20.5 4.5L19 3Z" fill="#76808F" />
            <path d="M12.5 12L10 14.5L12.5 17L15 14.5L12.5 12Z" fill="#76808F" />
            <path d="M3.5 15L2 16.5L3.5 18L5 16.5L3.5 15Z" fill="#76808F" />
            <defs>
                <linearGradient
                    id="paint0_linear_737_33914"
                    x1={12}
                    y1={21}
                    x2={12}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="vip-loan-g">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.73236 4.7677C10.8983 4.7677 13.4647 7.33417 13.4647 10.5001C13.4647 13.666 10.8983 16.2324 7.73236 16.2324C4.56647 16.2324 2 13.666 2 10.5001C2 7.33417 4.56647 4.7677 7.73236 4.7677ZM7.73236 12.9568L5.27563 10.5001L7.73236 8.04333L10.1891 10.5001L7.73236 12.9568Z"
                fill="url(#paint0_linear_34040_26824)"
            />
            <path
                d="M2 10.625H22V22H5C3.34315 22 2 20.6569 2 19V10.625Z"
                fill="#76808F"
            />
            <rect x={17} y="14.2324" width={5} height={4} fill="#F6CB2B" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0087 1.8927C20.213 1.8927 21.9999 3.6796 21.9999 5.88385C21.9999 8.0881 20.213 9.875 18.0087 9.875C15.8045 9.875 14.0176 8.0881 14.0176 5.88385C14.0176 3.6796 15.8045 1.8927 18.0087 1.8927ZM18.0087 7.59434L16.2982 5.88385L18.0087 4.17336L19.7192 5.88385L18.0087 7.59434Z"
                fill="url(#paint1_linear_34040_26824)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_34040_26824"
                    x1="7.73236"
                    y1="16.2324"
                    x2="7.73236"
                    y2="4.7677"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_34040_26824"
                    x1="18.0087"
                    y1="9.875"
                    x2="18.0087"
                    y2="1.8927"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F0B90B" />
                    <stop offset={1} stopColor="#F8D33A" />
                </linearGradient>
            </defs>
        </symbol>
        <symbol viewBox="0 0 24 24" id="wallet-g-1">
            <g>
                <path
                    d="M3 5.5A2.5 2.5 0 0 0 5.5 8H21v13H6a3 3 0 0 1-3-3V5.5z"
                    fill="url(#paint0_linear)"
                />
                <path d="M5.5 3H21v5H5.5a2.5 2.5 0 0 1 0-5z" fill="#76808F" />
                <path d="M21 12v5h-5v-5h5z" fill="#76808F" />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1={12}
                        y1={21}
                        x2={12}
                        y2="5.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F0B90B" />
                        <stop offset={1} stopColor="#F8D33A" />
                    </linearGradient>
                </defs>
            </g>
        </symbol>
    </svg>
    );
}

export default ComSvg;