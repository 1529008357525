import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { signOutStart } from "store/user/user.actions";
import { selectUser } from "store/user/user.selector";
import { shareLink } from "utils/helpers/helpers";
import { authLinks, walletLinks } from "utils/helpers/links";

const WalletNav = () => {
    const user = useSelector(selectUser), dispatch = useDispatch(),

        logOut = () => {
            dispatch(signOutStart())
        };
    var baseurl = window.location.origin,
        register = baseurl + authLinks.default + authLinks.signUp + '/' + user.referrer_code,
        shareSite = () => shareLink(register, 'Pexo Trading & Exchange');

    return (
        <div className="w-full mt-2">
            <hr className="border-amber-500 border-opacity-10 lg:hidden w-full" />
            {walletLinks.map((link, i) => (link.link ?

                <NavLink key={i} to={link.href}
                    className={({ isActive }) => `group flex space-x-3 p-4 hover:border-l-4 hover:border-amber-500 hover:bg-amber-500 hover:bg-opacity-10 ${isActive ? 'border-l-4 border-amber-500 text-amber-500' : ''}`}>
                    {link.icon}
                    <span className="text-neutral-900 dark:text-white text-base font-medium leading-tight">{link.name}</span>
                </NavLink> :
                <div key={i}>
                    <hr className="opacity-50 my-2" />
                    <span role="button" onClick={shareSite} className="flex space-x-3 p-4 hover:border-l-4 hover:border-amber-500 hover:bg-amber-500 hover:bg-opacity-10">
                        {link.icon}
                        <span className="text-neutral-900 dark:text-white text-base font-medium leading-tight">{link.name}</span>
                    </span>
                    <hr className="opacity-50 my-2" />
                </div>
            ))}

            <div className="flex justify-center mt-6 mb-32 lg:hidden">
                <button onClick={logOut} className="w-[200px] h-[43px] bg-amber-500 bg-opacity-10 rounded-[5px] flex justify-center items-center">
                    <div className="text-amber-500 text-base font-medium leading-tight">Sign out</div>
                </button>
            </div>
        </div>);
}

export default WalletNav;