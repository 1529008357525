import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectTheme } from "store/theme/theme.selector";
import { colors } from "utils/helpers/colors";
import { classNames } from "utils/helpers/helpers";
import { makeId } from "utils/initial-state/states";

export const CustomInput = ({ icon = null, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false)
  const
    tempID = makeId(),
    className = classNames(otherProps.className != null ? otherProps.className : '',
      icon == null ? 'pl-2' : 'pl-10',
      "w-full h-10 text-sm rounded-[5px] border-transparent text-pexo-gray-950 dark:text-pexo-gray-100",
      "bg-stone-500 bg-opacity-10 placeholder-opacity-50 focus:ring-pexo-orange-500 focus:border-pexo-orange-500",
      "dark:bg-amber-500 dark:bg-opacity-10 dark:placeholder-amber-500 dark:placeholder-opacity-50")
  return (
    <div className="relative w-full">
      {icon != null &&
        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
          {icon}
        </div>}
      <div className="relative">
        <input
          {...{
            ...otherProps, id: otherProps.id != null ? otherProps.id : `form-input-${tempID}`,
            type: otherProps.type == null ? 'text' : otherProps.type !== 'password' ? otherProps.type : showPassword ? 'text' : 'password',
            // type: otherProps.type != null ? 'text' : otherProps.type !== 'password' ? otherProps.type : showPassword ? 'text' : 'password',
            className
          }}
        />

        {otherProps.type && otherProps.type === 'password' && <span className="p-1 absolute top-2.5 right-2.5" role="button"
          onClick={() => setShowPassword(state => !state)}>
          {
            showPassword ? <BsEyeSlash className="text-gray-500 dark:text-gray-400 font-bold" />
              :
              <BsEye className="text-gray-500 dark:text-gray-400 font-bold" />
          }
        </span>}
      </div>

    </div>)

}

function FormInput({ label, options = null, error = false, errorMessage = '', ...otherProps }) {
  const [showPassword, setShowPassword] = useState(false)
  const theme = useSelector(selectTheme),
    color = colors(theme)
  if (otherProps.type === "select")
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label">{label}</label>
        <select className="form-control custom-select" {...otherProps}>
          {options}
        </select>
      </div>
    </div>;
  const tempID = makeId();
  return (
    <div className="mb-6">
      <label
        htmlFor={otherProps.id != null ? otherProps.id : `form-input-${tempID}`}
        className="block mb-2 text-gray-900 dark:text-white auth-input-label"
      >
        {label}
      </label>
      <div className="relative">
        <input

          style={{
            backgroundColor: color.inputBg
          }}
          {...{
            ...otherProps, id: otherProps.id != null ? otherProps.id : `form-input-${tempID}`,
            type: otherProps.type == null ? 'text' : otherProps.type !== 'password' ? otherProps.type : showPassword ? 'text' : 'password',
            className: `${otherProps.className != null ? otherProps.className : ''} border-transparent border-1 focus:border text-gray-900 text-sm rounded-lg focus:ring-pexo-orange-500 focus:border-pexo-orange-500 block w-full p-2.5 placeholder-opacity-50 dark:placeholder-amber-500 dark:placeholder-opacity-50 dark:text-white dark:focus:ring-pexo-orange-500 dark:focus:border-pexo-orange-200`
          }}
        />
        {otherProps.type && otherProps.type === 'password' && <span className="p-1 absolute top-2.5 right-2.5" role="button"
          onClick={() => setShowPassword(state => !state)}>
          {
            showPassword ? <BsEyeSlash className="text-gray-500 dark:text-gray-400 font-bold" />
              :
              <BsEye className="text-gray-500 dark:text-gray-400 font-bold" />
          }
        </span>}
      </div>
      {otherProps.value?.length > 0 && error && <p className="form-error mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errorMessage}</p>}
    </div>
  );
}

export default FormInput;
