import MobileHeader from "components/nav/mobile-header.component";
import Preloader from "components/preloader/preloader.component";
import WalletNav from "components/wallet/sidebar.component";
import WalletLG from "components/wallet/wallet-lg.component";
import { useState } from "react";
import { AiFillNotification } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAllMarketValues } from "store/market/market.selector";
import { selectUser } from "store/user/user.selector";
import { classNames } from "utils/helpers/helpers";
import { links } from "utils/helpers/links";

export const AlertMsg = ({ message }) => {
    const [view, setView] = useState(true)
    return <center><div
        id="alert-4" className={classNames("animate__animated p-4 mt-4 bg-yellow-100/50 rounded-lg dark:bg-yellow-200/50",
            view ? 'flex animate__fadeInDown' : 'hidden animate__fadeOutUp',
            "w-full lg:w-[500px]")} role="alert">
        <AiFillNotification className="flex-shrink-0 text-amber-500" />
        <Link
            to={links.default + links.kyc} className="ml-3 text-sm font-medium text-yellow-700 dark:text-yellow-100 truncate ...">
            {message}
        </Link>
        <button type="button"
            onClick={() => setView(false)}
            className="justify-center items-center ml-auto -mx-1.5 -my-1.5 bg-yellow-100/50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8 dark:bg-yellow-200/50 dark:text-yellow-600 dark:hover:bg-yellow-300/50 alert-hidden" data-dismiss-target="#alert-4" aria-label="Close">
            <BiX className="w-full h-auto" />
        </button>
    </div></center>
}
const Wallet = () => {
    const [navShow, setNavShow] = useState(false),
        user = useSelector(selectUser),
        marketListValues = useSelector(selectAllMarketValues)
    if (marketListValues == null) return <Preloader />

    return (<>
        <>
            <MobileHeader setNavShow={setNavShow} navShow={navShow} />
            <div className={classNames("grid grid-cols-12",
                navShow ? 'bg-white dark:bg-pexo-gray-950 lg:bg-transparent dark:lg:bg-transparent' : 'm-2'
            )}>
                <div className={classNames("col-span-12 z-[999999999] lg:col-span-2 w-full overflow-x-auto text-3xs text-pexo-gray-600 dark:text-white font-inter",
                    navShow ? classNames('fixed lg:relative bg-white dark:bg-pexo-gray-950 inset-0',
                        'lg:bg-transparent dark:lg:bg-transparent',
                        "animate__animated animate__slideInLeft") : 'hidden lg:block lg:relative')}>

                    <MobileHeader setNavShow={setNavShow} navShow={navShow} />

                    <WalletNav />
                </div>
                <WalletLG user={user} />
            </div>
        </>
    </>
    );
}

export default Wallet;