import { all, takeLatest, put, call } from "redux-saga/effects";
import { MARKET_ACTION_TYPES } from "./market.types";
import { marketActions } from "./market.slice";

export function* fetchData({ payload }) {
  const query =
    payload.length > 1
      ? "ids=" +
        payload.map((item, i) => (i === 0 ? item : `%2C${item}`)).join("")
      : `ids=${payload[0]}`;
  const uri = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&${query}&order=market_cap_desc&per_page=2000&page=1&sparkline=true&locale=en`;
  try {
    const response = yield fetch(uri);
    const data = yield response.json();
    yield put(marketActions.setMarketAll(data));
  } catch (error) {
    console.log(error);
  }
}
export function* fetchDataBTC({ payload }) {
  const query =
    payload.length > 1
      ? "ids=" +
        payload.map((item, i) => (i === 0 ? item : `%2C${item}`)).join("")
      : `ids=${payload[0]}`;
  const uri = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=btc&${query}&order=market_cap_desc&per_page=2000&page=1&sparkline=true&locale=en`;
  try {
    const response = yield fetch(uri);
    const data = yield response.json();
    yield put(marketActions.setMarketAllByBTC(data));
  } catch (error) {
    console.log(error);
  }
}
export function* fetchTopData({ payload }) {
  // console.log("payload: ", payload);
  const query =
    payload.length > 1
      ? "ids=" +
        payload.map((item, i) => (i === 0 ? item : `%2C${item}`)).join("")
      : `ids=${payload[0]}`;
  const uri = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&${query}&order=volume_desc&per_page=2000&page=1&sparkline=true&locale=en`;
  try {
    const response = yield fetch(uri);
    const data = yield response.json();
    yield put(marketActions.setTopGainers(data));
  } catch (error) {
    console.log(error);
  }
}

export function* onFetchData() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKET_VALUES, fetchData);
}
export function* onFetchBTCData() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKET_VALUES_BTC, fetchDataBTC);
}
export function* onFetchTopData() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_TOP_MARKET_VALUES, fetchTopData);
}

export function* marketSagas() {
  yield all([call(onFetchData), call(onFetchTopData), call(onFetchBTCData)]);
}
