import logo from 'assets/img/logo.svg'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectInfo } from 'store/info/info.selector';
import { goToTop } from 'utils/helpers/helpers';
import { links } from "utils/helpers/links";
function Footer() {
    const siteInfo = useSelector(selectInfo)
    if (siteInfo == null) return null
    return (<footer className="dark:bg-pexo-dark-950 page-footer bg-white">
        <div className="container">
            <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
                <div className="col-span-full md:col-span-4">
                    <Link className="mb-6 inline-block" to="/">
                        <div className="dark:hidden flex items-center justify-center">
                            <img
                                alt="Pexo - Trading and Exchange"
                                loading="lazy"
                                width={30}
                                decoding="async"
                                data-nimg={1}
                                className="max-h-7 h-auto "
                                style={{ color: "transparent" }}
                                src={logo}
                            /><h1 style={{ color: '#ffa800', textTransform: 'lowercase' }} className="font-bold  text-center text-4xl dark:text-white md:text-left lg:text-4xl xl:text-4xl">
                                EXO.
                            </h1>
                        </div>
                        <div className="hidden dark:flex items-center justify-center">
                            <img
                                alt="Pexo - Trading and Exchange"
                                loading="lazy"
                                width={30}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                src={logo}
                            /><h1 style={{ color: '#ffa800', textTransform: 'lowercase' }} className="font-bold  text-center text-4xl dark:text-white md:text-left lg:text-4xl xl:text-4xl">
                                exo.
                            </h1>
                        </div>
                    </Link>

                    <p className="dark:text-pexo-gray-300 mb-12">
                        {siteInfo.write_up}
                    </p>
                    <div className="flex space-x-5">
                        {siteInfo?.socials.map((item, i) => <a key={i}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="group cursor-pointer"
                            href={item.url}
                        >
                            <svg className="icon group-hover:text-accent text-pexo-gray-300 h-5 w-5 dark:group-hover:text-white">
                                <use xlinkHref={item.svg} />
                            </svg>
                        </a>)}
                    </div>
                </div>
                <div className="col-span-full sm:col-span-3 md:col-span-2 md:col-start-7">
                    <h3 className="font-display text-pexo-gray-700 mb-6 text-sm dark:text-white">
                        Marketplace
                    </h3>
                    <ul className="dark:text-pexo-gray-300 flex flex-col space-y-1">
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.default + links.market.overview}>
                                Market Overview
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.default + links.market.overview}>
                                Market Trading
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-span-full sm:col-span-3 md:col-span-2 ">
                    <h3 className="font-display text-pexo-gray-700 mb-6 text-sm dark:text-white">
                        Company
                    </h3>
                    <ul className="dark:text-pexo-gray-300 flex flex-col space-y-1">
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={'/'} onClick={goToTop}>
                                Explore
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={'/about'} onClick={goToTop}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.blog} onClick={goToTop}>
                                Our Blog
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.faq} onClick={goToTop}>
                                F.A.Q
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-span-full sm:col-span-3 md:col-span-2 ">
                    <h3 className="font-display text-pexo-gray-700 mb-6 text-sm dark:text-white">
                        My Account
                    </h3>
                    <ul className="dark:text-pexo-gray-300 flex flex-col space-y-1">
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.default + links.profile}>
                                Profile
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.default + links.user.setting}>
                                Settings
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:text-accent dark:hover:text-white" to={links.default + links.user.referral}>
                                Referral
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
                <span className="dark:text-pexo-gray-400 text-sm">
                    <span>
                        © {/* */}{(new Date()).getFullYear()}{/* */} Pexo
                    </span>

                </span>
                <ul className="dark:text-pexo-gray-400 flex flex-wrap space-x-4 text-sm">
                    <li>
                        <Link className="hover:text-accent dark:hover:text-white" to={links.terms}>
                            Terms and conditions
                        </Link>
                    </li>
                    <li>
                        <Link className="hover:text-accent dark:hover:text-white" to={links.privacy}>
                            Privacy policy
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
    );
}

export default Footer;