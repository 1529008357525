import { createAction } from "../../utils/reducer/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";

// ------------------------------------------------------------------------------

export const setCurrentUser = (user) =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user);
// ------------------------------------------------------------------------------

export const changePassword = (props) =>
  createAction(USER_ACTION_TYPES.CHANGE_PASSWORD, props);
// ------------------------------------------------------------------------------

export const checkUserSession = () =>
  createAction(USER_ACTION_TYPES.CHECK_USER_SESSION);
// ------------------------------------------------------------------------------

export const updateUser = (details) =>
  createAction(USER_ACTION_TYPES.UPDATE_USER, details);
// ------------------------------------------------------------------------------

export const googleSignInStart = () =>
  createAction(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START);
// ------------------------------------------------------------------------------

export const emailSignInStart = (user) =>
  createAction(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, user);
// ------------------------------------------------------------------------------

export const userSignUpStart = (userData) =>
  createAction(USER_ACTION_TYPES.USER_SIGN_UP_START, userData);
// ------------------------------------------------------------------------------

export const signInSuccess = (user) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, user);
// ------------------------------------------------------------------------------

export const signInFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, error);
// ------------------------------------------------------------------------------

export const forgotPassword = (details) =>
  createAction(USER_ACTION_TYPES.FORGOT_PASSWORD, details);
// ------------------------------------------------------------------------------

export const signUpSuccess = (user) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_SUCCESS, user);
// ------------------------------------------------------------------------------

export const signUpFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_FAILED, error);
// ------------------------------------------------------------------------------

export const signOutStart = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_START);
// ------------------------------------------------------------------------------

export const signOutSuccess = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS);
// ------------------------------------------------------------------------------

export const fetchIpDetails = () =>
  createAction(USER_ACTION_TYPES.FETCH_USER_IP_LOCATION);
// ------------------------------------------------------------------------------

export const signOutFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_FAILED, error);
// ------------------------------------------------------------------------------

export const setIsLoading = (bool) =>
  createAction(USER_ACTION_TYPES.SET_IS_LOADING, bool);
// ------------------------------------------------------------------------------

export const setTwoStepVerify = (bool) =>
  createAction(USER_ACTION_TYPES.SET_TWO_STEP_VERIFICATION, bool);
// ------------------------------------------------------------------------------

export const checkUnseen = (userId) =>
  createAction(USER_ACTION_TYPES.CHECK_UNSEEN, userId);
// ------------------------------------------------------------------------------

export const getCurrentUser = (userId) =>
  createAction(USER_ACTION_TYPES.FETCH_USER_DETAILS, userId);
// ------------------------------------------------------------------------------

export const getUserWallets = (userId) =>
  createAction(USER_ACTION_TYPES.FETCH_USER_WALLETS, userId);
// ------------------------------------------------------------------------------

export const payNowDefault = (details) =>
  createAction(USER_ACTION_TYPES.PAY_NOW_DEFAULT, details);
// ------------------------------------------------------------------------------

export const sendOTPCode = (details) =>
  createAction(USER_ACTION_TYPES.RESEND_CODE, details);
// ------------------------------------------------------------------------------
